<template>
  <template v-if="!nrp">
    <a-card size="small">
      <a-empty />
    </a-card>
  </template>

  <template v-else>
    <div class="monitor">
      <div class="monitor-top">
        <div class="monitor-message">
          <h3>
            {{ patientName }}
            {{
              $comman.isCanReceiveOrgansHospital(hospital) ? "UNOS ID" : "MR"
            }}: {{ caseReport.mr_number }} | Acct:
            {{ caseReport.data.account_number ?? "N/A" }} | DOB:
            {{ $customDate.mdy(caseReport.data.dob) }} | Gender:
            {{ caseReport.data.gender }} | Allergies:
            {{ caseReport.data.allergies ?? "N/A" }} | BSA :
            {{ caseReport.data.body_surface_area ?? "N/A" }}
          </h3>
        </div>
      </div>
      <div class="monitor-middle">
        <div class="monitor-left">
          <div class="timer-actions">
            <ul class="timer-action-list">
              <li class="action-item">
                <a-spin :spinning="nrpLoading">
                  <button
                    class="timer-action"
                    :class="
                      checkTimer('NRP')?.start_time &&
                      !checkTimer('NRP')?.stop_time
                        ? 'timer-action-active'
                        : ''
                    "
                    @click="
                      hasNotCompletedNRP
                        ? handleTimerEvent('NRP', 'nrpLoading')
                        : ''
                    "
                  >
                    <span class="title">
                      NRP
                      <span style="font-weight: normal !important">
                        {{ timer("NRP") }}
                      </span>
                    </span>

                    <span class="start">
                      {{
                        checkTimer("NRP")?.start_time
                          ? checkTimer("NRP").start_time.slice(0, 5)
                          : "-"
                      }}
                    </span>
                    <span class="stop">
                      {{
                        checkTimer("NRP")?.stop_time
                          ? checkTimer("NRP").stop_time.slice(0, 5)
                          : "-"
                      }}
                    </span>
                  </button>
                </a-spin>
              </li>
            </ul>
          </div>
          <div class="event-actions">
            <ul class="event-action-list">
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedNRP
                      ? open({
                          title: 'NRP Pre-Procedure Checklist',
                          path: 'newCaseReport.drawerForm.nrpPreProcedureChecklist',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                        })
                      : ''
                  "
                >
                  <span class="title">Pre-Procedure Checklist</span>
                  <span class="description">
                    {{ nrp?.pre_procedure_checklist?.submitted_at }}
                  </span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedNRP
                      ? open({
                          title: 'NRP Initiation Checklist',
                          path: 'newCaseReport.drawerForm.nrpInitiationChecklist',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                        })
                      : ''
                  "
                >
                  <span class="title">Initiation Checklist</span>
                  <span class="description">
                    {{ nrp?.initiation_checklist?.submitted_at }}
                  </span>
                </button>
              </li>
              <li class="action-item">
                <button class="event-action" @click="showChart = !showChart">
                  <span class="title">{{ showChart ? "Table" : "Chart" }}</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedNRP && nrp?.initiation_checklist
                      ? open({
                          title: 'Status',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.nrpStatus',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: { lastRecord },
                        })
                      : showInitiationWarningModal()
                  "
                >
                  <span class="title">Status</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedNRP && nrp?.initiation_checklist
                      ? open({
                          title: 'Blood Gas',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.nrpBloodGas',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: { lastRecord },
                        })
                      : showInitiationWarningModal()
                  "
                >
                  <span class="title">Blood Gas</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    open({
                      title: 'Case Data',
                      width: '90%',
                      path: 'newCaseReport.drawerForm.nrpCaseData',
                      callback: callback,
                      record: caseReport,
                    })
                  "
                >
                  <span class="title">Case Data</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action event-action-close"
                  @click="$emit('closeCaseClickEvent')"
                >
                  <span class="title">Close Case</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="monitor-right">
          <div class="monitor-right-top">
            <div class="indicators scroller">
              <ul class="indicators-list">
                <li class="indicator-item">
                  <span class="title">Blood Flow</span>
                  <span class="count">{{ computedBloodFlow }}</span>
                </li>
                <li class="indicator-item">
                  <span class="title">HCT</span>
                  <span class="count">
                    {{ computedHCT != "-" ? `${computedHCT}%` : "-" }}
                  </span>
                </li>
                <li class="indicator-item">
                  <span class="title">Lac</span>
                  <span class="count">
                    {{ computedLac }}
                  </span>
                </li>
                <li class="indicator-item">
                  <span class="title">Line Pres.</span>
                  <span class="count">{{ computedLinePressure }}</span>
                </li>
                <li class="indicator-item">
                  <span class="title">Core Temp.</span>
                  <span class="count">{{ computedCoreTemp }}</span>
                </li>
                <li class="indicator-item">
                  <span class="title">H/C Temp.</span>
                  <span class="count">{{ computedHCTemp }}</span>
                </li>
                <li class="indicator-item">
                  <span class="title">Cardiac Index</span>
                  <span class="count">{{ computedCardiacIndex }}</span>
                </li>
                <li
                  class="indicator-item"
                  @click="
                    hasNotCompletedNRP
                      ? open({
                          title: 'Volume Balance',
                          path: 'newCaseReport.drawerForm.volumeBalanceInfo',
                          callback: callback,
                          width: '90%',
                          record: caseReport,
                          extra: totalVolumeBalance,
                        })
                      : ''
                  "
                >
                  <span class="title">Balance</span>
                  <span class="count">Σ {{ computedBalance }} mL</span>
                </li>
                <li class="indicator-item">
                  <span class="title">Pre-Bypass Vitals</span>
                  <span class="count">
                    ART : {{ firstRecord ? firstRecord.art_pressure : "-" }} |
                    CVP :
                    {{ firstRecord ? firstRecord.cvp : "-" }}
                  </span>
                </li>
                <li class="indicator-item">
                  <span class="title">Total NRP Time</span>
                  <span class="count"> {{ computedTotalNrpTime }} </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="monitor-right-middle" ref="nrpDatatableRef">
            <div id="nrpChart"></div>
            <div v-if="!showChart" class="event-table scroller">
              <table class="event-table-compact" ref="nrpDatatable">
                <thead>
                  <tr>
                    <th rowspan="2">Time</th>
                    <th rowspan="2">Event</th>
                    <th colspan="2">Pressure (mmHg)</th>
                    <th colspan="3">Pump</th>
                    <th colspan="2">Temp (<sup>o</sup>C)</th>
                    <th colspan="1">Sec.</th>
                    <th colspan="2">Gases</th>
                    <th colspan="6" class="highlight">Blood Gas Analysis</th>
                    <th colspan="7" class="highlight">Chemistry</th>
                  </tr>
                  <tr>
                    <th>ART</th>
                    <th>VAC</th>
                    <th>LINE</th>
                    <th>Blood Flow</th>
                    <th>RPM</th>
                    <th>Core Temp</th>
                    <th>H/C Temp</th>
                    <th>ACT</th>
                    <th>FiO<sub>2</sub></th>
                    <th>Sweep</th>
                    <th class="highlight"><sub>p</sub>H</th>
                    <th class="highlight">PCO<sub>2</sub></th>
                    <th class="highlight">PO<sub>2</sub></th>
                    <th class="highlight">HCO<sub>3</sub></th>
                    <th class="highlight">BASE</th>
                    <th class="highlight">O<sub>2</sub> SAT</th>
                    <th class="highlight">Hb/HCT</th>
                    <th class="highlight">iCa</th>
                    <th class="highlight">K<sup>+</sup></th>
                    <th class="highlight">GLU</th>
                    <th class="highlight">Lac</th>
                    <th class="highlight">AST</th>
                    <th class="highlight">ALT</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="hasRecords">
                    <template
                      v-for="(item, index) in onlyActiveRecords ?? []"
                      :key="index"
                    >
                      <tr v-if="item.event == 'Date change'">
                        <td
                          colspan="25"
                          align="left"
                          style="
                            font-weight: 500;
                            background-color: #eee;
                            padding-left: 10px;
                          "
                        >
                          {{ item.comment }}
                        </td>
                      </tr>
                      <tr v-else>
                        <td
                          v-if="
                            (!isCompleted &&
                              item.employee_id == employee.id &&
                              item.event != 'Shift Change') ||
                            isAdminLiveChartingPage
                          "
                        >
                          <a-popover placement="left">
                            <template #content>
                              <a-space>
                                <a-button
                                  type="primary"
                                  @click="handleEditEvent(item)"
                                >
                                  Edit
                                </a-button>
                                <a-popconfirm
                                  title="Confirm deletion?"
                                  ok-text="Yes"
                                  cancel-text="No"
                                  @confirm="deleteRecord(item.unique_code)"
                                >
                                  <a-button danger>Delete</a-button>
                                </a-popconfirm>
                              </a-space>
                            </template>
                            {{ item.time.slice(0, 5) ?? "-" }}
                          </a-popover>
                        </td>
                        <td v-else>
                          {{ item.time.slice(0, 5) ?? "-" }}
                        </td>
                        <td>{{ item.event }}</td>
                        <template
                          v-if="['NRP', 'Shift Change'].includes(item.event)"
                        >
                          <td colspan="23" align="left">
                            <span class="text-medium">
                              {{ item.note }}
                              {{
                                item.duration
                                  ? `(${$comman.secondsToHms1(item.duration)})`
                                  : ""
                              }}
                              [Date: {{ item.date }}]
                            </span>
                          </td>
                        </template>
                        <template
                          v-else-if="
                            ['Cooling', 'Warming'].includes(item.event)
                          "
                        >
                          <td colspan="23" align="left">
                            <span class="text-medium">Temperature:</span>
                            {{ item.temperature }}
                          </td>
                        </template>
                        <template v-else-if="['Comment'].includes(item.event)">
                          <td colspan="23" align="left">
                            {{
                              [
                                item?.event_type ?? "",
                                item?.comment ?? "",
                                item?.time ?? "",
                              ]
                                .filter((e) => e != "")
                                .join(" | ")
                            }}
                          </td>
                        </template>
                        <template
                          v-else-if="['Medication'].includes(item.event)"
                        >
                          <td colspan="23" align="left">
                            <span class="text-medium">Drug:</span>
                            {{ item.drug ?? "N/A" }} |
                            <span class="text-medium">Dose:</span>
                            {{ item.dose ?? "N/A" }}
                            {{ item.doseUnit ?? "N/A" }} |
                            <span class="text-medium">Administered By:</span>
                            {{ item.administered_by ?? "N/A" }} |
                            <span class="text-medium">Lot No#:</span>
                            {{ item.lot_no ?? "N/A" }} |
                            <span class="text-medium">Expiry Date:</span>
                            {{ item.expiry_date ?? "N/A" }}
                          </td>
                        </template>
                        <template v-else-if="['+ Volume'].includes(item.event)">
                          <td colspan="23" align="left">
                            <span v-html="plusVolumeEvent(item)" />
                          </td>
                        </template>
                        <template v-else-if="['- Volume'].includes(item.event)">
                          <td colspan="23" align="left">
                            <span v-html="minusVolumeEvent(item)" />
                          </td>
                        </template>
                        <template
                          v-else-if="['Blood Product'].includes(item.event)"
                        >
                          <td colspan="23" align="left">
                            <span class="text-medium"> Product Type : </span>
                            {{ item.product_type ?? "N/A" }} |
                            <span class="text-medium"> Unit Type: </span>
                            {{ item.unit_type ?? "N/A" }} |
                            <span class="text-medium"> Unit ID No: </span>
                            {{ item.unit_id_no ?? "N/A" }} |
                            <span class="text-medium">
                              Unit Expiration Date:
                            </span>
                            {{ item.unit_expiration_date ?? "N/A" }} |
                            <span class="text-medium"> Volume: </span>
                            {{ item.blood_product_volume ?? "N/A" }} mL
                          </td>
                        </template>
                        <template v-else>
                          <td>{{ item.art_pressure ?? "-" }}</td>
                          <td>{{ item.vacuum ? -item.vacuum : "-" }}</td>
                          <td>{{ item.line_pressure ?? "-" }}</td>
                          <td>{{ item.blood_flow ?? "-" }}</td>
                          <td>{{ item.rpm ?? "-" }}</td>
                          <td>{{ item.core_temp ?? "-" }}</td>
                          <td>{{ item.hc_temp ?? "-" }}</td>
                          <td>{{ item.act ?? "-" }}</td>
                          <td>{{ item.fio2 ?? "-" }}</td>
                          <td>{{ item.sweep ?? "-" }}</td>
                          <template v-if="item.event == 'Status'">
                            <td align="left" colspan="13"></td>
                          </template>
                          <template v-else>
                            <td class="highlight">{{ item.ph ?? "-" }}</td>
                            <td class="highlight">{{ item.pco2 ?? "-" }}</td>
                            <td class="highlight">{{ item.po2 ?? "-" }}</td>
                            <td class="highlight">{{ item.hco3 ?? "-" }}</td>
                            <td class="highlight">{{ item.base ?? "-" }}</td>
                            <td class="highlight">{{ item.o2_sat ?? "-" }}</td>
                            <td class="highlight">
                              {{ item.hb ?? "-" }}/{{ item.hct ?? "-" }}
                            </td>
                            <td class="highlight">{{ item.ica ?? "-" }}</td>
                            <td class="highlight">{{ item.kplus ?? "-" }}</td>
                            <td class="highlight">{{ item.glu ?? "-" }}</td>
                            <td class="highlight">{{ item.lac ?? "-" }}</td>
                            <td class="highlight">{{ item.ast ?? "-" }}</td>
                            <td class="highlight">{{ item.alt ?? "-" }}</td>
                          </template>
                        </template>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="25" align="left">
                        <strong>No Data Found.</strong>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
          <div class="monitor-right-bottom">
            <div class="quick-actions">
              <ul class="quick-actions-list">
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedNRP
                        ? open({
                            title: 'Medication',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.nrpMedication1',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Medication
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedNRP
                        ? open({
                            title: '+ Volume',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.nrpPlusVolume',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    + Volume
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedNRP
                        ? open({
                            title: '- Volume',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.nrpMinusVolume',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    - Volume
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedNRP
                        ? open({
                            title: 'Blood Product',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.nrpBloodProduct1',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Blood Products
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedNRP
                        ? open({
                            title: 'Comment',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.nrpComment',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Events
                  </button>
                </li>
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      hasNotCompletedNRP
                        ? open({
                            title: 'Shift Change',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.shiftChange',
                            callback: (e) => redirectToIndexPage(),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Shift Change
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { Line } from "@antv/g2plot";
import { Modal } from "ant-design-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  props: [
    "caseReport",
    "nrp",
    "uuid",
    "isCompleted",
    "isAdminLiveChartingPage",
  ],

  emits: ["updateCaseReport", "closeCaseClickEvent"],

  data() {
    return {
      moment,
      nrpLoading: false,
      showChart: false,
      nrpChart: null,
      nrpChartConfig: {
        xField: "time",
        yField: "value",
        seriesField: "category",
      },

      currentDateTime: "",
      currentDateTimeInverval: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.caseReport?.hospital?.timezone ?? this.$constants.timezone;
    },

    hospital() {
      return this.caseReport?.hospital ?? null;
    },

    patientName() {
      return this.$comman.isCanReceiveOrgansHospital(this.hospital)
        ? ""
        : `${this.caseReport.patient_first_name} ${this.caseReport.patient_last_name} |`;
    },

    hasNotCompletedNRP() {
      return !this.isCompleted || this.isAdminLiveChartingPage;
    },

    onlyActiveRecords() {
      let data = this.nrp?.data
        ? this.nrp.data
            .filter((item) => !item.deleted_at)
            .map((item) => {
              if (!item.employee_id) {
                item.employee_id = this.caseReport.employee_id;
              }
              if (!item.date) {
                item.date = this.$customDate.mdy(this.caseReport.date);
                item.datetime = `${item.date} ${item.time}`;
              }
              return item;
            })
            .sort((a, b) =>
              moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
                moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
              )
                ? 1
                : -1
            )
        : [];

      if (data.length != 0) {
        let firstDate = data[0].date;
        let lastDate = data[data.length - 1].date;
        let range = this.$comman.getDateRange(firstDate, lastDate);
        range.forEach((date, idx) => {
          if (idx != 0) {
            data.push({
              event: "Date change",
              date: date,
              time: "00:00:00",
              datetime: moment(`${date} 00:00:00`, "MM-DD-YYYY HH:mm:ss"),
              comment: `Date: ${date}`,
            });
          }
        });
      }

      return data.sort((a, b) =>
        moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
          moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
        )
          ? 1
          : -1
      );
    },

    hasRecords() {
      return this.onlyActiveRecords.length > 0 ? true : false;
    },

    firstRecord() {
      if (!this.hasRecords) {
        return null;
      }
      let record = this.onlyActiveRecords.filter((e) =>
        ["Status", "Blood Gas"].includes(e.event)
      );
      return record ? record[0] : null;
    },

    lastRecord() {
      if (!this.hasRecords || this.checkTimer("NRP")?.stop_time) {
        return null;
      }
      let record = this.onlyActiveRecords.filter(
        (e) =>
          ["Status", "Blood Gas"].includes(e.event) &&
          moment(
            this.checkTimer("NRP")?.start_datetime,
            "MM-DD-YYYY HH:mm:ss"
          ).isBefore(moment(e.datetime, "MM-DD-YYYY HH:mm:ss"))
      );
      return record ? record[record.length - 1] : null;
    },

    totalVolumeBalance() {
      return this.onlyActiveRecords
        .filter((e) =>
          ["+ Volume", "- Volume", "Blood Product"].includes(e.event)
        )
        .reduce(
          (pre, curr) => {
            let entries = Object.entries(curr);
            pre = pre.map((item) => {
              let entry = entries.find((e) => e[0] == item.key);
              if (entry && entry[1]) {
                item.sum += entry[1];
              }
              return item;
            });
            return pre;
          },
          [
            {
              name: "Blood Product Volume",
              key: "blood_product_volume",
              sum: 0,
              type: "Blood Product",
            },
            {
              name: "Pre-Bypass Volume",
              key: "pre_bypass_volume",
              sum: 0,
              type: "+ Volume",
            },
            {
              name: "Prime Volume",
              key: "prime_volume",
              sum: 0,
              type: "+ Volume",
            },
            {
              name: "Crystalloid",
              key: "crystalloid",
              sum: 0,
              type: "+ Volume",
            },
            { name: "Colloid", key: "colloid", sum: 0, type: "+ Volume" },
            {
              name: "Autologous Prime",
              key: "autologous_prime",
              sum: 0,
              type: "- Volume",
            },
            {
              name: "Ultrafiltration",
              key: "ultrafiltration",
              sum: 0,
              type: "- Volume",
            },
            { name: "Urine", key: "urine", sum: 0, type: "- Volume" },
            {
              name: "Residual CPB Volume",
              key: "residual_cpb_volume",
              sum: 0,
              type: "- Volume",
            },
          ]
        );
    },

    computedBalance() {
      return this.totalVolumeBalance.reduce(
        (pre, curr) => (pre += curr.sum),
        0
      );
    },

    computedBloodFlow() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.blood_flow ?? pre),
        "-"
      );
    },

    computedCardiacIndex() {
      let bloodFlow =
        this.computedBloodFlow != "-" ? this.computedBloodFlow : 0;
      if (bloodFlow == 0) {
        return 0;
      }
      if (this.caseReport.data.body_surface_area) {
        return Number(
          this.computedBloodFlow /
            Number(this.caseReport.data.body_surface_area)
        ).toFixed(1);
      }
      return 0;
    },

    computedHCT() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.hct ?? pre),
        "-"
      );
    },

    computedLac() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.lac ?? pre),
        "-"
      );
    },

    computedLinePressure() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.line_pressure ?? pre),
        "-"
      );
    },

    computedCoreTemp() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.core_temp ?? pre),
        "-"
      );
    },

    computedHCTemp() {
      return this.onlyActiveRecords.reduce(
        (pre, curr) => (pre = curr.hc_temp ?? pre),
        "-"
      );
    },

    nrpChartData() {
      let res = this.onlyActiveRecords.reduce((pre, curr) => {
        if (curr.hct) {
          pre.push({
            time: curr.time,
            value: curr.hct,
            category: "HCT",
          });
        }
        if (curr.svo2) {
          pre.push({
            time: curr.time,
            value: curr.svo2,
            category: "SvO2",
          });
        }
        if (curr.venous_temp) {
          pre.push({
            time: curr.time,
            value: curr.venous_temp,
            category: "Temp Venous",
          });
        }
        if (curr.blood_flow) {
          pre.push({
            time: curr.time,
            value: curr.blood_flow,
            category: "Art Flow",
          });
        }
        return pre;
      }, []);
      return res;
    },

    computedTotalNrpTime() {
      let seconds = this.onlyActiveRecords
        .filter((e) => e.event == "NRP")
        .reduce((pre, curr) => {
          let lastPre = pre.length > 0 ? { ...pre[pre.length - 1] } : {};
          if (lastPre.start_datetime && lastPre.stop_datetime) {
            lastPre = {};
          }
          if (["Start"].includes(curr.note)) {
            lastPre.start_datetime = `${curr.date} ${curr.time}`;
          } else if (["Stop"].includes(curr.note)) {
            lastPre.stop_datetime = `${curr.date} ${curr.time}`;
          }
          pre.push(lastPre);
          return pre;
        }, [])
        .filter((e) => e.start_datetime && e.stop_datetime)
        .reduce((pre, curr) => {
          let startTime = moment(curr.start_datetime, "MM-DD-YYYY HH:mm:ss");
          let endTime = moment(curr.stop_datetime, "MM-DD-YYYY HH:mm:ss");
          return pre + endTime.diff(startTime, "seconds");
        }, 0);

      let nrpTimer = this.checkTimer("NRP");
      if (nrpTimer?.start_time && !nrpTimer?.stop_time) {
        let currentSeconds = moment(
          this.currentDateTime,
          "MM-DD-YYYY HH:mm:ss"
        ).diff(
          moment(nrpTimer.start_datetime, "MM-DD-YYYY HH:mm:ss"),
          "seconds"
        );
        seconds += currentSeconds;
      }

      return this.$comman.secondsToHms1(seconds);
    },
  },

  mounted() {
    this.currentDateTime = moment()
      .tz(this.timezone)
      .format("MM-DD-YYYY HH:mm:ss");
    var self = this;
    this.currentDateTimeInverval = setInterval(function () {
      self.currentDateTime = moment()
        .tz(self.timezone)
        .format("MM-DD-YYYY HH:mm:ss");
    }, 1000);
  },

  unmounted() {
    clearInterval(this.currentDateTimeInverval);
  },

  methods: {
    ...mapActions("modal", ["open"]),

    callback() {
      // console.log("callback::");
    },

    handleEditEvent(item) {
      let path = "newCaseReport.drawerForm.";
      let title = `Edit ${item.event}`;
      if (item.event == "Status") {
        path += "nrpStatus";
      } else if (item.event == "Blood Gas") {
        path += "nrpBloodGas";
      } else if (item.event == "Medication") {
        path += "nrpMedication1";
      } else if (item.event == "+ Volume") {
        path += "nrpPlusVolume";
      } else if (item.event == "- Volume") {
        path += "nrpMinusVolume";
      } else if (item.event == "Blood Product") {
        path += "nrpBloodProduct1";
      } else if (item.event == "Comment") {
        path += "nrpComment";
      } else if (["NRP"].includes(item.event)) {
        path += "nrpTimer";
        title += " Timer";
      }
      this.open({
        title,
        path,
        callback: (e) => this.$emit("updateCaseReport", e),
        record: this.caseReport,
        extra: { record: item, onlyActiveRecords: this.onlyActiveRecords },
      });
    },

    deleteRecord(unique_code) {
      commonService
        .store(this.$constants.caseReportStoreNRPRecordUrl, {
          unique_code,
          uuid: this.uuid,
          delete: true,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.$emit("updateCaseReport", res.data);
        })
        .catch((err) => this.$message.error(err));
    },

    checkTimer(type) {
      return this.onlyActiveRecords
        .filter((e) => e.event == type)
        .reduce((pre, curr) => {
          if (pre.start_time && pre.stop_time) {
            pre = {};
          }
          if (["Start"].includes(curr.note)) {
            pre.start_time = curr.time;
            pre.start_datetime = `${curr.date} ${curr.time}`;
          } else if (["Stop"].includes(curr.note)) {
            pre.stop_time = curr.time;
            pre.stop_datetime = `${curr.date} ${curr.time}`;
          }
          return pre;
        }, {});
    },

    timer(type) {
      let checkTimer = this.checkTimer(type);
      let startDateTime = checkTimer?.start_datetime;
      let stopDateTime = checkTimer?.stop_datetime;
      if (this.currentDateTime && startDateTime) {
        let seconds = moment(
          stopDateTime ?? this.currentDateTime,
          "MM-DD-YYYY HH:mm:ss"
        ).diff(moment(startDateTime, "MM-DD-YYYY HH:mm:ss"), "seconds");
        return this.$comman.secondsToHms1(seconds);
      }
      return "";
    },

    handleTimerEvent(event, name) {
      if ("initiation_checklist" in this.nrp) {
        let data = { uuid: this.uuid, event };
        let timers = this.checkTimer(event);
        if (timers?.start_time && !timers?.stop_time) {
          data.note = "Stop";
        } else {
          data.note = "Start";
        }
        data.date = moment().tz(this.timezone).format("MM-DD-YYYY");
        data.time = moment().tz(this.timezone).format("HH:mm:ss");
        data.datetime = `${data.date} ${data.time}`;
        data.employee_id = this.employee.id;
        data.employee_name = this.employee.full_name;

        if (data.note == "Stop") {
          let startTime = moment(timers.start_datetime, "MM-DD-YYYY HH:mm:ss");
          let endTime = moment(data.datetime, "MM-DD-YYYY HH:mm:ss");
          data.duration = endTime.diff(startTime, "seconds");
        }

        this.loadingCallback(name, true);
        commonService
          .store(this.$constants.caseReportStoreNRPRecordUrl, data)
          .then((res) => {
            this.$emit("updateCaseReport", res.data);
          })
          .catch((err) => this.$message.error(err))
          .finally(() => this.loadingCallback(name, false));

        return;
      }
      Modal.warning({
        title: () =>
          "Please complete Initiation checklists in order to start NRP timer.",
      });
      return;
    },

    loadingCallback(name, bool) {
      this[name] = bool;
    },

    plusVolumeEvent(item) {
      return [
        { name: "Pre-Bypass Volume", key: "pre_bypass_volume" },
        { name: "Prime Volume", key: "prime_volume" },
        { name: "Crystalloid", key: "crystalloid" },
        { name: "Colloid", key: "colloid" },
        { name: "0.9% Normal Saline Solution", key: "normal_saline_solution" },
        { name: "Normosol-R, pH 7.4", key: "normosol_r_ph" },
        { name: "Isolyte S, pH 7.4", key: "isolyte_s_ph" },
      ]
        .reduce((pre, curr) => {
          if (item[curr.key] && !["", null].includes(item[curr.key])) {
            pre.push(
              `<span class="text-medium"> ${curr.name} : </span> ${
                item[curr.key]
              } mL`
            );
          }
          return pre;
        }, [])
        .join(" | ");
    },

    minusVolumeEvent(item) {
      return [
        { name: "Autologous Prime", key: "autologous_prime" },
        { name: "Ultrafiltration", key: "ultrafiltration" },
        { name: "Urine", key: "urine" },
        { name: "Residual CPB Volume", key: "residual_cpb_volume" },
      ]
        .reduce((pre, curr) => {
          if (item[curr.key] && !["", null].includes(item[curr.key])) {
            pre.push(
              `<span class="text-medium"> ${curr.name} : </span> ${
                item[curr.key]
              } mL`
            );
          }
          return pre;
        }, [])
        .join(" | ");
    },

    redirectToIndexPage() {
      this.$router.push({
        name:
          this.$route.name == "admin.case-report-live-charting"
            ? "admin.case-report"
            : "case-report",
      });
    },

    showInitiationWarningModal() {
      Modal.warning({
        title: () =>
          "Please complete Initiation checklists in order to record Status or Blood Gas.",
      });
    },
  },

  watch: {
    showChart: function (val) {
      if (val) {
        this.nrpChart = new Line("nrpChart", {
          data: this.nrpChartData,
          ...this.nrpChartConfig,
        });
        this.nrpChart.render();
      } else {
        this.nrpChart.destroy();
      }
    },
  },
};
</script>

<style lang="less">
.monitor {
  position: relative;
  display: block;
  background-color: #001529;
  overflow: hidden;

  .monitor-top {
    display: grid;
    grid-auto-flow: column;

    .monitor-message {
      display: block;
      padding: 1em;
      background-color: #002766;

      h3 {
        color: #fff;
        margin-bottom: 0;
      }
    }
  }

  .monitor-middle {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, auto);
    gap: 0px 0px;

    .monitor-left {
      display: flex;
      flex-direction: row;
      padding: 1em;
    }

    .monitor-right {
      display: flex;
      flex-direction: column;
    }
  }

  .timer-actions {
    display: flex;
    flex-direction: column;

    .timer-action-list {
      padding-left: 0;
      list-style-type: none;

      .action-item {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }

        .timer-action {
          display: grid;
          grid-template-columns: repeat(2, 50fr);
          grid-template-rows: repeat(2, 1fr);
          align-items: center;
          align-items: stretch;
          border: none;
          border-radius: 4px;
          font-size: 12px;
          width: 100%;
          padding: 0;
          color: #254000;
          background-color: #d3f261;
          transition: all 300ms ease-in-out;
          cursor: pointer;
          overflow: hidden;

          &.timer-action-active {
            background-color: #ff7875;
            box-shadow: inset 4px 6px 10px rgba(63, 62, 54, 0.5);
          }

          .title {
            align-content: center;
            display: grid;
            grid-area: 1 / 1 / 3 / 2;
            font-size: 14px;
            font-weight: 700;
            border-right: 1px solid #ccc;
          }

          .start {
            grid-area: 1 / 2 / 2 / 3;
            text-align: center;
            padding: 5px;
            background-color: #ffffff;
            border-bottom: 1px solid #ccc;
          }

          .stop {
            grid-area: 2 / 2 / 3 / 3;
            text-align: center;
            padding: 5px;
            background-color: #ffffff;
          }
        }
      }
    }
  }

  .event-actions {
    display: flex;
    flex-direction: column;

    .event-action-list {
      padding-left: 0;
      list-style-type: none;

      .action-item {
        margin-bottom: 1em;

        .description {
          font-weight: normal;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .event-action {
          border-radius: 4px;
          display: grid;
          align-items: center;
          align-items: stretch;
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          padding: 1em;
          color: #030852;
          background-color: #bae0ff;
          border: none;
          cursor: pointer;
          overflow: hidden;

          &.event-action-close {
            color: #ffffff;
            background-color: #cf1322;
          }
        }
      }
    }
  }

  .indicators {
    position: relative;
    display: block;
    padding: 1em 0;
    overflow-x: auto;

    .indicators-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;

      .indicator-item {
        display: flex;
        flex-direction: column;
        margin-right: 1em;
        border-radius: 4px;
        padding: 5px 10px;
        color: #030852;
        background-color: #bae0ff;
        text-align: center;

        &:last-child {
          margin-right: 0;
        }

        span {
          display: block;
          min-width: 100px;
          white-space: nowrap;
        }

        .count {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
  }

  .event-table {
    display: block;
    width: 100%;
    height: 500px;
    overflow-x: auto;
    padding: 0;

    .event-table-compact {
      position: relative;
      color: #07203c;
      background-color: #ffffff;
      border-collapse: initial;
      border-spacing: 0;
      width: 100%;
      text-align: center;
      border-radius: 4px;
      overflow: scroll;

      thead {
        background-color: #fff;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

        tr {
          th {
            border: 1px solid #ccc;
            min-width: 60px;

            &.highlight {
              background-color: #eee;
            }
          }

          &:nth-child(1) {
            th:nth-child(2) {
              min-width: 100px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            border: 1px solid #ccc;

            &.highlight {
              background-color: #eee;
            }
          }
        }
      }
    }
  }

  .scroller {
    & {
      scrollbar-width: thin;
      scrollbar-color: #243d5c #001529;
    }

    &::-webkit-scrollbar {
      width: 4px;
      width: 4px;
      height: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: #001529;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: #001529;
    }

    &::-webkit-scrollbar-track:active {
      background-color: #001529;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #243d5c;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #243d5c;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: #243d5c;
    }
  }

  .flow-info {
    .flow-info-table {
      text-align: center;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;

      thead {
        background-color: #bae0ff;

        tr {
          th {
            border: 1px solid #bae0ff;
          }
        }
      }

      tbody {
        background-color: #ffffff;

        tr {
          td {
            border: 1px solid #ccc;
          }
        }
      }
    }
  }

  .quick-actions {
    position: relative;
    display: block;
    padding: 1em 0;
    overflow-x: auto;

    .quick-actions-list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 0;
      margin-bottom: 0;
      list-style-type: none;

      .quick-item {
        margin-right: 1em;

        &:last-child {
          margin-right: 0;
        }

        .action {
          border-radius: 4px;
          display: grid;
          align-items: center;
          align-items: stretch;
          font-size: 14px;
          font-weight: 700;
          width: 100%;
          padding: 1em;
          color: #873800;
          background-color: #ffe7ba;
          border: none;
          cursor: pointer;
          overflow: hidden;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .monitor {
    .monitor-middle {
      grid-template-columns: 15% 85%;
      grid-template-rows: 1fr;

      .monitor-left {
        flex-direction: column;
      }
    }
  }
}
</style>
