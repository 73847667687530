import { route } from "@/helper/route";
import auth from "./middleware/auth";
import profile from "./middleware/profile";

export default [
  {
    path: "/poc-quality-controls",
    name: "poc-quality-control",
    component: route("pocQc/index"),
    meta: {
      title: "POC Quality Controls",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/poc-quality-control/create",
    name: "create-poc-quality-control",
    component: route("pocQc/store"),
    meta: {
      title: "Create POC Quality Control",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/poc-quality-control/edit/:id",
    name: "edit-poc-quality-control",
    component: route("pocQc/store"),
    meta: {
      title: "Edit POC Quality Control",
      layout: "app",
      middleware: [auth, profile],
    },
  },
  {
    path: "/poc-quality-control/show/:id",
    name: "show-poc-quality-control",
    component: route("pocQc/show"),
    meta: {
      title: "Show POC Quality Control",
      layout: "app",
      middleware: [auth, profile],
    },
  },
];
