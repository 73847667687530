<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      :title="title"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card size="small" :loading="cardLoader" hoverable>
      <a-form
        name="poc_quality_control"
        ref="poc_quality_control"
        :model="model"
        :rules="rules"
        layout="vertical"
        @finish="handleFinish"
      >
        <a-row :gutter="[20, 0]">
          <a-col :span="24">
            <a-form-item
              ref="asset_inventory_id"
              label="Select POC device used"
              name="asset_inventory_id"
            >
              <a-select
                v-model:value="model.asset_inventory_id"
                placeholder="Search device by serial number"
                optionFilterProp="label"
                showSearch
                @search="fetchAssetInventory"
                @change="handleDeviceChange"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>

                <a-select-option
                  v-for="option in assetInventory"
                  :key="option.id"
                  :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
                  :value="option.id"
                >
                  {{ option.asset_product?.name }} | {{ option.model_no }} |
                  {{ option.serial_no }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-divider orientation="left">CG8+ Level 1</a-divider>
            <a-form-item
              ref="cg8_plus_level_1_ph"
              label="pH"
              name="cg8_plus_level_1_ph"
            >
              <a-input
                v-model:value="model.cg8_plus_level_1_ph"
                placeholder="pH"
                @input="formatPH('cg8_plus_level_1_ph')"
              />
            </a-form-item>
            <a-form-item
              ref="cg8_plus_level_1_pco2"
              label="pCO2"
              name="cg8_plus_level_1_pco2"
            >
              <a-input
                v-model:value="model.cg8_plus_level_1_pco2"
                placeholder="pCO2"
                @input="formatPCO2('cg8_plus_level_1_pco2')"
              />
            </a-form-item>
            <a-form-item
              ref="cg8_plus_level_1_po2"
              label="pO2"
              name="cg8_plus_level_1_po2"
            >
              <a-input
                v-model:value="model.cg8_plus_level_1_po2"
                placeholder="pO2"
                @input="formatPO2('cg8_plus_level_1_po2')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-divider orientation="left">CG8+ Level 3</a-divider>
            <a-form-item
              ref="cg8_plus_level_3_ph"
              label="pH"
              name="cg8_plus_level_3_ph"
            >
              <a-input
                v-model:value="model.cg8_plus_level_3_ph"
                placeholder="pH"
                @input="formatPH('cg8_plus_level_3_ph')"
              />
            </a-form-item>
            <a-form-item
              ref="cg8_plus_level_3_pco2"
              label="pCO2"
              name="cg8_plus_level_3_pco2"
            >
              <a-input
                v-model:value="model.cg8_plus_level_3_pco2"
                placeholder="pCO2"
                @input="formatPCO2('cg8_plus_level_3_pco2')"
              />
            </a-form-item>
            <a-form-item
              ref="cg8_plus_level_3_po2"
              label="pO2"
              name="cg8_plus_level_3_po2"
            >
              <a-input
                v-model:value="model.cg8_plus_level_3_po2"
                placeholder="pO2"
                @input="formatPO2('cg8_plus_level_3_po2')"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-divider orientation="left">CG4+ Level 1</a-divider>
            <a-form-item
              ref="cg4_plus_level_1_ph"
              label="pH"
              name="cg4_plus_level_1_ph"
            >
              <a-input
                v-model:value="model.cg4_plus_level_1_ph"
                placeholder="pH"
                @input="formatPH('cg4_plus_level_1_ph')"
              />
            </a-form-item>
            <a-form-item
              ref="cg4_plus_level_1_pco2"
              label="pCO2"
              name="cg4_plus_level_1_pco2"
            >
              <a-input
                v-model:value="model.cg4_plus_level_1_pco2"
                placeholder="pCO2"
                @input="formatPCO2('cg4_plus_level_1_pco2')"
              />
            </a-form-item>
            <a-form-item
              ref="cg4_plus_level_1_po2"
              label="pO2"
              name="cg4_plus_level_1_po2"
            >
              <a-input
                v-model:value="model.cg4_plus_level_1_po2"
                placeholder="pO2"
                @input="formatPO2('cg4_plus_level_1_po2')"
              />
            </a-form-item>
            <a-form-item
              ref="cg4_plus_level_1_lactate"
              label="Lactate"
              name="cg4_plus_level_1_lactate"
            >
              <a-input
                v-model:value="model.cg4_plus_level_1_lactate"
                placeholder="Lactate"
                @input="formatLactate('cg4_plus_level_1_lactate')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-divider orientation="left">CG4+ Level 3</a-divider>
            <a-form-item
              ref="cg4_plus_level_3_ph"
              label="pH"
              name="cg4_plus_level_3_ph"
            >
              <a-input
                v-model:value="model.cg4_plus_level_3_ph"
                placeholder="pH"
                @input="formatPH('cg4_plus_level_3_ph')"
              />
            </a-form-item>
            <a-form-item
              ref="cg4_plus_level_3_pco2"
              label="pCO2"
              name="cg4_plus_level_3_pco2"
            >
              <a-input
                v-model:value="model.cg4_plus_level_3_pco2"
                placeholder="pCO2"
                @input="formatPCO2('cg4_plus_level_3_pco2')"
              />
            </a-form-item>
            <a-form-item
              ref="cg4_plus_level_3_po2"
              label="pO2"
              name="cg4_plus_level_3_po2"
            >
              <a-input
                v-model:value="model.cg4_plus_level_3_po2"
                placeholder="pO2"
                @input="formatPO2('cg4_plus_level_3_po2')"
              />
            </a-form-item>
            <a-form-item
              ref="cg4_plus_level_3_lactate"
              label="Lactate"
              name="cg4_plus_level_3_lactate"
            >
              <a-input
                v-model:value="model.cg4_plus_level_3_lactate"
                placeholder="Lactate"
                @input="formatLactate('cg4_plus_level_3_lactate')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-divider orientation="left">i-STAT TriControls Level 1</a-divider>
            <a-form-item
              ref="istat_tricontrols_lavel_1_lot_number"
              label="Lot#"
              name="istat_tricontrols_lavel_1_lot_number"
            >
              <a-input
                v-model:value="model.istat_tricontrols_lavel_1_lot_number"
                placeholder="Lot#"
              />
            </a-form-item>
            <a-form-item
              ref="istat_tricontrols_lavel_1_expiry_date"
              label="Expiration Date"
              name="istat_tricontrols_lavel_1_expiry_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.istat_tricontrols_lavel_1_expiry_date"
                :disabled-date="$comman.disabledPastDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-divider orientation="left">i-STAT TriControls Level 3</a-divider>
            <a-form-item
              ref="istat_tricontrols_lavel_3_lot_number"
              label="Lot#"
              name="istat_tricontrols_lavel_3_lot_number"
            >
              <a-input
                v-model:value="model.istat_tricontrols_lavel_3_lot_number"
                placeholder="Lot#"
              />
            </a-form-item>
            <a-form-item
              ref="istat_tricontrols_lavel_3_expiry_date"
              label="Expiration Date"
              name="istat_tricontrols_lavel_3_expiry_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.istat_tricontrols_lavel_3_expiry_date"
                :disabled-date="$comman.disabledPastDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-divider orientation="left">CG8+ Cartridge</a-divider>
            <a-form-item
              ref="cg8_plus_cartridge_lot_number"
              label="Lot#"
              name="cg8_plus_cartridge_lot_number"
            >
              <a-input
                v-model:value="model.cg8_plus_cartridge_lot_number"
                placeholder="Lot#"
              />
            </a-form-item>
            <a-form-item
              ref="cg8_plus_cartridge_expiry_date"
              label="Expiration Date"
              name="cg8_plus_cartridge_expiry_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.cg8_plus_cartridge_expiry_date"
                :disabled-date="$comman.disabledPastDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-divider orientation="left">CG4+ Cartridge</a-divider>
            <a-form-item
              ref="cg4_plus_cartridge_lot_number"
              label="Lot#"
              name="cg4_plus_cartridge_lot_number"
            >
              <a-input
                v-model:value="model.cg4_plus_cartridge_lot_number"
                placeholder="Lot#"
              />
            </a-form-item>
            <a-form-item
              ref="cg4_plus_cartridge_expiry_date"
              label="Expiration Date"
              name="cg4_plus_cartridge_expiry_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.cg4_plus_cartridge_expiry_date"
                :disabled-date="$comman.disabledPastDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-divider orientation="left">i-STAT ACT Control Level 1</a-divider>
            <a-form-item
              ref="istat_act_control_lavel_1_lot_number"
              label="Lot#"
              name="istat_act_control_lavel_1_lot_number"
            >
              <a-input
                v-model:value="model.istat_act_control_lavel_1_lot_number"
                placeholder="Lot#"
              />
            </a-form-item>
            <a-form-item
              ref="istat_act_control_lavel_1_expiry_date"
              label="Expiration Date"
              name="istat_act_control_lavel_1_expiry_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.istat_act_control_lavel_1_expiry_date"
                :disabled-date="$comman.disabledPastDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-divider orientation="left">i-STAT ACT Control Level 2</a-divider>
            <a-form-item
              ref="istat_act_control_lavel_2_lot_number"
              label="Lot#"
              name="istat_act_control_lavel_2_lot_number"
            >
              <a-input
                v-model:value="model.istat_act_control_lavel_2_lot_number"
                placeholder="Lot#"
              />
            </a-form-item>
            <a-form-item
              ref="istat_act_control_lavel_2_expiry_date"
              label="Expiration Date"
              name="istat_act_control_lavel_2_expiry_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.istat_act_control_lavel_2_expiry_date"
                :disabled-date="$comman.disabledPastDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-divider orientation="left">ACT</a-divider>
            <a-form-item ref="act_lavel_1" label="Level 1" name="act_lavel_1">
              <a-input
                v-model:value="model.act_lavel_1"
                placeholder="Level 1"
                @input="formatPO2('act_lavel_1')"
              />
            </a-form-item>
            <a-form-item ref="act_lavel_2" label="Level 2" name="act_lavel_2">
              <a-input
                v-model:value="model.act_lavel_2"
                placeholder="Level 2"
                @input="formatPO2('act_lavel_2')"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-divider orientation="left"
              >i-STAT Kaolin ACT Cartridge</a-divider
            >
            <a-form-item
              ref="istat_kaolin_act_cartridge_lot_number"
              label="Lot#"
              name="istat_kaolin_act_cartridge_lot_number"
            >
              <a-input
                v-model:value="model.istat_kaolin_act_cartridge_lot_number"
                placeholder="Lot#"
              />
            </a-form-item>
            <a-form-item
              ref="istat_kaolin_act_cartridge_expiry_date"
              label="Expiration Date"
              name="istat_kaolin_act_cartridge_expiry_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.istat_kaolin_act_cartridge_expiry_date"
                :disabled-date="$comman.disabledPastDate"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>
          <a-divider></a-divider>
          <a-col :span="12">
            <a-form-item
              ref="completed_date"
              label="Date Completed"
              name="completed_date"
            >
              <a-date-picker
                :allowClear="false"
                v-model:value="model.completed_date"
                :format="$constants.datepickerFormat"
                :placeholder="$constants.datePickerPlaceholder"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item ref="due_date" label="Date Due" name="due_date">
              <a-date-picker
                v-model:value="model.due_date"
                :disabled="true"
                :format="$constants.datepickerFormat"
                placeholder="Due Date"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="12">
            <a-form-item
              label="Is this new I-STAT device undergoing its initial QC?"
              name="is_device_undergoing"
            >
              <a-radio-group v-model:value="model.is_device_undergoing">
                <a-radio
                  v-for="v in $constants.yesNo"
                  :key="v.value"
                  :value="v.value"
                >
                  {{ v.text }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item
              label="Is this new I-STAT cartridge lot undergoing its initial QC?"
              name="is_cartridge_lot_undergoing"
            >
              <a-radio-group v-model:value="model.is_cartridge_lot_undergoing">
                <a-radio
                  v-for="v in $constants.yesNo"
                  :key="v.value"
                  :value="v.value"
                >
                  {{ v.text }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :sm="12">
            <a-form-item label="Notes" name="notes">
              <a-textarea
                v-model:value="model.notes"
                :rows="4"
                :disabled="submitting"
                placeholder="Notes"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row type="flex" justify="center">
          <a-space direction="vertical">
            <vue-signature-pad
              :options="{ onEnd: save }"
              ref="signaturePad"
              :customStyle="$constants.signPadCustomCss"
            />

            <a-row type="flex" justify="center">
              <a-col>
                <a-typography-paragraph>
                  Employee signature indicates that the information reported is
                  complete and accurate.
                </a-typography-paragraph>
              </a-col>
            </a-row>
            <a-row v-if="model.signature" justify="center">
              <a-space>
                <a-button
                  htmlType="button"
                  type="primary"
                  danger
                  ghost
                  @click="clear"
                >
                  Clear
                </a-button>
              </a-space>
            </a-row>
          </a-space>
        </a-row>
        <a-button type="primary" html-type="submit" :loading="submitBtnLoader">
          {{ submitBtnText }}
        </a-button>
      </a-form>
    </a-card>
  </a-layout-content>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import backButton from "../../components/backButton.vue";
import { pocQCRule } from "../../helper/formRules";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services";

export default {
  components: { backButton },

  data() {
    return {
      cardLoader: true,
      submitBtnLoader: false,
      fetching: false,
      fetchingTimeOut: null,
      assetInventory: [],
      deviceId: this.$route.query?.device ?? null,
      model: {
        id: this.$route.params.id ?? 0,
        employee_id: 0,
        asset_inventory_id: null,
        asset_manufacturer_id: null,
        owned_by: null,
        hospital_id: null,
        company_id: null,
        allocated_to: null,
        asset_inventory_data: null,

        cg8_plus_level_1_ph: null,
        cg8_plus_level_1_pco2: null,
        cg8_plus_level_1_po2: null,

        cg8_plus_level_3_ph: null,
        cg8_plus_level_3_pco2: null,
        cg8_plus_level_3_po2: null,

        cg4_plus_level_1_ph: null,
        cg4_plus_level_1_pco2: null,
        cg4_plus_level_1_po2: null,
        cg4_plus_level_1_lactate: null,

        cg4_plus_level_3_ph: null,
        cg4_plus_level_3_pco2: null,
        cg4_plus_level_3_po2: null,
        cg4_plus_level_3_lactate: null,

        istat_tricontrols_lavel_1_lot_number: null,
        istat_tricontrols_lavel_1_expiry_date: null,

        istat_tricontrols_lavel_3_lot_number: null,
        istat_tricontrols_lavel_3_expiry_date: null,

        cg8_plus_cartridge_lot_number: null,
        cg8_plus_cartridge_expiry_date: null,

        cg4_plus_cartridge_lot_number: null,
        cg4_plus_cartridge_expiry_date: null,

        istat_act_control_lavel_1_lot_number: null,
        istat_act_control_lavel_1_expiry_date: null,

        istat_act_control_lavel_2_lot_number: null,
        istat_act_control_lavel_2_expiry_date: null,

        act_lavel_1: null,
        act_lavel_2: null,

        istat_kaolin_act_cartridge_lot_number: null,
        istat_kaolin_act_cartridge_expiry_date: null,

        completed_date: null,
        due_date: null,
        signature: null,
        signatured_at: null,

        is_device_undergoing: null,
        is_cartridge_lot_undergoing: null,
        notes: null,
      },
      rules: pocQCRule,
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("auth", ["employee"]),

    title() {
      if (this.model.id == 0) {
        return "Add New POC Quality Control";
      }
      return "Edit POC Quality Control";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },
  },

  async mounted() {
    await this.fetchDevices();
    if (this.model.id != 0) {
      await this.fetchCellSaverQC();
    }

    if (this.deviceId) {
      await this.fetchData({ id: this.deviceId });
      this.model.asset_inventory_id = parseInt(this.deviceId);
      this.handleDeviceChange(this.deviceId);
    }

    this.cardLoader = false;
    setTimeout(() => {
      this.$refs.signaturePad.fromDataURL(this.model.signature);
    }, 500);
  },

  watch: {
    "model.completed_date"(newVal) {
      if (newVal) {
        this.model.due_date = moment(newVal).add(30, "days");
      } else {
        this.model.due_date = null;
      }
    },
  },

  methods: {
    formatPH(field) {
      let value = this.model[field];
      if (value) {
        this.model[field] = value
          .replace(/[^0-9.]/g, "")
          .replace(/^(\d*\.\d{0,3})\d*$/, "$1");
      }
    },

    formatPCO2(field) {
      let value = this.model[field];
      if (value) {
        this.model[field] = value
          .replace(/[^0-9.]/g, "")
          .replace(/^(\d*\.\d{0,1})\d*$/, "$1");
      }
    },

    formatPO2(field) {
      let value = this.model[field];
      if (value) {
        this.model[field] = value.replace(/\D/g, "");
      }
    },

    formatLactate(field) {
      let value = this.model[field];
      if (value) {
        this.model[field] = value
          .replace(/[^0-9.]/g, "")
          .replace(/^(\d*\.\d{0,2})\d*$/, "$1");
      }
    },

    fetchCellSaverQC() {
      return commonService
        .get(this.$constants.pointOfCareQcShowUrl, { id: this.model.id })
        .then(async (res) => {
          if (!res.success) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          let data = res.data;

          this.model = {
            id: this.model.id,
            asset_inventory_id: data.asset_inventory_id,
            hospital_id: data.hospital_id,
            asset_product_id: data.asset_product_id,
            asset_manufacturer_id: data.asset_manufacturer_id,
            owned_by: data.owned_by,
            hospital_id: data.hospital_id,
            company_id: data.company_id,
            allocated_to: data.allocated_to,
            asset_inventory_data: data.asset_inventory_data,

            cg8_plus_level_1_ph: data.cg8_plus_level_1_ph,
            cg8_plus_level_1_pco2: data.cg8_plus_level_1_pco2,
            cg8_plus_level_1_po2: data.cg8_plus_level_1_po2,

            cg8_plus_level_3_ph: data.cg8_plus_level_3_ph,
            cg8_plus_level_3_pco2: data.cg8_plus_level_3_pco2,
            cg8_plus_level_3_po2: data.cg8_plus_level_3_po2,

            cg4_plus_level_1_ph: data.cg4_plus_level_1_ph,
            cg4_plus_level_1_pco2: data.cg4_plus_level_1_pco2,
            cg4_plus_level_1_po2: data.cg4_plus_level_1_po2,
            cg4_plus_level_1_lactate: data.cg4_plus_level_1_lactate,

            cg4_plus_level_3_ph: data.cg4_plus_level_3_ph,
            cg4_plus_level_3_pco2: data.cg4_plus_level_3_pco2,
            cg4_plus_level_3_po2: data.cg4_plus_level_3_po2,
            cg4_plus_level_3_lactate: data.cg4_plus_level_3_lactate,

            istat_tricontrols_lavel_1_lot_number:
              data.istat_tricontrols_lavel_1_lot_number,
            istat_tricontrols_lavel_1_expiry_date:
              data.istat_tricontrols_lavel_1_expiry_date,

            istat_tricontrols_lavel_3_lot_number:
              data.istat_tricontrols_lavel_3_lot_number,
            istat_tricontrols_lavel_3_expiry_date:
              data.istat_tricontrols_lavel_3_expiry_date,

            cg8_plus_cartridge_lot_number: data.cg8_plus_cartridge_lot_number,
            cg8_plus_cartridge_expiry_date: data.cg8_plus_cartridge_expiry_date,

            cg4_plus_cartridge_lot_number: data.cg4_plus_cartridge_lot_number,
            cg4_plus_cartridge_expiry_date: data.cg4_plus_cartridge_expiry_date,

            istat_act_control_lavel_1_lot_number:
              data.istat_act_control_lavel_1_lot_number,
            istat_act_control_lavel_1_expiry_date:
              data.istat_act_control_lavel_1_expiry_date,

            istat_act_control_lavel_2_lot_number:
              data.istat_act_control_lavel_2_lot_number,
            istat_act_control_lavel_2_expiry_date:
              data.istat_act_control_lavel_2_expiry_date,

            act_lavel_1: data.act_lavel_1,
            act_lavel_2: data.act_lavel_2,

            istat_kaolin_act_cartridge_lot_number:
              data.istat_kaolin_act_cartridge_lot_number,
            istat_kaolin_act_cartridge_expiry_date:
              data.istat_kaolin_act_cartridge_expiry_date,

            is_device_undergoing: data.is_device_undergoing,
            is_cartridge_lot_undergoing: data.is_cartridge_lot_undergoing,
            notes: data.notes,
            completed_date: data.completed_date,
            due_date: data.due_date,
            signature: data.signature,
            signatured_at: data.signatured_at,
          };

          await this.fetchData({ id: data.asset_inventory_id });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },

    fetchAssetInventory(value) {
      this.fetching = true;
      clearTimeout(this.fetchingTimeOut);

      this.fetchingTimeOut = setTimeout(this.fetchData, 500, {
        search: value,
      });
    },

    fetchData(value) {
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          ...value,
          status: [1, 2],
          is_point_of_care: true,
        })
        .then((res) => {
          this.assetInventory = res.data;
        })
        .catch(() => (this.assetInventory = []))
        .finally(() => (this.fetching = false));
    },

    fetchDevices() {
      this.fetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_point_of_care: true,
        })
        .then((res) => {
          this.assetInventory = res.data;
        })
        .catch(() => (this.assetInventory = []))
        .finally(() => (this.fetching = false));
    },

    handleDeviceChange(id) {
      let asset = this.assetInventory.find((item) => item.id == id);
      this.model.asset_product_id = asset.asset_product_id;
      this.model.asset_manufacturer_id = asset.asset_manufacturer_id;
      this.model.company_id = asset.company_id;
      this.model.hospital_id = asset.hospital_id;
      this.model.owned_by = asset.owned_by;
      this.model.allocated_to =
        asset.asset_inventory_hospital?.hospital_id ?? null;
      this.model.asset_inventory_data = asset;
    },

    handleFinish() {
      if (this.model.signature == null) {
        this.$message.error("Signature must be required!");
        return;
      }

      if (this.model.id == 0) {
        this.model.employee_id = this.employee.id;
      }

      const dateFields = [
        "istat_tricontrols_lavel_1_expiry_date",
        "istat_tricontrols_lavel_3_expiry_date",
        "cg8_plus_cartridge_expiry_date",
        "cg4_plus_cartridge_expiry_date",
        "istat_act_control_lavel_1_expiry_date",
        "istat_act_control_lavel_2_expiry_date",
        "istat_kaolin_act_cartridge_expiry_date",
        "completed_date",
        "due_date",
      ];

      dateFields.forEach((field) => {
        if (this.model[field]) {
          this.model[field] = this.$customDate.ymd(this.model[field]);
        }
      });

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.pointOfCareQcStoreUrl, {
          ...this.model,
        })
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.$router.go(-1);
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    clear() {
      this.model.signature = null;
      this.model.signatured_at = null;
      this.$refs.signaturePad.clearSignature();
    },

    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.signature = data;
      this.model.signatured_at = this.$customDate.ymd(this.$comman.momentTz());
      return Promise.resolve();
    },
  },
};
</script>
