<template>
  <div style="margin-bottom: 10px">
    <a-descriptions size="small" bordered :column="2">
      <a-descriptions-item label="Invoice Date">{{
        this.$customDate.mdy(record.date)
      }}</a-descriptions-item>
      <a-descriptions-item label="Due Date">{{
        this.$customDate.mdy(record.due_date)
      }}</a-descriptions-item>
      <a-descriptions-item label="Amount">{{
        $comman.withCurrency(record.amount)
      }}</a-descriptions-item>
      <a-descriptions-item label="Open Balance">{{
        $comman.withCurrency(record.open_balance)
      }}</a-descriptions-item>
      <a-descriptions-item label="Due Since">
        {{ $comman.getDataDiffDays(record.due_date) }} days
      </a-descriptions-item>
    </a-descriptions>
  </div>
  <template v-if="!editMode">
    <a-form :model="model" layout="vertical" @finish="onSubmit" ref="noteForm">
      <a-form-item
        name="followup"
        :rules="{
          required: true,
          message: 'This field is required.',
        }"
      >
        <a-textarea
          v-model:value="model.followup"
          placeholder="Enter note here..."
          :auto-size="{ minRows: 4 }"
        />
      </a-form-item>

      <a-button htmlType="submit" type="primary" :loading="loading">
        Add Follow-up
      </a-button>
    </a-form>
  </template>
  <template v-if="followups.length > 0">
    <a-divider orientation="left">Follow-up History</a-divider>

    <a-list
      class="note-histories"
      item-layout="horizontal"
      :data-source="followups"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-comment>
            <template #author>
              <div style="display: flex; align-items: center; gap: 8px">
                <span>{{ item?.creator?.full_name }}</span>
              </div>
            </template>

            <template #content>
              <div v-if="editedNoteId === item.id">
                <a-textarea
                  v-model:value="editedNote"
                  placeholder="Edit your note"
                  style="width: 600px"
                  :auto-size="{ minRows: 4 }"
                /><br />
                <a-space style="margin-top: 10px">
                  <a-button
                    :loading="noteLoading"
                    type="primary"
                    size="small"
                    @click="saveNote(item)"
                  >
                    Save
                  </a-button>
                  <a-button size="small" @click="cancelEdit"> Cancel </a-button>
                </a-space>
              </div>
              <p v-else>{{ item.followup }}</p>
            </template>

            <template #datetime>
              <a-space class="comment-section">
                <a-tooltip
                  :title="moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')"
                >
                  <span>{{ moment(item.created_at).fromNow() }}</span>
                </a-tooltip>
              </a-space>
            </template>
          </a-comment>
          <template #actions>
            <template v-if="item.creator.id == employee.id && !editMode">
              <a-tooltip title="Edit">
                <a-button type="link" size="small" @click="editNote(item)">
                  <i class="ti ti-pencil ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>
          </template>
        </a-list-item>
      </template>
    </a-list>
  </template>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../../mixins/formMixins";
import { commonService } from "../../../../services";

export default {
  components: {
    PlusOutlined,
  },

  emits: ["callback"],

  data() {
    return {
      moment,
      model: {
        followup: null,
      },
      followups: [],
      editedNoteId: null,
      editedNote: "",
      editMode: false,
      noteLoading: false,
      popConfirmLoading: false,
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "loading", "extra"]),
    ...mapGetters("auth", ["isAdmin", "employee"]),
  },
  mounted() {
    this.followups = this.record.followups;
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    async onSubmit() {
      this.loadingStart();

      const payload = {
        id: this.record.id,
        qbo_invoice_id: this.record.qbo_invoice_id,
        followup: this.model.followup,
      };

      try {
        const res = await commonService.store(
          this.$constants.qboInvoiceFollowup,
          payload
        );
        this.$message.success(res.message);
        this.followups = res.data;
        this.$emit("callback", res.data);
        this.model.followup = null;
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loadingStop();
      }
    },

    editNote(item) {
      this.editMode = true;
      this.editedNoteId = item.id;
      this.editedNote = item.followup;
    },

    cancelEdit() {
      this.editMode = false;
      this.editedNoteId = null;
      this.editedNote = "";
    },

    async saveNote(item) {
      if (!this.editedNote.trim()) {
        this.$message.error("Note cannot be empty.");
        return;
      }

      const payload = {
        id: item.id,
        followup: this.editedNote,
      };
      this.noteLoading = true;
      try {
        const res = await commonService.store(
          this.$constants.qboInvoiceUpdateFollowup,
          payload
        );
        this.$message.success(res.message);
        item.followup = this.editedNote;
        this.cancelEdit();
      } catch (err) {
        this.$message.error(err.message || "Failed to update note.");
      } finally {
        this.editMode = false;
        this.noteLoading = false;
      }
    },
  },
};
</script>

<style lang="less">
.note-histories {
  .ant-comment {
    .ant-comment-inner {
      padding: 0;
    }
  }
}
</style>
