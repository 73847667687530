import { constants } from "./constants";
import { validation } from "./validation";

const employeeFormRules = {
  piRules: {
    companyId: [
      {
        required: true,
        message: "Company is required!",
        type: "number",
        trigger: ["change", "blur"],
      },
    ],
    employeeStatus: [
      {
        required: true,
        message: "Employee status is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    insperityHireDate: [
      {
        required: true,
        message: "Insperity hire date is required!",
        trigger: ["change", "blur"],
        type: "date",
      },
    ],
    clientHireDate: [
      {
        required: true,
        message: "Client hire date is required!",
        trigger: ["change", "blur"],
        type: "date",
      },
    ],
    firstName: [
      {
        required: true,
        message: "First name is required!",
        trigger: ["change", "blur"],
      },
    ],
    // middleName: [
    //   {
    //     required: true,
    //     message: "Middle name is required!",
    //     trigger: ["change", "blur"],
    //   },
    // ],
    lastName: [
      {
        required: true,
        message: "Last name is required!",
        trigger: ["change", "blur"],
      },
    ],
    email: [
      {
        required: true,
        message: "Email is required!",
        trigger: ["change", "blur"],
      },
      {
        type: "email",
        message: "Email must be a valid email!",
        trigger: ["change", "blur"],
      },
    ],
    dateOfBirth: [
      {
        required: true,
        message: "Date of birth is required!",
        trigger: ["change", "blur"],
        type: "date",
      },
    ],
    gender: [
      {
        required: true,
        message: "Gender is required!",
        trigger: ["change", "blur"],
      },
    ],
    ssn: [
      {
        required: true,
        message: "Social security number is required!",
        trigger: ["change", "blur"],
      },
    ],
    cssn: [
      {
        required: true,
        message: "Confirm social security number is required!",
        trigger: ["change", "blur"],
      },
    ],
    streetAddress: [
      {
        required: true,
        message: "Street address is required!",
        trigger: ["change", "blur"],
      },
    ],
    city: [
      {
        required: true,
        message: "City is required!",
        trigger: ["change", "blur"],
      },
    ],
    state: [
      {
        required: true,
        message: "State is required!",
        trigger: ["change", "blur"],
      },
    ],
    zipCode: [
      {
        required: true,
        message: "Zip Code is required!",
        trigger: ["change", "blur"],
      },
    ],
  },
  aiRules: {
    workOption: [
      {
        required: true,
        message: "Work Option is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    workAddress: [
      {
        required: true,
        message: "Work address is required!",
        trigger: ["change", "blur"],
      },
    ],
    workCity: [
      {
        required: true,
        message: "Work city is required!",
        trigger: ["change", "blur"],
      },
    ],
    workState: [
      {
        required: true,
        message: "Work state is required!",
        trigger: ["change", "blur"],
      },
    ],
    workZip: [
      {
        required: true,
        message: "Work zip is required!",
        trigger: ["change", "blur"],
      },
    ],
    reportingOption: [
      {
        required: true,
        message: "Reporting Option is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    reportingAddress: [
      {
        required: true,
        message: "Reporting address is required!",
        trigger: ["change", "blur"],
      },
    ],
    reportingCity: [
      {
        required: true,
        message: "Reporting city is required!",
        trigger: ["change", "blur"],
      },
    ],
    reportingState: [
      {
        required: true,
        message: "Reporting state is required!",
        trigger: ["change", "blur"],
      },
    ],
    reportingZip: [
      {
        required: true,
        message: "Reporting zip is required!",
        trigger: ["change", "blur"],
      },
    ],
    deliveryOption: [
      {
        required: true,
        message: "Delivery Option is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    deliveryAddress: [
      {
        required: true,
        message: "Delivery address is required!",
        trigger: ["change", "blur"],
      },
    ],
    deliveryCity: [
      {
        required: true,
        message: "Delivery city is required!",
        trigger: ["change", "blur"],
      },
    ],
    deliveryState: [
      {
        required: true,
        message: "Delivery state is required!",
        trigger: ["change", "blur"],
      },
    ],
    deliveryZip: [
      {
        required: true,
        message: "Delivery zip is required!",
        trigger: ["change", "blur"],
      },
    ],
  },
  poiRules: {
    workersCompensationCode: [
      {
        required: true,
        message: "Worker company code is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    jobFunctionID: [
      {
        required: true,
        message: "Job function is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    jobTitle: [
      {
        required: true,
        message: "Job title is required!",
        trigger: ["change", "blur"],
      },
    ],
    supervisesOthers: [
      {
        required: true,
        message: "Supervises other is required!",
        trigger: ["change", "blur"],
      },
    ],
  },
  eiRules: {
    isUnionMember: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
    employeeAgreementCategory: [
      {
        required: true,
        message: "Employee agreement category is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    ownerStatus: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
    employeeJobCategory: [
      {
        required: true,
        message: "Employee job category is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    workEmail: [
      {
        required: true,
        message: "Work email is required!",
        trigger: ["change", "blur"],
      },
      {
        type: "email",
        message: "email must be a valid email!",
        trigger: ["change", "blur"],
      },
    ],
    willWorkMultiStates: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
    willWorkInternational: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
  },
  payiRules: {
    classification: [
      {
        required: true,
        message: "Classification is required!",
        trigger: ["change", "blur"],
        type: "string",
      },
    ],
    temporaryIndicator: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
    isExemptFromOvertime: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
    isPaidTips: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
    isPaidPiecework: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
    employeePaid: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
        type: "array",
      },
    ],
    isAllowanceProvided: [
      {
        required: true,
        message: "This field is required!",
        trigger: ["change", "blur"],
      },
    ],
    adminEmail: [
      {
        required: true,
        message: "Addministrator email address is required!",
        trigger: ["change", "blur"],
      },
      {
        type: "email",
        message: "email must be a valid email!",
        trigger: ["change", "blur"],
      },
    ],
    employeeEmail: [
      {
        required: true,
        message: "Employee email address is required!",
        trigger: ["change", "blur"],
      },
      {
        type: "email",
        message: "email must be a valid email!",
        trigger: ["change", "blur"],
      },
    ],
  },
};

const checkoutFormRules = {
  company_id: [
    {
      required: true,
      message: "Company is required!",
      type: "number",
      trigger: ["change", "blur"],
    },
  ],
  hospital_id: [
    {
      required: true,
      message: "Hospital is required!",
      type: "number",
      trigger: ["change", "blur"],
    },
  ],
  termsAndConditions: [
    {
      required: true,
      message: "This  is required!",
      trigger: ["change", "blur"],
      type: "boolean",
      // enum: true,
    },
  ],
};

const emailTemplateRules = {
  name: [
    {
      required: true,
      message: "Name field is required!",
      trigger: ["change", "blur"],
    },
  ],
  value: [
    {
      required: true,
      message: "Template field is required!",
      trigger: ["change", "blur"],
    },
  ],
  // company_email: [
  //   {
  //     required: true,
  //     message: "Company template is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
  // hosptial_email: [
  //   {
  //     required: true,
  //     message: "Hospital template is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
  // creator_email: [
  //   {
  //     required: true,
  //     message: "Order Creator template is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
  // manufacturer_email: [
  //   {
  //     required: true,
  //     message: "Manufacturer template is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
};

const calendarEventRules = {
  title: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
    },
  ],
  hospital_id: [
    {
      required: true,
      message: "Field is required!",
      type: "number",
      trigger: ["change", "blur"],
    },
  ],
  dateRange: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
      type: "array",
    },
  ],
  days: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
      type: "array",
    },
  ],
};

export {
  calendarEventRules, checkoutFormRules,
  emailTemplateRules, employeeFormRules
};

export const employeeCreateFormRules = {
  first_name: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  last_name: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  title: [
    {
      required: true,
      message: "First name is required!",
      trigger: ["change", "blur"],
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  company_id: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      type: "integer",
    },
  ],
  employee_type: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      type: "integer",
    },
  ],
  employment_type: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      type: "integer",
    },
  ],
  position_id: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      type: "integer",
    },
  ],
  gender: [
    {
      message: "This field is required!",
      trigger: ["change", "blur"],
    },
  ],
  dba: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  gusto_employee_code: [
    {
      // required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      transform: (value) => (value ? value.trim() : ""),
    },
  ],
  email: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      type: "email",
    },
  ],
  email_confirmation: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      type: "email",
    },
  ],
  // social_security_no: [
  //   {
  //     required: true,
  //     message: "This field is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
  // social_security_no_confirmation: [
  //   {
  //     required: true,
  //     message: "This field is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
  hire_date: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
      type: "date",
    },
  ],
  contact_no: [
    {
      required: true,
      message: "This field is required!",
      trigger: ["change", "blur"],
    },
  ],
  // street_address: [
  //   {
  //     required: true,
  //     message: "This field is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
  // city: [
  //   {
  //     required: true,
  //     message: "This field is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
  // state: [
  //   {
  //     required: true,
  //     message: "This field is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
  // zipcode: [
  //   {
  //     required: true,
  //     message: "This field is required!",
  //     trigger: ["change", "blur"],
  //   },
  // ],
};

export const expenseRules = {
  date: [
    {
      required: true,
      message: "Date field is required!",
      trigger: ["change", "blur"],
      type: "date",
    },
  ],
  expense_type_id: [
    {
      required: true,
      message: "Expense type field is required!",
      trigger: ["change", "blur"],
      type: "number",
    },
  ],
  hospital_id: [
    {
      required: true,
      message: "Hospital field is required!",
      trigger: ["change", "blur"],
      type: "number",
    },
  ],
  amount: [
    {
      required: true,
      message: "price have max upto 2 decimal points!",
      validator: validation.decimal,
      type: "number",
    }
  ],
  trip_type: [
    {
      required: true,
      message: "Hospital field is required!",
      type: "number"
    }
  ],
  receipts: [
    {
      required: true,
      message: "Receipt field is required!",
      type: "array",
    },
    {
      message: "Number of receipts must be beetween 1 to 10!",
      type: "array",
      min: 0,
      max: 10,
    },
  ],
  date_range: [
    {
      required: true,
      message: "Expense start and end date field is required!",
      trigger: ["change", "blur"],
      type: "array",
    },
  ],
  city: [
    {
      required: true,
      message: "City field is required!",
      trigger: ["change", "blur"],
    },
  ],
  state: [
    {
      required: true,
      message: "State field is required!",
      trigger: ["change", "blur"],
    },
  ],
  origin_address: [
    {
      required: true,
      message: "Origin address field is required!",
    },
  ],
  destination_address: [
    {
      required: true,
      message: "Destination address field is required!",
    },
  ]
};

export const employeeShiftTimeRule = {
  hospital_id: [
    {
      required: true,
      message: "Hospital field is required!",
      trigger: ["change", "blur"],
      type: "number",
    },
  ],
  shift_type_id: [
    {
      required: true,
      message: "Shift type field is required!",
      trigger: ["change", "blur"],
      type: "number",
    },
  ],
  start_date: [
    {
      required: true,
      message: "Start date field is required!",
      trigger: ["change", "blur"],
      type: "date",
    },
  ],
  start_time: [
    {
      required: true,
      message: "Start time field is required!",
    },
    {
      message: "Invalid time!",
      validator: validation.timeFormat,
    },
  ],
  end_date: [
    {
      required: true,
      message: "End date field is required!",
      trigger: ["change", "blur"],
      type: "date",
    },
  ],
  end_time: [
    {
      required: true,
      message: "Start time field is required!",
    },
    {
      message: "Invalid time!",
      validator: validation.timeFormat,
    },
  ],
  on_call_end_date: [
    {
      required: true,
      message: "On call start time field is required!",
      type: "date",
    },
  ],
  on_call_end_time: [
    {
      required: true,
      message: "On call end time field is required!",
    },
    {
      message: "Invalid time!",
      validator: validation.timeFormat,
    },
  ],
  home_departure: [
    {
      required: true,
      message: "This field is required!",
    },
    {
      message: "Invalid time!",
      validator: validation.timeFormat,
    },
  ],
  hospital_arrival: [
    {
      required: true,
      message: "This field is required!",
    },
    {
      message: "Invalid time!",
      validator: validation.timeFormat,
    },
  ],
  hospital_departure: [
    {
      required: true,
      message: "This field is required!",
    },
    {
      message: "Invalid time!",
      validator: validation.timeFormat,
    },
  ],
  home_arrival: [
    {
      required: true,
      message: "This field is required!",
    },
    {
      message: "Invalid time!",
      validator: validation.timeFormat,
    },
  ],
};

export const paidTimeOffRule = {
  hospital_id: [
    {
      required: true,
      message: "Hospital field is required!",
      trigger: ["change", "blur"],
      type: "number",
    },
  ],
  type: [
    {
      required: true,
      message: "Type field is required!",
      trigger: ["change", "blur"],
      type: "number",
    },
  ],
  date_range: [
    {
      required: true,
      message: "Start and end date field is required!",
      trigger: ["change", "blur"],
      type: "array",
    },
  ],
  comments: [
    {
      required: true,
      message: "Comment field is required!",
      trigger: ["change", "blur"],
    },
  ],
};

export const incidentRule = {
  hospital_id: [
    {
      required: true,
      message: "Hospital field is required!",
      trigger: ["change", "blur"],
      type: "number",
    },
  ],
  report_date: [
    {
      required: true,
      message: "Date of report field is required!",
      trigger: ["change", "blur"],
      type: "date",
    },
  ],
  position: [
    {
      required: true,
      message: "Position of reporting person field is required!",
      trigger: ["change", "blur"],
      type: "string",
    },
  ],
  incident_date: [
    {
      required: true,
      message: "Date of incident field is required!",
      trigger: ["change", "blur"],
      type: "date",
    },
  ],
  signature: [
    {
      required: true,
      message: "Signature field is required!",
      trigger: ["change", "blur"],
      type: "boolean",
      validator: async (rule, value) => {
        if (!value) {
          return Promise.reject("Signature field is required!");
        }
        return Promise.resolve();
      },
    },
  ],
};

export const caseServiceRule = {
  name: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
      transform: (value) => value ? value.trim() : '',
    },
  ],
  price: [
    {
      required: true,
      message: "price field is required!",
      trigger: constants.ruleTrigger,
      type: 'number',
    },
    {
      message: 'price have max upto 2 decimal points!',
      trigger: constants.ruleTrigger,
      validator: validation.decimal,
    }
  ],
};

export const cellSaverQCRule = {
  date: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
      type: "date",
    },
  ],
  asset_inventory_id: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
      type: "number",
    },
  ],
  mr_number: [
    {
      required: true,
      message: 'This field is required!',
      trigger: constants.ruleTrigger,
      transform: (value) => (value ? value.trim() : ''),
    },
  ],
  owned_by: [
    {
      required: true,
      message: 'This field is required',
      type: 'number',
    }
  ],
  hospital_id: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
      type: "number",
    },
  ],
  company_id: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
      type: "number",
    },
  ],
  device_manufacturer: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  device_serial_number: [
    {
      required: true,
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
      transform: (value) => value ? value.trim() : '',
    },
  ],
  signature: [
    {
      required: true,
      message: "Signature field is required!",
      trigger: constants.ruleTrigger,
      type: "boolean",
      validator: async (rule, value) => {
        if (!value) {
          return Promise.reject("Signature field is required!");
        }
        return Promise.resolve();
      },
    },
  ],
  r_hematocrit: [
    {
      validator: validation.decimal,
      message: "must be in numbers!",
      trigger: constants.ruleTrigger,
    },
  ],
  r_potassium: [
    {
      validator: validation.decimal,
      message: "must be in numbers!",
      trigger: constants.ruleTrigger,
    },
  ],
  r_total_serum_protein: [
    {
      validator: validation.decimal,
      message: "must be in numbers!",
      trigger: constants.ruleTrigger,
    },
  ],
  s_hematocrit: [
    {
      validator: validation.decimal,
      message: "must be in numbers!",
      trigger: constants.ruleTrigger,
    },
  ],
  s_potassium: [
    {
      validator: validation.decimal,
      message: "must be in numbers!",
      trigger: constants.ruleTrigger,
    },
  ],
  s_total_serum_protein: [
    {
      validator: validation.decimal,
      message: "must be in numbers!",
      trigger: constants.ruleTrigger,
    },
  ],
};



export const pocQCRule = {
  asset_inventory_id: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
      type: "number",
    },
  ],
  cg8_plus_level_1_ph: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg8_plus_level_1_pco2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg8_plus_level_1_po2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg8_plus_level_3_ph: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg8_plus_level_3_pco2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg8_plus_level_3_po2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_level_1_ph: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_level_1_pco2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_level_1_po2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],

  cg4_plus_level_1_lactate: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_level_3_ph: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_level_3_pco2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_level_3_po2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_level_3_lactate: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_tricontrols_lavel_1_lot_number: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_tricontrols_lavel_1_expiry_date: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_tricontrols_lavel_3_lot_number: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_tricontrols_lavel_3_expiry_date: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg8_plus_cartridge_lot_number: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg8_plus_cartridge_expiry_date: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_cartridge_lot_number: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  cg4_plus_cartridge_expiry_date: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_act_control_lavel_1_lot_number: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_act_control_lavel_1_expiry_date: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_act_control_lavel_2_lot_number: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_act_control_lavel_2_expiry_date: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  act_lavel_1: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  act_lavel_2: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_kaolin_act_cartridge_lot_number: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  istat_kaolin_act_cartridge_expiry_date: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  completed_date: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  is_device_undergoing: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
  is_cartridge_lot_undergoing: [
    {
      required: true,
      message: "This field is required!",
    },
  ],
}
export const equipmentMaintenanceRule = {
  date: [
    {
      required: true,
      message: "Date of report field is required!",
      trigger: constants.ruleTrigger,
      type: "date",
    },
  ],
  asset_inventory_id: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
      type: "number",
    },
  ],
  is_device_being_stored_empty: [
    {
      required: true,
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  is_equipment_cleaned: [
    {
      required: true,
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  is_heater_cooler_tubing_change: [
    {
      required: true,
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  tubing_change_date: [
    {
      required: true,
      type: "date",
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  is_livanova_3t_aerosol_collection_set_changed: [
    {
      required: true,
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  livanova_3t_aerosol_collection_set_change_date: [
    {
      required: true,
      type: "date",
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  h2o2_level_measured_semi_quantitatively: [
    {
      required: true,
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
      transform: (value) => value ? value.trim() : '',
    },
  ],
  is_water_sample_obtained: [
    {
      required: true,
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  water_sample_shipped_date: [
    {
      required: true,
      type: "date",
      message: "Device serial number field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
};

export const biomedServiceRecordRule = {
  date: [
    {
      required: true,
      message: "Date of report field is required!",
      trigger: ["change", "blur"],
      type: "date",
    },
  ],
  asset_inventory_id: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
      type: "number",
    },
  ],
  re_enter_operator_settings: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  inspect_system_visually: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  paperwork_completed: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  leakage_current: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  requires_additional_service: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
  install_inspection_label: [
    {
      required: true,
      message: "This field is required!",
      trigger: constants.ruleTrigger,
    },
  ],
};

export const appointmentEventRules = {
  hospital_id: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
      type: "integer",
    },
  ],
  title: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
    },
  ],
  dateRange: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
      type: "array",
    },
  ],
  date: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
      type: "date",
    },
  ],
  days: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
      type: "array",
    },
  ],
  start_time: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
      type: "string",
    },
  ],
  end_time: [
    {
      required: true,
      message: "Field is required!",
      trigger: ["change", "blur"],
      type: "string",
    },
  ],
};
