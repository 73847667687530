import { store } from "@/store";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import axios from "axios";
import moment from "moment";
import { createVNode } from "vue";
import { api } from "../config/config";
import { commonService, companyService } from "../services";
import { constants } from "./constants";

export const comman = {
  access(slug) {
    if (store.getters["auth/isAdmin"]) {
      return true;
    } else {
      if (store.getters["auth/profileStatus"] == 1) {
        if (store.getters["auth/slugs"].indexOf(slug) != -1) {
          return true;
        }
      }
    }
    return false;
  },
  hasRole(roles) {
    roles = Array.isArray(roles) ? roles : [roles];
    return roles.includes(store.getters["auth/user"].role_id);
  },
  canAccess(access) {
    let employee = store.getters["auth/employee"];
    return employee.can_access ? employee.can_access.includes(access) : false;
  },
  employee_type(value) {
    let name = "";
    const employee_types = constants.employee_types;
    if (employee_types) {
      employee_types.find((element) => {
        if (value == element.value) {
          name = element.text;
        }
      });
    }
    return name;
  },
  company_name(id, all_companies = []) {
    let name = "-";
    all_companies.find((element) => {
      if (id == element.id) {
        name = element.name;
      }
    });
    return name;
  },
  async get_companies() {
    return await companyService.store(constants.getCompaniesUrl).then((res) => {
      if (res.success) {
        return res.data;
      }
    });
  },
  async get_certification_types() {
    return await companyService
      .store(constants.getCertificationTypesUrl)
      .then((res) => {
        if (res.success) {
          return res.data;
        }
      });
  },
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  },
  get_employee_type(value, type) {
    let res = "";
    constants.employee_types.forEach((v) => {
      if (v.value == value) {
        res = v[type];
      }
    });
    return res;
  },
  arr_diff(a1, a2) {
    var a = [],
      diff = [];
    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }
    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }
    for (var k in a) {
      diff.push(k);
    }
    return diff;
  },
  priceFilter(value) {
    return `$${value}`;
  },

  disabledFutureDate(current) {
    return current && current > moment().endOf("day");
  },

  disabledPastDate(current) {
    return current && current < moment().startOf("day");
  },

  momentTz() {
    return moment().tz(constants.timezone);
  },

  getDistanceInMiles(value) {
    return parseFloat(value / 1609.344).toFixed(2);
  },

  // getExpenseAmount(miles) {
  //   if (miles > 50) {
  //     return parseFloat(miles * 0.575).toFixed(2);
  //   }
  //   return 0;
  // },

  getTripExpenseAmount(miles, tripType) {
    if (miles > (50 * tripType)) {
      return parseFloat(miles * 0.70).toFixed(2);
    }
    return 0;
  },

  withCurrency(val) {
    return `${constants.currencySymbol}${Number(val).toFixed(2)}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  },

  currencyformatter(val) {
    return `${constants.currencySymbol}${val}`.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
  },

  currencyParser(val) {
    return val.replace(/\$\s?|(,*)/g, "");
  },

  percentageFormatter(val) {
    return `${val}%`;
  },

  percentageParser(val) {
    return val.replace("%", "");
  },

  getValueFromObject(object, key, formObject, returnKey = "text") {
    if (key in object) {
      let singleObject = Object.values(formObject).find((v) => {
        if (v.value == object[key]) {
          return v;
        }
      });
      if (singleObject) {
        return singleObject[returnKey];
      }
    }
    return "-";
  },

  getCompanies(val = {}) {
    return axios
      .post(api.host + constants.getCompaniesUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  getRoutes(val = {}) {
    return axios
      .post(api.host + constants.getAccessRoutesUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.response.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  getRoles(val = {}) {
    return axios
      .post(api.host + constants.getRolesUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.response.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  getEmployeePositions(val = {}) {
    return axios
      .post(api.host + constants.getEmployeePositionsUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.response.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  getCaseServices(val = {}) {
    return axios
      .post(api.host + constants.getCaseServicesUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.response.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  // val = { hospital_id: 1 }
  getHospitalWiseCaseServices(val) {
    return axios
      .post(api.host + constants.getHospitalWiseCaseServicesUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.response.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  getHospitals(val = {}) {
    return axios
      .post(api.host + constants.getHospitalsUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  getEmployees(val = {}) {
    return axios
      .post(api.host + constants.getEmployeesUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  getHospitalTemplate(val = {}) {
    return axios
      .post(api.host + constants.hospitalTemplatesShowUrl, val)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        var err = error.data.message || "Something went wrong";
        message.error(err);
        return Promise.reject(err);
      });
  },

  downloadItem({ url, name }) {
    commonService.downloadFile(constants.downloadFile, { url }, name);
  },

  caseReportLogStore({ id, action }) {
    commonService.store(constants.caseReportStoreLogUrl, { id, action });
  },

  getTextAreaValueAsHtml(val) {
    return val?.replaceAll("\r\n", "<br />") ?? "N/A";
  },

  secondsToHms(seconds, isShort = false) {
    let isMinus = "";
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      isMinus = "-";
    }
    seconds = Number(seconds);
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor((seconds % 3600) % 60);

    let hDisplay =
      h > 0 ? h + (isShort ? "h" : h == 1 ? " hour" : " hours") : "";
    let mDisplay =
      m > 0 ? m + (isShort ? "m" : m == 1 ? " minute" : " minutes") : "";
    let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

    let array = [hDisplay, mDisplay];
    if (!isShort) {
      array.push(sDisplay);
    }

    return `${isMinus} ${array.reduce((pre, curr) => {
      if (curr.length > 0) {
        if (pre.length > 0) {
          pre += `, ${curr}`;
        } else {
          pre = curr;
        }
      }
      return pre;
    }, "")}`;
  },

  secondsToHms1(seconds) {
    let isMinus = "";
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      isMinus = "-";
    }
    seconds = Number(seconds);
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor((seconds % 3600) % 60);

    h = h > 9 ? h : `0${h}`;
    m = m > 9 ? m : `0${m}`;
    s = s > 9 ? s : `0${s}`;
    return `${h}:${m}:${s}`;
  },

  isCanReceiveOrgansHospital(hospital) {
    return hospital?.is_can_receive_organs == 1;
  },

  isDisposableOnlyCase(record) {
    return (
      record.is_disposable_only_case &&
      record.services.includes(constants.disposableOnlyCase)
    );
  },

  showNrpQiIndicatorStep(record, hospital) {
    return !this.isDisposableOnlyCase(record) &&
      constants.nrpQiIndicatorStep.reduce(
        (bool, curr) => bool || record.services.includes(curr),
        false
      ) && this.isCanReceiveOrgansHospital(hospital);
  },

  showNrpProcedureInfoStep(record, hospital) {
    return !this.isDisposableOnlyCase(record) &&
      constants.nrpPocedureInfoStep.reduce(
        (bool, curr) => bool || record.services.includes(curr),
        false
      ) && this.isCanReceiveOrgansHospital(hospital);
  },

  mrNumber(hospital) {
    return !this.isCanReceiveOrgansHospital(hospital) ? "MR Number" : "UNOS ID";
  },

  heartLungMachineLabel(hospital, select = true) {
    return !this.isCanReceiveOrgansHospital(hospital)
      ? select
        ? "Select heart lung machine used"
        : "Heart lung machine device"
      : "NRP circuit console";
  },

  heartLungMachineCleanLabel(hospital) {
    return !this.isCanReceiveOrgansHospital(hospital)
      ? "Heart lung machine cleaned after procedure?"
      : "NRP circuit console cleaned after procedure?";
  },

  heartLungMachineRemarkLabel(hospital) {
    return !this.isCanReceiveOrgansHospital(hospital)
      ? "Heart lung bypass machine remarks"
      : "NRP circuit console remarks";
  },

  getYearsListFrom(startingYear = 2021) {
    let years = [];
    for (let i = startingYear; i <= moment().format("YYYY"); i++) {
      years.push(i);
    }
    return years.sort((a, b) => b - a);
  },

  abbreviatedNumber(n, prefix = "$") {
    let isMinus = "";
    if (n < 0) {
      n = Math.abs(n);
      isMinus = "-";
    }
    if (n < 1e3) {
      return prefix + n;
    } else if (n >= 1e3 && n < 1e6) {
      return prefix + `${isMinus ? "-" : ""}` + (n / 1e3).toFixed(1) + "K";
    } else if (n >= 1e6 && n < 1e9) {
      return prefix + `${isMinus ? "-" : ""}` + (n / 1e6).toFixed(1) + "M";
    } else if (n >= 1e9 && n < 1e12) {
      return prefix + `${isMinus ? "-" : ""}` + (n / 1e9).toFixed(1) + "B";
    } else if (n >= 1e12) {
      return prefix + `${isMinus ? "-" : ""}` + (n / 1e12).toFixed(1) + "T";
    }
  },

  average(arr) {
    return arr.length > 0
      ? Number(
        parseFloat(arr.reduce((p, c) => p + c, 0) / arr.length).toFixed(2)
      )
      : 0;
  },

  getTrackingURL(partner, trackingNumber) {
    // Regular expressions to match carrier names
    const fedexRegex = /fed\s?ex\s?(freight|express)?/i;
    const upsRegex = /ups/i;
    const oldDominionRegex = /old\s?dominion/i;
    const dhlRegex = /dhl/i;

    // Extract tracking number from the trackingNo argument
    // const trackingNumber = trackingNo.match(/\d+/)?.[0];
    //
    // if (!trackingNumber) return false;

    // Match partner name using switch case
    switch (true) {
      case fedexRegex.test(partner):
        return `https://www.fedex.com/wtrk/track/?tracknumbers=${trackingNumber}`;
      case upsRegex.test(partner):
        return `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}`;
      case oldDominionRegex.test(partner):
        return `https://www.odfl.com/us/en/tools/trace-track-ltl-freight.html?proNumbers=${trackingNumber}`;
      case dhlRegex.test(partner):
        return `https://www.dhl.com/in-en/home/tracking.html?tracking-id=${trackingNumber}&submit=1`;
      default:
        return false; // or any other appropriate fallback value
    }
  },

  getDateRange(firstDate, lastDate) {
    if (
      moment(firstDate, "MM-DD-YYYY").isSame(
        moment(lastDate, "MM-DD-YYYY"),
        "day"
      )
    ) {
      return [lastDate];
    }
    let date = firstDate;
    const dates = [date];
    do {
      date = moment(date, "MM-DD-YYYY").add(1, "day");
      dates.push(date.format("MM-DD-YYYY"));
    } while (moment(date, "MM-DD-YYYY").isBefore(lastDate));
    return dates;
  },

  pastDateWarningModal(date) {
    if (date && date.isBefore()) {
      Modal.warning({
        title: () => "Warning!!",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => "Please check and confirm expiration date!",
      });
    }
  },

  isLessThen10Percent(price, newPrice) {
    price = parseFloat(price);
    newPrice = parseFloat(newPrice);
    return (price + (price * 0.1)) > newPrice;
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  markupText(text, identifier, htmltag) {
    let array = text.split(identifier);
    let previous = "";
    let previous_i;

    for (let i = 0; i < array.length; i++) {
      if (i % 2) {
        //
      } else if (i != 0) {
        previous_i = eval(i - 1);
        array[previous_i] = "<" + htmltag + ">" + previous + "</" + htmltag + ">";
      }
      previous = array[i];
    }

    let newtext = "";
    for (let i = 0; i < array.length; i++) {
      newtext += array[i];
    }

    return newtext;
  },

  getDataDiffDays(date) {
    const currentDate = moment();
    const daysOld = currentDate.diff(date, "days");
    return `${daysOld}`;
  }
};
