<template>
  <div>
    <a-layout-content>
      <a-page-header
        class="page-header"
        :title="title"
        style="margin-bottom: 24px; background-color: #fff"
        @back="() => $router.go(-1)"
      />

      <a-card :loading="cardLoading">
        <a-form
          name="employee_form"
          ref="employee_form"
          :model="model"
          :rules="rules"
          layout="vertical"
          @finish="onSubmit"
        >
          <a-row :gutter="[20, 0]">
            <a-col :sm="24" :md="8">
              <a-form-item
                ref="first_name"
                label="First Name"
                name="first_name"
                :validateStatus="validate('first_name', 'status')"
                :help="validate('first_name', 'msg')"
              >
                <a-input
                  v-model:value="model.first_name"
                  placeholder="Enter first name here"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="middle_name"
                label="Middle Name"
                name="middle_name"
              >
                <a-input
                  v-model:value="model.middle_name"
                  placeholder="Enter middle name here"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="last_name"
                label="Last Name"
                name="last_name"
                :validateStatus="validate('last_name', 'status')"
                :help="validate('last_name', 'msg')"
              >
                <a-input
                  v-model:value="model.last_name"
                  placeholder="Enter last name here"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="company_id"
                label="Company"
                name="company_id"
                :validateStatus="validate('company_id', 'status')"
                :help="validate('company_id', 'msg')"
              >
                <a-select
                  v-model:value="model.company_id"
                  placeholder="select company"
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="company in companies"
                    :key="company.id"
                    :label="company.name"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="employee_type"
                label="Profession Type"
                name="employee_type"
                :validateStatus="validate('employee_type', 'status')"
                :help="validate('employee_type', 'msg')"
              >
                <a-select
                  v-model:value="model.employee_type"
                  placeholder="select employee type"
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="type in $constants.employee_types"
                    :key="type.value"
                    :label="type.text"
                    :value="type.value"
                  >
                    {{ type.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="employment_type"
                label="Employment Type"
                name="employment_type"
                :validateStatus="validate('employment_type', 'status')"
                :help="validate('employment_type', 'msg')"
              >
                <a-select
                  v-model:value="model.employment_type"
                  placeholder="select employment type"
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="type in $constants.employment_types"
                    :key="type.value"
                    :label="type.text"
                    :value="type.value"
                  >
                    {{ type.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="gender"
                label="Gender"
                name="gender"
                :validateStatus="validate('gender', 'status')"
                :help="validate('gender', 'msg')"
              >
                <a-radio-group name="radioGroup" v-model:value="model.gender">
                  <a-radio
                    v-for="(v, i) in $constants.gender"
                    :key="`${i}Gender`"
                    :value="v.value"
                  >
                    {{ v.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col v-if="false" :sm="24" :md="8">
              <a-form-item
                ref="blood_group"
                label="Blood Group"
                name="blood_group"
                :validateStatus="validate('blood_group', 'status')"
                :help="validate('blood_group', 'msg')"
              >
                <a-select
                  v-model:value="model.blood_group"
                  placeholder="Select employee blood group"
                  optionFilterProp="label"
                >
                  <a-select-option
                    v-for="blood_group in $constants.blood_groups"
                    :key="blood_group"
                    :label="blood_group"
                    :value="blood_group"
                  >
                    {{ blood_group }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                v-if="model.employment_type == 1"
                ref="gusto_employee_code"
                label="Gusto Employee Code"
                name="gusto_employee_code"
                :validateStatus="validate('gusto_employee_code', 'status')"
                :help="validate('gusto_employee_code', 'msg')"
              >
                <a-input
                  v-model:value="model.gusto_employee_code"
                  placeholder="Enter here..."
                />
              </a-form-item>

              <a-form-item
                v-if="model.employment_type == 2"
                ref="dba"
                label="DBA (Doing Business As)"
                name="dba"
                :validateStatus="validate('dba', 'status')"
                :help="validate('dba', 'msg')"
              >
                <a-input
                  v-model:value="model.dba"
                  placeholder="Enter here..."
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="position_id"
                label="Position"
                name="position_id"
                :validateStatus="validate('position_id', 'status')"
                :help="validate('position_id', 'msg')"
              >
                <a-select
                  v-model:value="model.position_id"
                  placeholder="Select employee position"
                  optionFilterProp="label"
                  @change="handlePositionChangeEvent"
                >
                  <a-select-option
                    v-for="position in employeePositions"
                    :key="position.id"
                    :label="position.name"
                    :value="position.id"
                  >
                    {{ position.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <template v-if="model.id == 0">
              <a-col :sm="24" :md="12">
                <a-form-item
                  ref="email"
                  label="Email"
                  name="email"
                  :validateStatus="validate('email', 'status')"
                  :help="validate('email', 'msg')"
                >
                  <a-input
                    v-model:value="model.email"
                    placeholder="Enter email here"
                  />
                </a-form-item>
              </a-col>

              <a-col :sm="24" :md="12">
                <a-form-item
                  ref="email_confirmation"
                  label="Confirm Email"
                  name="email_confirmation"
                >
                  <a-input
                    v-model:value="model.email_confirmation"
                    placeholder="Enter email here"
                  />
                </a-form-item>
              </a-col>

              <a-col :sm="24" :md="12">
                <a-form-item
                  ref="social_security_no"
                  label="Social Security Number"
                  name="social_security_no"
                  :validateStatus="validate('social_security_no', 'status')"
                  :help="validate('social_security_no', 'msg')"
                >
                  <a-input
                    v-model:value="model.social_security_no"
                    v-maska="'###-##-####'"
                    placeholder="###-##-####"
                  />
                </a-form-item>
              </a-col>

              <a-col :sm="24" :md="12">
                <a-form-item
                  ref="social_security_no_confirmation"
                  label="Confirm Social Security Number"
                  name="social_security_no_confirmation"
                >
                  <a-input
                    v-model:value="model.social_security_no_confirmation"
                    v-maska="'###-##-####'"
                    placeholder="###-##-####"
                  />
                </a-form-item>
              </a-col>
            </template>

            <a-col v-if="isRoleAdmin" :sm="24" :md="6">
              <a-form-item
                ref="title"
                label="Management Title"
                name="title"
                :validateStatus="validate('title', 'status')"
                :help="validate('title', 'msg')"
              >
                <a-input
                  v-model:value="model.title"
                  placeholder="Enter management title here"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="isRoleAdmin ? 6 : 8">
              <a-form-item
                ref="birthdate"
                label="Date of Birth"
                name="birthdate"
                :validateStatus="validate('birthdate', 'status')"
                :help="validate('birthdate', 'msg')"
              >
                <a-date-picker
                  v-model:value="model.birthdate"
                  :disabled-date="$comman.disabledFutureDate"
                  :placeholder="$constants.datePickerPlaceholder"
                  :format="$constants.datepickerFormat"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="isRoleAdmin ? 6 : 8">
              <a-form-item
                ref="hire_date"
                label="Hire Date"
                name="hire_date"
                :validateStatus="validate('hire_date', 'status')"
                :help="validate('hire_date', 'msg')"
              >
                <a-date-picker
                  v-model:value="model.hire_date"
                  :placeholder="$constants.datePickerPlaceholder"
                  :format="$constants.datepickerFormat"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="isRoleAdmin ? 6 : 8">
              <a-form-item
                ref="contact_no"
                label="Contact Number"
                name="contact_no"
                :validateStatus="validate('contact_no', 'status')"
                :help="validate('contact_no', 'msg')"
              >
                <a-input
                  v-model:value="model.contact_no"
                  v-maska="$constants.contactNumberMask"
                  :placeholder="$constants.contactNumberPlaceholder"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="6">
              <a-form-item
                ref="street_address"
                label="Street Address"
                name="street_address"
                :validateStatus="validate('street_address', 'status')"
                :help="validate('street_address', 'msg')"
              >
                <a-input
                  v-model:value="model.street_address"
                  placeholder="Enter street address here..."
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="6">
              <a-form-item
                ref="city"
                label="City"
                name="city"
                :validateStatus="validate('city', 'status')"
                :help="validate('city', 'msg')"
              >
                <a-input
                  v-model:value="model.city"
                  placeholder="Enter city here..."
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="6">
              <a-form-item
                ref="state"
                label="State"
                name="state"
                :validateStatus="validate('state', 'status')"
                :help="validate('state', 'msg')"
              >
                <a-select
                  v-model:value="model.state"
                  placeholder="Select state here..."
                  optionFilterProp="label"
                  showSearch
                >
                  <a-select-option
                    v-for="state in $constants.states"
                    :key="state.name"
                    :label="state.name"
                    :value="state.name"
                  >
                    {{ state.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="6">
              <a-form-item
                ref="zipcode"
                label="Zip Code"
                name="zipcode"
                :validateStatus="validate('zipcode', 'status')"
                :help="validate('zipcode', 'msg')"
              >
                <a-input
                  v-model:value="model.zipcode"
                  placeholder="Enter zip code here..."
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="12">
              <a-form-item
                ref="license_no"
                label="Driver's License Number"
                name="license_no"
                :validateStatus="validate('license_no', 'status')"
                :help="validate('license_no', 'msg')"
              >
                <a-input
                  v-model:value="model.license_no"
                  placeholder="Enter license no here"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="12">
              <a-form-item
                ref="license_state"
                label="Driver's License State"
                name="license_state"
                :validateStatus="validate('license_state', 'status')"
                :help="validate('license_state', 'msg')"
              >
                <a-select
                  v-model:value="model.license_state"
                  placeholder="Select license state here..."
                  optionFilterProp="label"
                  showSearch
                  allowClear
                >
                  <a-select-option
                    v-for="state in $constants.states"
                    :key="state.name"
                    :label="state.name"
                    :value="state.name"
                  >
                    {{ state.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="emergency_name"
                label="Emergency Contact Name"
                name="emergency_name"
                :validateStatus="validate('emergency_name', 'status')"
                :help="validate('emergency_name', 'msg')"
              >
                <a-input
                  v-model:value="model.emergency_name"
                  placeholder="Enter emergency contact name here"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="emergency_contact"
                label="Emergency Contact Number"
                name="emergency_contact"
                :validateStatus="validate('emergency_contact', 'status')"
                :help="validate('emergency_contact', 'msg')"
              >
                <a-input
                  v-model:value="model.emergency_contact"
                  v-maska="$constants.contactNumberMask"
                  :placeholder="$constants.contactNumberPlaceholder"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="emergency_relation"
                label="Emergency Contact Relation"
                name="emergency_relation"
                :validateStatus="validate('emergency_relation', 'status')"
                :help="validate('emergency_relation', 'msg')"
              >
                <a-input
                  v-model:value="model.emergency_relation"
                  placeholder="Enter emergency contact relation here"
                />
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="is_manager"
                label=""
                name="is_manager"
                :validateStatus="validate('is_manager', 'status')"
                :help="validate('is_manager', 'msg')"
              >
                <a-checkbox v-model:checked="model.is_manager">
                  Is this employee a manager?
                </a-checkbox>
              </a-form-item>
            </a-col>

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="is_sales_person"
                label=""
                name="is_sales_person"
                :validateStatus="validate('is_sales_person', 'status')"
                :help="validate('is_sales_person', 'msg')"
              >
                <a-checkbox v-model:checked="model.is_sales_person">
                  Is this employee a sales person?
                </a-checkbox>
              </a-form-item>
            </a-col>

            <!-- <a-col :sm="24" :md="8">
              <a-form-item
                ref="is_associated_with_monthly_billing"
                label=""
                name="is_associated_with_monthly_billing"
                :validateStatus="
                  validate('is_associated_with_monthly_billing', 'status')
                "
                :help="validate('is_associated_with_monthly_billing', 'msg')"
              >
                <a-checkbox
                  v-model:checked="model.is_associated_with_monthly_billing"
                >
                  Is associated with monthly billing?
                </a-checkbox>
              </a-form-item>
            </a-col> -->

            <a-col :sm="24" :md="8">
              <a-form-item
                ref="pto_hours_per_day"
                label="PTO Hours per Day"
                name="pto_hours_per_day"
                :validateStatus="validate('pto_hours_per_day', 'status')"
                :help="validate('pto_hours_per_day', 'msg')"
              >
                <a-input-number
                  v-model:value="model.pto_hours_per_day"
                  placeholder="Enter here..."
                  type="number"
                />
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item
                ref="email_signature_html_code"
                label="Email Signature HTML Code"
                name="email_signature_html_code"
              >
                <a-textarea
                  v-model:value="model.email_signature_html_code"
                  placeholder="Enter hear..."
                />
              </a-form-item>
            </a-col>
            <a-col :sm="24" :md="8">
              <a-form-item ref="signature" label="Signature" name="signature">
                <a-upload
                  v-model:file-list="model.signature"
                  :before-upload="beforeUpload"
                  @change="
                    ({ file }) =>
                      onFileAdd(file, 'image/jpeg,image/jpg,image/png')
                  "
                  accept="image/jpeg,image/jpg,image/png"
                  :remove="() => (model.signature = [])"
                >
                  <div>
                    <a-button>
                      <UploadOutlined />
                      Upload
                    </a-button>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>

            <!--            <a-col :span="24">
              <a-form-item
                ref="can_access"
                label="More access"
                name="can_access"
              >
                <a-checkbox-group v-model:value="model.can_access">
                  <a-checkbox value="admin-order">
                    Can Access Admin > Orders Page
                  </a-checkbox>

                  <a-checkbox value="admin-dispatched-orders">
                    Can Access Admin > Dispatched Orders Page
                  </a-checkbox>
                </a-checkbox-group>
              </a-form-item>
            </a-col>-->
          </a-row>

          <a-space direction="vertical" style="width: 100%">
            <a-alert
              v-if="generateOfferLetter"
              message="While updating an employee's position, a candidate record is created for generating an offer letter."
              type="warning"
              close-text="Cancel"
              @close="generateOfferLetter = false"
            />

            <a-button
              type="primary"
              html-type="submit"
              :loading="submitBtnLoader"
            >
              {{ submitBtnText }}
            </a-button>
          </a-space>
        </a-form>
      </a-card>
    </a-layout-content>
  </div>
</template>
<script>
import {
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import moment from "moment";
import { createVNode } from "vue";
import backButton from "../../components/backButton.vue";
import { employeeCreateFormRules } from "../../helper/formRules";
import formMixins from "../../mixins/formMixins";
import { commonService } from "../../services/common.service.js";

export default {
  components: {
    backButton,
    UploadOutlined,
  },
  data() {
    return {
      cardLoading: false,
      submitBtnLoader: false,
      companies: {},
      roles: {},
      employeePositions: [],
      rules: employeeCreateFormRules,
      model: {
        id: this.$route.params.id ?? 0,
        user_id: 0,
        first_name: null,
        middle_name: null,
        last_name: null,
        company_id: null,
        employee_type: null,
        employment_type: 2,
        position_id: undefined,
        gender: null,
        gusto_employee_code: null,
        dba: null,
        blood_group: null,
        email: null,
        email_confirmation: null,
        contact_no: null,
        birthdate: null,
        hire_date: null,
        title: null,
        social_security_no: null,
        social_security_no_confirmation: null,
        street_address: null,
        city: null,
        state: null,
        zipcode: null,
        license_no: null,
        license_state: null,
        emergency_name: null,
        emergency_contact: null,
        emergency_relation: null,
        pto_hours_per_day: null,
        is_manager: false,
        is_sales_person: false,
        // is_associated_with_monthly_billing: false,
        // can_access: [],
        email_signature_html_code: null,
        signature: [],
      },
      record: null,
      generateOfferLetter: false,
    };
  },

  created() {
    this.cardLoading = true;
    Promise.all([
      this.$comman.getCompanies(),
      this.$comman.getRoles(),
      this.getEmployee(),
      this.$comman.getEmployeePositions(),
    ]).then((res) => {
      this.cardLoading = false;

      this.companies = res[0];
      this.roles = res[1];
      this.employeePositions = res[3];

      if (this.model.id != 0) {
        let employee = res[2].employee;
        this.record = employee;
        this.model = {
          id: employee.id,
          user_id: employee.user?.id ?? 0,
          first_name: employee.first_name,
          middle_name: employee.middle_name,
          last_name: employee.last_name,
          company_id: employee.company_id,
          employee_type: employee.employee_type,
          employment_type: employee.employment_type,
          position_id: employee.position_id,
          gender: employee.gender,
          gusto_employee_code: employee.gusto_employee_code,
          dba: employee.dba,
          blood_group: employee.blood_group,
          contact_no: employee.contact_no,
          birthdate:
            employee.birthdate != null ? moment(employee.birthdate) : null,
          hire_date:
            employee.hire_date != null ? moment(employee.hire_date) : null,
          title: employee.title ?? null,
          street_address: employee.street_address,
          city: employee.city,
          state: employee.state,
          zipcode: employee.zipcode,
          license_no: employee.license_no,
          license_state: employee.license_state,
          emergency_name: employee.emergency_name,
          emergency_contact: employee.emergency_contact,
          emergency_relation: employee.emergency_relation,
          is_manager: employee.is_manager ? true : false,
          is_sales_person: employee.is_sales_person ? true : false,
          is_associated_with_monthly_billing:
            !!employee.is_associated_with_monthly_billing,
          pto_hours_per_day: employee.pto_hours_per_day,
          // can_access: employee.can_access ?? [],
          email_signature_html_code: employee.email_signature_html_code,
          signature: employee.signature
            ? [
                {
                  id: employee.id,
                  uid: employee.signature.split("/")[
                    employee.signature.split("/").length - 1
                  ],
                  name: employee.signature.split("/")[
                    employee.signature.split("/").length - 1
                  ],
                  url: employee.signature_file_path,
                },
              ]
            : [],
        };
      }
    });
  },

  mixins: [formMixins],

  computed: {
    title() {
      if (this.model.id == 0) {
        return "Create New Employee";
      }
      return "Edit Employee";
    },

    submitBtnText() {
      if (this.model.id == 0) {
        return "Create";
      }
      return "Update";
    },

    isRoleAdmin() {
      return this.record?.user?.role_id == 1;
    },
  },

  methods: {
    getEmployee() {
      if (this.model.id != 0) {
        return commonService
          .get(this.$constants.employeeShowUrl, { id: this.model.id })
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            this.$message.error(err || "Something went wrong.");
            this.$router.push({ name: "employee" });
            return Promise.reject();
          });
      } else {
        return Promise.resolve();
      }
    },

    onSubmit() {
      let formData = new FormData();
      if (this.generateOfferLetter) {
        formData.append("generate_offer_letter", true);
      }
      Object.keys(this.model).forEach((key) => {
        if (key === "signature") {
          if (this.model[key].length > 0 && this.model[key][0].id) {
            formData.append("old_signature", true);
          } else {
            formData.append("signature", this.model[key][0]);
          }
        } else if (key == "gusto_employee_code") {
          if (this.model.employment_type == 1) {
            formData.append(key, this.model[key] ?? "");
          }
        } else if (key == "dba") {
          if (this.model.employment_type == 2) {
            formData.append(key, this.model[key] ?? "");
          }
        } else if (["birthdate", "hire_date"].includes(key)) {
          if (this.model[key]) {
            formData.append(key, this.$customDate.ymd(this.model[key]));
          }
        } else if ([true, false].includes(this.model[key])) {
          formData.append(key, this.model[key] == true ? 1 : 0);
        } else if (this.model[key] == null) {
          //
        } else {
          formData.append(key, this.model[key] ?? "");
        }
      });

      this.submitBtnLoader = true;
      commonService
        .store(this.$constants.employeeStoreUrl, formData)
        .then((res) => {
          if (res.success) {
            this.submitBtnLoader = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          if (this.model.id) {
            this.$router.push({
              name: "show-employee",
              params: { id: this.model.id },
            });
          } else {
            this.$router.push({ name: "employee" });
          }
        })
        .catch((err) => {
          this.submitBtnLoader = false;
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    beforeUpload(file) {
      const items = this.model.signature;
      items.forEach(() => items.pop());
      items.push(file);
      return false;
    },

    onFileAdd(file, types) {
      const acceptedTypes = types.split(",");

      if (!acceptedTypes.includes(file.type)) {
        this.$message.error(`File type of ${file.type} is not supported.`);
        this.model.signature = [];
        return;
      }

      if (file.size / 1024 / 1024 > 5) {
        this.$message.error(`File size of ${file.name} is too large. Max: 5MB`);
        this.model.signature = [];
        return false;
      }
    },

    handlePositionChangeEvent() {
      let that = this;
      if (this.record && this.record?.position_id !== this.model.position_id) {
        if (!that.generateOfferLetter) {
          Modal.confirm({
            title: () => "Do you want to generate an offer letter?",
            icon: () => createVNode(ExclamationCircleOutlined),
            content: () =>
              "Once you click on Yes then new candidate entry will be created for this employee.",
            onOk() {
              that.generateOfferLetter = true;
            },
            onCancel() {
              that.generateOfferLetter = false;
            },
            okText: () => "Yes",
          });
        }
      } else {
        that.generateOfferLetter = false;
      }
    },
  },
};
</script>
