<template>
  <a-card title="Contract" size="small" :bordered="false">
    <template #extra>
      <a-button type="primary" @click="addNewContract">
        <a-space size="small">
          <i class="ti ti-plus ti-lg"></i>
          Add New
        </a-space>
      </a-button>
    </template>

    <a-table
      size="small"
      :columns="columns"
      :data-source="contracts"
      :rowKey="(record) => record.id"
    >
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined /></template>
            Search
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
      </template>

      <template #filterIcon="filtered">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #attachment="{ record }">
        <template v-if="record.attachments.length > 0">
          <template v-for="attach in record.attachments" :key="attach.id">
            <a :href="attach.full_file_path" target="_blank">
              {{ attach.file_name }}
            </a>
            <br />
          </template>
        </template>
        <template v-else>N/A</template>
      </template>

      <template #actions="{ record }">
        <a-tooltip title="Edit">
          <a-button
            type="link"
            size="small"
            class="edit-color pl-sm-0"
            @click="editContract(record)"
          >
            <i class="ti ti-pencil ti-lg"></i>
          </a-button>
        </a-tooltip>

        <a-popconfirm
          title="Are you sure you want to delete this hospital contract?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteHospitalContract(record.id)"
        >
          <a-tooltip title="Delete">
            <a-button type="link" size="small" class="danger-color">
              <i class="ti ti-trash ti-lg"></i>
            </a-button>
          </a-tooltip>
        </a-popconfirm>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { mapActions } from "vuex";
import { commonService } from "../../../services";

export default {
  name: "hospital-contracts",

  props: {
    hospital: {
      required: true,
    },
  },

  components: { SearchOutlined },

  data() {
    return {
      loading: true,
      contracts: [],
      columns: [
        {
          title: "Title",
          dataIndex: "title",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: [""],
          onFilter: (value, record) =>
            value
              ? record.title
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              : record,
        },
        {
          title: "Expiration Date",
          dataIndex: "expiry_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Contract Increase Date",
          dataIndex: "contract_increase_date",
          customRender: ({ text }) => this.$customDate.mdy(text),
        },
        {
          title: "Contract Increase Percentage",
          dataIndex: "contract_increase_percentage",
          customRender: ({ record, text }) =>
            record.is_annual_escalator ? `${text}%` : "-",
        },
        {
          title: "Attachment",
          dataIndex: "attachment",
          slots: {
            customRender: "attachment",
          },
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          customRender: ({ record }) => record.employee?.full_name ?? "N/A",
        },
        {
          title: "Actions",
          dataIndex: "actions",
          slots: {
            customRender: "actions",
          },
        },
      ],
    };
  },

  async mounted() {
    this.getHospitalContract();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    getHospitalContract() {
      this.loading = true;
      return commonService
        .get(this.$constants.hospitalContractsUrl, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.contracts = res.data;
          this.loading = false;
        });
    },

    addNewContract() {
      this.open({
        title: "Add new hospital contract",
        path: "hospital.drawerForm.storeContract",
        callback: this.getHospitalContract,
        record: { hospital_id: this.hospital.id },
      });
    },

    editContract(record) {
      this.open({
        title: "Edit hospital contract",
        path: "hospital.drawerForm.storeContract",
        callback: this.getHospitalContract,
        record,
      });
    },

    deleteHospitalContract(id) {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalContractDeleteUrl, { id })
        .then((res) => {
          if (res.success) {
            this.getHospitalContract();
          }
        });
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
    },

    handleReset(clearFilters) {
      clearFilters();
    },
  },
};
</script>

<style scoped>
.product-prices div.ant-typography,
.product-prices .ant-typography p {
  margin-bottom: 0;
}
</style>
