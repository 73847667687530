<template>
  <a-layout-content>
    <page-header>
      <template #title> Candidates </template>

      <template #buttons>
        <a-button type="primary" @click="addNewCandidate">
          <a-space>
            <i class="ti ti-plus ti-lg"></i> Add New Candidate
          </a-space>
        </a-button>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #employment_type="{ record }">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'employment_type',
                $constants.employment_types,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "employment_type",
                $constants.employment_types
              )
            }}
          </a-tag>
        </template>

        <template #employee_type="{ record }">
          <a-tag
            :color="$comman.get_employee_type(record.employee_type, 'color')"
          >
            {{ $comman.get_employee_type(record.employee_type, "text") }}
          </a-tag>
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <a-tooltip v-if="[1, 2, 3, 5].includes(record.status)" title="Edit">
              <a-button type="link" size="small" @click="editCandidate(record)">
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-tooltip
              v-if="[1, 2, 5, 7].includes(record.status)"
              :title="`${
                record.employee_position_id != null ? 'Update' : 'Create'
              } Offer Letter`"
            >
              <router-link
                :to="{
                  name: 'candidate.create-offer-letter',
                  params: { id: record.id },
                }"
              >
                <a-button type="link" size="small">
                  <i class="ti ti-file-certificate ti-lg"></i>
                </a-button>
              </router-link>
            </a-tooltip>

            <a-tooltip
              v-if="record.status == 1 && record.employee_position_id != null"
              title="Send To Approval"
            >
              <a-button
                :loading="record.isSendingToApproval"
                type="link"
                size="small"
                @click="sendToApproval(record)"
              >
                <i class="ti ti-send ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-tooltip
              v-if="canSendOfferLetter(record)"
              :title="`${
                record.status == 7 ? 'Approve & ' : ''
              }Send Offer Letter`"
            >
              <a-button
                :loading="record.isMailSending"
                type="link"
                size="small"
                @click="sendOfferLetter(record)"
              >
                <i class="ti ti-mail ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-tooltip v-if="record.status == 2" title="Mail Logs">
              <a-button
                type="link"
                size="small"
                @click="showOfferMailLog(record)"
              >
                <i class="ti ti-history ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-tooltip v-if="record.status != 1" title="Offer Letter Preview">
              <a-button
                :loading="record.previewLoader"
                type="link"
                size="small"
                @click="offerLetterPreview(record)"
              >
                <i class="ti ti-file-text ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-tooltip v-if="record.status == 3" title="Sign Offer Letter">
              <router-link
                :to="{
                  name: 'candidate.sign-offer-letter',
                  params: { id: record.id },
                }"
              >
                <a-button type="link" size="small">
                  <i class="ti ti-signature ti-lg"></i>
                </a-button>
              </router-link>
            </a-tooltip>

            <a-popconfirm
              v-if="record.status == 1"
              title="Are you sure you want to delete this candidate?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="deleteCandidate(record)"
            >
              <a-tooltip title="Delete">
                <a-button type="link" size="small" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>

            <a-popover
              v-if="record.status === 6 && record.decline_remark"
              title="Decline Remarks"
              :content="record.decline_remark"
              overlayClassName="overlay-popover-class"
              placement="topRight"
            >
              <a-button danger type="link" size="small">
                <ExclamationCircleOutlined />
              </a-button>
            </a-popover>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.candidatesUrl,
      columns: [
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          width: 50,
          customRender: ({ index }) => index + 1,
        },
        {
          title: "Name",
          dataIndex: "full_name",
          key: "full_name",
          customRender: ({ text }) => text ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
          customRender: ({ text }) => text ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Contact No",
          dataIndex: "contact_no",
          key: "contact_no",
          customRender: ({ text }) => text ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
        },
        {
          title: "Employment Type",
          dataIndex: "employment_type",
          key: "employment_type",
          filters: this.$constants.employment_types,
          filterMultiple: false,
          slots: {
            customRender: "employment_type",
          },
        },
        {
          title: "Profession Type",
          dataIndex: "employee_type",
          key: "employee_type",
          filters: this.$constants.employee_types,
          filterMultiple: false,
          slots: {
            customRender: "employee_type",
          },
        },
        {
          title: "Hire Type",
          dataIndex: "hire_type",
          key: "hire_type",
          customRender: ({ record }) =>
            this.$comman.getValueFromObject(
              record,
              "hire_type",
              this.$constants.candidate_hire_types
            ),
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record?.creator?.full_name ?? "",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.candidateStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),

      companies: [],
    };
  },

  components: { ExclamationCircleOutlined },

  async mounted() {
    this.companies = await this.$comman.getCompanies();

    console.log(this.employee);
  },

  computed: {
    ...mapGetters("auth", ["employee"]),
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    canSendOfferLetter(rec) {
      return (
        rec.status == 2 ||
        (rec.status == 7 && [1, 45].includes(this.employee.id))
      );
    },

    addNewCandidate() {
      this.open({
        title: "Add New Candidate",
        path: "admin.candidate.store",
        callback: this.refresh,
        extra: { companies: this.companies },
      });
    },

    showOfferMailLog(record) {
      this.open({
        title: "Mail Logs",
        path: "admin.candidate.offer_mail_logs",
        callback: this.refresh,
        record,
      });
    },

    editCandidate(record) {
      this.open({
        title: "Edit Candidate",
        path: "admin.candidate.store",
        callback: this.refresh,
        record,
        extra: { companies: this.companies },
      });
    },

    sendToApproval(record) {
      record.isSendingToApproval = true;
      commonService
        .store(this.$constants.candidateSendToApprovalUrl, {
          id: record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally((err) => {
          delete record.isSendingToApproval;
          this.refresh();
        });
    },

    sendOfferLetter(record) {
      record.isMailSending = true;
      commonService
        .store(this.$constants.candidateSendOfferLetterUrl, {
          id: record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally((err) => {
          delete record.isMailSending;
          if (record.status == 7) {
            this.refresh();
          }
        });
    },

    offerLetterPreview(record) {
      record.previewLoader = true;
      commonService
        .renderFile(
          this.$constants.candidateOfferLetterPreviewUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          delete record.previewLoader;
        });
    },

    deleteCandidate(record) {
      commonService
        .store(this.$constants.candidateDeleteUrl, {
          id: record.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .finally((err) => {
          this.refresh();
        });
    },
  },
};
</script>
