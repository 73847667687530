<template>
  <a-layout-content>
    <a-row :gutter="[10, 10]">
      <a-col :span="24">
        <page-header>
          <template #title> Accounts Receivable </template>
          <template #buttons>
            <a-button
              type="primary"
              ghost
              @click="fetchData(true)"
              :loading="datatableLoading"
            >
              <a-space>
                <i class="ti ti-refresh ti-1x"></i>
                QBO Sync
              </a-space>
            </a-button>
          </template>
        </page-header>
      </a-col>
    </a-row>

    <a-row :gutter="[10, 10]">
      <a-col
        v-for="(widget, index) in widgets"
        :xs="24"
        :lg="4"
        :key="`widgets_${index}`"
      >
        <a-card
          :style="widgetStyle(widget)"
          size="small"
          :loading="datatableLoading"
          hoverable
          @click="handleFilterClick(widget.filter)"
        >
          <a-statistic :value="$comman.abbreviatedNumber(widget.value)">
            <template #title> {{ widget.title }} </template>
          </a-statistic>
        </a-card>
      </a-col>

      <a-col :span="24">
        <a-card size="small">
          <template #customRender="item">
            {{ item.text }} ({{ item.counts }})
          </template>

          <div id="tableContainer" ref="myTable">
            <a-table
              :rowKey="(record, index) => record.id"
              :dataSource="groupedReceivables"
              :expandedRowKeys="expandedRows"
              :columns="columns"
              :loading="datatableLoading"
              size="small"
              :pagination="false"
            >
              <template #filterDropdown="filterDropdown">
                <xFilterInputSearchDropdown
                  :filterDropdown="filterDropdown"
                  @handleSearch="handleDatatableSearch"
                  @handleReset="handleDatatableReset"
                />
              </template>

              <template #filterIcon="filterIcon">
                <xFilterIcon :filterIcon="filterIcon" />
              </template>
              <template #due_invoice="{ record }">
                <span>{{ record.items.length }}</span>
              </template>
              <template #action="{ record }">
                <a-tooltip title="Send Mail">
                  <a-button
                    type="text"
                    @click="showMailFormModel(record)"
                    :disabled="!isMailButtonEnabled(record)"
                  >
                    <a-space>
                      <i class="ti ti-mail ti-lg"></i>
                    </a-space>
                  </a-button>
                </a-tooltip>
              </template>
              <template #expandIcon="{ expanded, onExpand, record }">
                <a-button
                  size="small"
                  type="text"
                  @click="() => handleExpand(record, onExpand, expanded)"
                >
                  <i v-if="expanded" class="ti ti-chevron-down"></i>
                  <i v-else class="ti ti-chevron-right"></i>
                </a-button>
              </template>

              <template #expandedRowRender="{ record }">
                <div class="extended-row">
                  <a-table
                    :row-selection="rowSelection"
                    :columns="innerColumns"
                    :data-source="record.items"
                    size="small"
                    :pagination="false"
                    :rowKey="(rec, index) => rec.id"
                    :scroll="{ x: true, y: 0 }"
                  >
                    <template #invoice_no="{ record }">
                      <a-space>
                        <span>{{ record.invoice_no }}</span>
                        <a-popover v-if="record.case_report">
                          <template #content>
                            Case #:
                            <router-link
                              :to="{
                                name: 'show-case-report',
                                params: { uuid: record.case_report.uuid },
                              }"
                              target="_blank"
                            >
                              {{ record.case_report.report_no }}
                            </router-link>
                          </template>
                          <a-button size="small" type="link">
                            <i class="ti ti-info-circle ti-lg"> </i>
                          </a-button>
                        </a-popover>
                      </a-space>
                    </template>

                    <template #invoice_po_number="{ record }">
                      <div class="customer">
                        <div class="name">
                          <span>{{
                            record.invoice_po_number === ""
                              ? "N/A"
                              : record.invoice_po_number
                          }}</span>
                        </div>
                        <div class="discussion">
                          <a-tooltip title="Edit">
                            <a-button
                              size="small"
                              type="link"
                              @click="updateInvoicePoNumber(record)"
                            >
                              <i class="ti ti-pencil ti-lg"></i>
                            </a-button>
                          </a-tooltip>
                        </div>
                      </div>
                    </template>
                    <template #action="{ record }">
                      <a-tooltip title="Download">
                        <a-button
                          :loading="renderingPdf[record.id] || false"
                          type="link"
                          @click="renderPdf(record)"
                          style="padding: 1px"
                        >
                          <i class="ti ti-download ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                      <a-tooltip title="Follow-up">
                        <a-badge
                          :count="record.followups?.length"
                          :offset="[-6, 0]"
                        >
                          <a-button
                            type="link"
                            size="small"
                            @click="addNotes(record)"
                          >
                            <i class="ti ti-notes ti-lg"></i>
                          </a-button>
                        </a-badge>
                      </a-tooltip>
                      <a-tooltip title="Email History">
                        <a-badge
                          :count="record.emails?.length"
                          :offset="[-6, 0]"
                        >
                          <a-button
                            type="link"
                            size="small"
                            @click="emailLogs(record)"
                          >
                            <i class="ti ti-history ti-lg"></i>
                          </a-button>
                        </a-badge>
                      </a-tooltip>
                    </template>
                  </a-table>
                </div>
              </template>
            </a-table>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-layout-content>
  <a-modal
    v-model:visible="showMailForm"
    width="1000px"
    :footer="null"
    :closable="false"
    :maskClosable="false"
    style="top: 20px"
  >
    <mailFormVue :record="mailFormRecord" @close="closeMailFormModel" />
  </a-modal>
</template>
<script setup>
import { message } from "ant-design-vue";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import xFilterIcon from "../../../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../../../components/table/filterInputSearchDropdown.vue";
import { comman } from "../../../helper/comman";
import { constants } from "../../../helper/constants";
import { date } from "../../../helper/date";
import { commonService } from "../../../services";
import mailFormVue from "./mailForm.vue";

const receivable = ref([]);
const selectedStatus = ref("all");
const expandedRows = ref([]);
const selectedRowKeys = ref([]);
const showMailForm = ref(false);
const mailFormRecord = ref(null);
const store = useStore();
const datatableLoading = ref(false);
const renderingPdf = ref({});
const widgets = reactive([
  {
    title: "All",
    value: 0,
    filter: "all",
  },
  {
    title: "Current",
    value: 0,
    filter: "Current",
  },
  {
    title: "1-30 Days",
    value: 0,
    filter: "1 - 30 days past due",
  },
  {
    title: "31-60 Days",
    value: 0,
    filter: "31 - 60 days past due",
  },
  {
    title: "61-90 Days",
    value: 0,
    filter: "61 - 90 days past due",
  },
  {
    title: "Over 90 Days",
    value: 0,
    filter: "91 or more days past due",
  },
  // {
  //   title: "Days Sales Outstanding",
  //   value: "63",
  //   span: 5,
  //   filter: "90+",
  // },
]);

const columns = [
  {
    title: "Customer",
    dataIndex: "customer_name",
    key: "customer_name",
    width: "35%",
    slots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "customer_name",
    },
    onFilter: (value, record) =>
      record.customer_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    sorter: (a, b) => (a.customer_name > b.customer_name ? 1 : -1),
    defaultSortOrder: "ascend",
  },
  {
    title: "Invoices",
    dataIndex: "due_invoice",
    width: "10%",
    slots: {
      customRender: "due_invoice",
    },
    sorter: (a, b) => (a.items.length > b.items.length ? 1 : -1),
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    customRender: ({ text }) => {
      return text ?? "-";
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    width: "5%",
    slots: {
      customRender: "action",
    },
  },
];

const innerColumns = [
  {
    title: "Invoice#",
    dataIndex: "invoice_no",
    key: "invoice_no",
    slots: {
      customRender: "invoice_no",
    },
  },
  {
    title: "Invoice Date",
    dataIndex: "date",
    key: "date",
    slots: { customRender: "date" },
    customRender: ({ text }) => date.mdy(text),
  },
  {
    title: "Due Date",
    dataIndex: "due_date",
    key: "due_date",
    slots: { customRender: "due_date" },
    customRender: ({ text }) => date.mdy(text),
  },
  {
    title: "P.O. Number",
    dataIndex: "invoice_po_number",
    key: "invoice_po_number",
    slots: { customRender: "invoice_po_number" },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    customRender: ({ record }) => {
      return `${comman.withCurrency(record.amount)}`;
    },
  },
  {
    title: "Open Balance",
    dataIndex: "open_balance",
    key: "open_balance",
    customRender: ({ record }) => {
      return `${comman.withCurrency(record.open_balance)}`;
    },
  },
  {
    title: "Due Since",
    key: "days_old",
    customRender: ({ record }) => {
      return `${comman.getDataDiffDays(record.due_date)} days`;
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    fixed: "right",
    width: 160,
    slots: { customRender: "action" },
  },
];

function fetchData(sync = false) {
  datatableLoading.value = true;
  commonService
    .store(constants.financeAccountReceivableDetails, sync ? { sync } : {})
    .then((res) => {
      if (res.success) {
        expandedRows.value = [];
        receivable.value = res.data;

        widgets = widgets.map((widget) => {
          if (widget.filter == "all") {
            widget.value = res.data.reduce(
              (pre, curr) => pre + curr.qbo_invoice_open_balance,
              0
            );
          } else {
            widget.value = res.data
              .filter((e) => e.qbo_invoice_status == widget.filter)
              .reduce((pre, curr) => pre + curr.qbo_invoice_open_balance, 0);
          }
          return widget;
        });
      } else {
        receivable.value = [];
      }
      datatableLoading.value = false;
      //
    })
    .catch((ee) => {
      console.log(ee);
    })
    .finally(() => {
      datatableLoading.value = false;
    });
}

const handleFilterClick = (filter) => {
  selectedStatus.value = filter;
};

const filteredReceivables = computed(() => {
  if (selectedStatus.value === "all") {
    return receivable.value;
  }
  return receivable.value.filter(
    (record) => record.qbo_invoice_status === selectedStatus.value
  );
});

const groupedReceivables = computed(() => {
  const grouped = {};

  filteredReceivables.value.forEach((invoice) => {
    const customerId = invoice.qbo_customer_id;

    if (!grouped[customerId]) {
      grouped[customerId] = {
        id: customerId,
        customer_name: invoice.qbo_customer_name,
        notes: invoice.hospital?.notes ?? "N/A",
        items: [],
      };
    }

    grouped[customerId].items.push({
      id: invoice.qbo_invoice_id,
      qbo_invoice_id: invoice.qbo_invoice_id,
      invoice_no: invoice.qbo_invoice_number,
      date: invoice.qbo_invoice_date,
      due_date: invoice.qbo_invoice_due_date,
      invoice_po_number: invoice.qbo_invoice_po_number,
      amount: invoice.qbo_invoice_amount,
      open_balance: invoice.qbo_invoice_open_balance,
      followups: invoice.followups,
      hospital: invoice.hospital,
      customer_name: invoice.qbo_customer_name,
      emails: invoice.emails,
      case_report: invoice.case_report,
    });
  });

  return Object.values(grouped);
});

const rowSelection = {
  selectedRowKeys: selectedRowKeys,
  onChange: (selectedKeys, selectedRows) => {
    selectedRowKeys.value = selectedKeys;
    mailFormRecord.value = selectedRows;
  },
};
const hasSelection = computed(() => selectedRowKeys.value.length > 0);

const handleExpand = (record, onExpand, expanded) => {
  if (expanded) {
    // expandedRows.value = expandedRows.value.filter((id) => id !== record.id);
    expandedRows.value = [];
    selectedRowKeys.value = [];
  } else {
    // expandedRows.value.push(record.id);
    expandedRows.value = [record.id];
    // selectedRowKeys.value = [];
  }
  onExpand(record);
};

function addNotes(record) {
  store.dispatch("drawer/open", {
    title: `Add Follow-up | #${record.invoice_no}`,
    path: "finance.receivableBills.drawer.notes",
    callback: (followup) => {
      record.followups = followup;

      receivable.value = receivable.value.map((r) => {
        if (r.qbo_invoice_id === record.qbo_invoice_id) {
          return { ...r, followups: followup };
        }
        return r;
      });
    },
    record,
  });
}
function emailLogs(record) {
  store.dispatch("drawer/open", {
    title: `Email History`,
    path: "finance.receivableBills.drawer.emailLog",
    callback: () => {},
    record,
  });
}

function updateInvoicePoNumber(record) {
  store.dispatch("drawer/open", {
    title: `Update QuickBooks Invoice P.O. Number`,
    path: "finance.receivableBills.drawer.updateInvoicePoNumber",
    callback: (payload) => {
      record.invoice_po_number = payload.qbo_invoice_po_number;

      receivable.value = receivable.value.map((r) => {
        if (r.qbo_invoice_id === record.qbo_invoice_id) {
          return {
            ...r,
            qbo_invoice_po_number: payload.qbo_invoice_po_number,
            followups: payload.followups,
          };
        }
        return r;
      });
    },
    record,
  });
}

const isMailButtonEnabled = (record) => {
  return hasSelection.value && expandedRows.value.includes(record.id);
};

function showMailFormModel() {
  showMailForm.value = true;
}

function closeMailFormModel(data = null) {
  if (data) {
    let invoiceIds = data.map((e) => e.qbo_invoice_id);
    receivable.value = receivable.value.map((e) => {
      if (invoiceIds.includes(e.qbo_invoice_id)) {
        let emails = data.filter(
          (email) => e.qbo_invoice_id == email.qbo_invoice_id
        );
        e.emails = emails;
      }
      return e;
    });
    selectedRowKeys.value = [];
  }
  showMailForm.value = false;
}

function renderPdf(record) {
  renderingPdf.value[record.id] = true;
  commonService
    .renderFile(
      constants.qboInvoicePdf,
      {
        qbo_invoice_id: record.qbo_invoice_id,
        qbo_invoice_number: record.invoice_no,
      },
      "application/pdf;base64"
    )
    .then((res) => {
      // message.success(res);
    })
    .catch((err) => {
      message.error(err);
    })
    .finally(() => {
      renderingPdf.value[record.id] = false;
    });
}

function handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
  confirm();
}
function handleDatatableReset({ clearFilters }) {
  clearFilters();
}

const widgetStyle = (widget) => {
  return selectedStatus.value === widget.filter
    ? {
        "box-shadow": "0 4px 8px rgba(0,0,0,.20)",
        border: "1px solid #bfbfbf",
        transform: "translateY(-4px)",
      }
    : {};
};

onMounted(() => {
  fetchData();
});
</script>

<style lang="less">
.extended-row {
  .ant-table-wrapper {
    margin: 10px 0 10px -30px;
  }
}

.ant-table-expanded-row > td:first-child {
  border-right: none !important;
}
.customer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .discussion {
    border-left: 1px solid #f0f0f0;
  }
}
</style>
