<template>
  <a-divider orientation="left" class="mt-sm-0"> CPB Info </a-divider>

  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item
        label="CPB Time"
        name="cpb_time"
        :rules="[
          {
            required: isRequired,
            type: 'integer',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input-number
          type="number"
          v-model:value="model.cpb_time"
          placeholder="Enter CPB Time (Minutes)"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item
        label="Cross-Clamp Time"
        name="cross_clamp_time"
        :rules="[
          {
            required: isRequired,
            type: 'integer',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input-number
          type="number"
          v-model:value="model.cross_clamp_time"
          placeholder="Enter Cross-Clamp Time (Minutes)"
          :min="0"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item
        label="Circulatory Arrest Time"
        name="circulatory_arrest_time"
        :rules="[
          {
            required: isRequired,
            type: 'integer',
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input-number
          type="number"
          v-model:value="model.circulatory_arrest_time"
          placeholder="Enter Circulatory Arrest Time (Minutes)"
          :min="0"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-divider orientation="left" class="mt-sm-0"> </a-divider>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item
        label="First Blood Glucose Level"
        name="first_blood_glucose_level"
        :rules="[
          {
            required: isRequired,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.first_blood_glucose_level"
          placeholder="Enter here..."
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item
        label="Last Blood Glucose Level"
        name="last_blood_glucose_level"
        :rules="[
          {
            required: isRequired,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          },
        ]"
      >
        <a-input
          v-model:value="model.last_blood_glucose_level"
          placeholder="Enter here..."
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item
        label="Highest Blood Glucose Level"
        name="highest_blood_glucose_level"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <!-- <template #extra>
          <b v-html="`Highest Intraoperative Glucose < 180 mg/dL`"></b>
          <br />
          Reported as Percent Compliance <br />
          Target > 70%
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.highest_blood_glucose_level"
          placeholder="mg/dL"
          :min="1"
          :step="1"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Anesthesia Volume Pre-Bypass"
        name="anesthesia_volumn_pre_bypass"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.anesthesia_volumn_pre_bypass"
          placeholder="mLs"
          :min="0"
          :step="1"
          @change="handleAnesthesiaVolumnPreBypassChangeEvent"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Net Prime Volume"
        name="net_prime_volume"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.net_prime_volume"
          placeholder="mLs"
          :min="1"
          :step="1"
          @change="handleNetPrimeVolumeChangeEvent"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Ultrafiltration on CPB"
        name="ultrafiltration_on_cpb"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.ultrafiltration_on_cpb"
          placeholder="mLs"
          :min="0"
          :step="1"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="MUF - Post Bypass"
        name="modified_ultrafiltration"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.modified_ultrafiltration"
          placeholder="mLs"
          :min="0"
          :step="1"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="ACT > 400 sec. prior to initiation of CPB"
        name="act_seconds_prior_to_initiation"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
        }"
      >
        <!-- <template #extra>
          <b>
            Activated Clotting Time (ACT) Prior to CPB Initiation will be > 400
            seconds
          </b>
          <br />
          Reported as Percent Compliance <br />
          Target > 90%
        </template> -->

        <a-select
          v-model:value="model.act_seconds_prior_to_initiation"
          placeholder="Select One..."
        >
          <a-select-option
            v-for="yesNo in $constants.yesNo"
            :key="yesNo.value"
            :label="yesNo.text"
            :value="yesNo.value"
          >
            {{ yesNo.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="ACT > 400 sec. while on CPB"
        name="act_seconds_while_on_cpb"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
        }"
      >
        <!-- <template #extra>
          <b>
            Activated Clotting Time (ACT) during CPB will be > 400 seconds
          </b>
          <br />
          Reported as Percent Compliance <br />
          Target > 90%
        </template> -->

        <a-select
          v-model:value="model.act_seconds_while_on_cpb"
          placeholder="Select One..."
        >
          <a-select-option
            v-for="yesNo in $constants.yesNo"
            :key="yesNo.value"
            :label="yesNo.text"
            :value="yesNo.value"
          >
            {{ yesNo.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Lowest ACT on CPB"
        name="lowest_act_on_cpb"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.lowest_act_on_cpb"
          placeholder="Seconds"
          :min="1"
          :step="1"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Post Induction Hematocrit"
        name="post_induction_hematocrit"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.post_induction_hematocrit"
          placeholder="%"
          :min="0.01"
          :step="0.01"
          @change="handlePostInductionHematocritChangeEvent"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="First CPB Hematocrit"
        name="first_cpb_hematocrit"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.first_cpb_hematocrit"
          placeholder="%"
          :min="0.01"
          :step="0.01"
          @change="handleFirstCPBHematocritChangeEvent"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Percent Decrease in Hematocrit"
        name="percent_decrease_in_hemtocrit"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
        }"
      >
        <!-- <template #extra>
          <b
            v-html="
              `Change in Hematocrit (from 1st in OR to 1st on CPB) < 8 percent`
            "
          >
          </b>
          <br />
          Reported as Percent Compliance <br />
          Target > 85%
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.percent_decrease_in_hemtocrit"
          placeholder="%"
          disabled
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Lowest CPB Hematocrit"
        name="lowest_cpb_hematocrit"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <!-- <template #extra>
          <b>Lowest HCT on CPB > 24%</b>
          <br />
          Reported as Percent Compliance <br />
          Target > 60%
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.lowest_cpb_hematocrit"
          placeholder="%"
          :min="0.01"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Last CPB Hematocrit"
        name="last_cpb_hematocrit"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.last_cpb_hematocrit"
          placeholder="%"
          :min="0.01"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Last In Room Hematocrit"
        name="last_in_room_hematocrit"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.last_in_room_hematocrit"
          placeholder="%"
          :min="0.01"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Lowest Arterial Pressure on CPB"
        name="lowest_arterian_pressure_on_cpb"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.lowest_arterian_pressure_on_cpb"
          placeholder="mmHg"
          :min="1"
          :step="1"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Lowest SvO2 on CPB"
        name="lowest_svo2_on_cpb"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <!-- <template #extra>
          <b>Lowest SvO2 during CPB > 60%</b> <br />
          Reported as Percent Compliance <br />
          Target > 70%
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.lowest_svo2_on_cpb"
          placeholder="%"
          :min="1"
          :step="1"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Lowest CPB Base Excess/Deficit"
        name="lowest_cpb_base_excess_deficit"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <!-- <template #extra>
          <b>Lowest Base Excess (BE) during CPB > -4</b> <br />
          Reported as Percent Compliance <br />
          Target > 90%
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.lowest_cpb_base_excess_deficit"
          placeholder="mEq/L"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="6">
      <a-form-item
        label="Lowest Hb"
        name="lowest_hb"
        :rules="{
          required: isRequired,
          pattern: new RegExp(/^\d+(\.\d{1,2})*$/),
          min: 0,
          message: 'This field must be valid input.',
        }"
      >
        <a-input-number type="number" v-model:value="model.lowest_hb" />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="6">
      <a-form-item
        label="Highest Venous Blood Temperature"
        name="highest_arterial_blood_temperature"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <!-- <template #extra>
          <b v-html="`Highest Venous Blood Temperature < 37C`"></b>
          <br />
          Reported as Percent Compliance <br />
          Target > 85%
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.highest_arterial_blood_temperature"
          placeholder="°C"
          :min="0.01"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="6">
      <a-form-item
        label="Lowest Core Body Temperature"
        name="lowest_core_body_temperature"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.lowest_core_body_temperature"
          placeholder="°C"
          :min="0.01"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="6">
      <a-form-item
        label="Minimum CI During CPB"
        name="minimum_cardiac_index_during_cpb"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'number',
          validator: isRequired
            ? $validation.notNullDecimal
            : $validation.decimal,
        }"
      >
        <!-- <template #extra>
          <b> Minimum Cardiac Index (CI) during CPB > 1.8 LPM/M2 BSA </b>
          <br />
          Reported as Percent Compliance <br />
          Target > 85%
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.minimum_cardiac_index_during_cpb"
          placeholder="Enter minimum cardiac index during cpb here"
          :min="0"
          :step="0.01"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="PRBC Transfusion during CPB (mLs)"
        name="prbc_transfusion_during_cpb"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <!-- <template #extra>
          <b>
            Packed Red Blood Cell (PRBC) Transfusion during CPB (includes prime)
          </b>
          <br />
          Reported as Percent Patients Receiving PRBC <br />
          <span v-html="`Target < 15%`"></span>
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.prbc_transfusion_during_cpb"
          placeholder="Enter packed red blood cell transfusion during cpb here"
          :min="0"
          :step="1"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Additional Crystalloid Volume during CPB (mLs)"
        name="additional_crystalloid_volume_during_cpb"
        :rules="{
          required: isRequired,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <!-- <template #extra>
          <b>
            Additional Crystalloid Volume during CPB (excluding prime and
            including cardioplegia volume)
          </b>
          <br />
          Reported as Percentage of Patients Receiving 2000 mL or more<br />
          <span v-html="`Target < 15%`"></span>
        </template> -->

        <a-input-number
          type="number"
          v-model:value="model.additional_crystalloid_volume_during_cpb"
          placeholder="Enter additional crystalloid volume during cpb here"
          :min="0"
          :step="1"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item name="estimated_blood_volume">
        <template #label>
          Estimated Blood Volume
          <a-popover>
            <template #content>
              Assuming 70 ml of blood volume per kilogram of body weight.
            </template>
            <a-button type="link" size="small">
              <i class="fa fa-info-circle"></i>
            </a-button>
          </a-popover>
        </template>
        <a-input-number
          type="number"
          v-model:value="model.estimated_blood_volume"
          placeholder="mL"
          @change="handleEstimatedBloodVolumeChangeEvent"
          disabled
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item name="predicted_cpb_hematocrit">
        <template #label>
          Predicted CPB Hematocrit
          <a-popover>
            <template #content>
              <b>HCTpost = (EBV * HCTpre)/(D + EBV)</b><br />
              The variables in the hemodilution calulation are specified as:
              <br />
              <b>HCTpost</b> = post dilutional hematocrit <br />
              <b>HCTpre</b> = hematocrit predilution (%) <br />
              <b>EBV</b> = Estimated Blood Volume (ml) <br />
              <b>D</b> = Dilutional volume of Fluid (ml) (pump prime +
              anesthesia fluid)
            </template>
            <a-button type="link" size="small">
              <i class="fa fa-info-circle"></i>
            </a-button>
          </a-popover>
        </template>
        <a-input-number
          type="number"
          v-model:value="model.predicted_cpb_hematocrit"
          placeholder="%"
          disabled
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8">
      <a-form-item name="diff_predicted_cpb_hct_vs_actual_cpb_hct">
        <template #label>
          Diff.(%) in Predicted CPB HCT vs Actual CPB HCT
          <a-popover>
            <template #content>
              Diff.(%) in Predicted CPB HCT vs Actual CPB HCT
            </template>
            <a-button type="link" size="small">
              <i class="fa fa-info-circle"></i>
            </a-button>
          </a-popover>
        </template>
        <a-input-number
          type="number"
          v-model:value="model.diff_predicted_cpb_hct_vs_actual_cpb_hct"
          placeholder="%"
          disabled
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-divider orientation="left">Blood Products Transfused</a-divider>
  <a-row :gutter="[20, 0]">
    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="PRBC"
        name="qi_prbc"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.qi_prbc"
          :min="0"
          :step="1"
          placeholder="Type the number of units transfused"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="FFP"
        name="qi_ffp"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.qi_ffp"
          :min="0"
          :step="1"
          placeholder="Type the number of units transfused"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Platelets"
        name="qi_platelets"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.qi_platelets"
          :min="0"
          :step="1"
          placeholder="Type the number of units transfused"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12" :lg="8" :xxl="6">
      <a-form-item
        label="Cryo"
        name="qi_cryo"
        :rules="{
          required: true,
          message: 'This field is required!',
          type: 'integer',
        }"
      >
        <a-input-number
          type="number"
          v-model:value="model.qi_cryo"
          :min="0"
          :step="1"
          placeholder="Type the number of units transfused"
        />
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "case-report-step-4",
  inheritAttrs: false,
  props: ["step", "model"],
  emits: ["validate"],

  data() {
    return {
      //
    };
  },

  mounted() {
    this.calculatePredictedCPBHematocrit();
    this.handlePostInductionHematocritChangeEvent();
    this.calculateDiffPredicatedAndActualCPBHCT();
    console.log(this.isRequired);
  },

  computed: {
    isRequired() {
      return (
        [7, 14].reduce(
          (bool, curr) => bool || this.model.services.includes(curr),
          false
        ) && !this.model.services.includes(8)
      );
    },
  },

  methods: {
    back() {
      this.$emit("back");
    },

    saveAndNext() {
      this.$emit("next");
    },

    handleAnesthesiaVolumnPreBypassChangeEvent() {
      this.calculatePredictedCPBHematocrit();
    },

    handleNetPrimeVolumeChangeEvent() {
      this.calculatePredictedCPBHematocrit();
    },

    handleEstimatedBloodVolumeChangeEvent() {
      this.calculatePredictedCPBHematocrit();
    },

    handlePostInductionHematocritChangeEvent() {
      this.calculatePercentDecreaseInHematocrit();
      this.calculatePredictedCPBHematocrit();
    },

    handleFirstCPBHematocritChangeEvent() {
      this.calculatePercentDecreaseInHematocrit();
      this.calculateDiffPredicatedAndActualCPBHCT();
    },

    calculatePercentDecreaseInHematocrit() {
      if (
        this.model.post_induction_hematocrit &&
        this.model.first_cpb_hematocrit
      ) {
        this.model.percent_decrease_in_hemtocrit = Number(
          Number(
            (this.model.post_induction_hematocrit -
              this.model.first_cpb_hematocrit) /
              this.model.post_induction_hematocrit
          ).toFixed(2) * 100
        );
      } else {
        this.model.percent_decrease_in_hemtocrit = "";
      }
    },

    calculatePredictedCPBHematocrit() {
      if (
        this.model.estimated_blood_volume &&
        this.model.post_induction_hematocrit &&
        this.model.anesthesia_volumn_pre_bypass &&
        this.model.net_prime_volume
      ) {
        this.model.predicted_cpb_hematocrit = Number(
          Number(
            (this.model.estimated_blood_volume *
              this.model.post_induction_hematocrit) /
              (this.model.anesthesia_volumn_pre_bypass +
                this.model.net_prime_volume +
                this.model.estimated_blood_volume)
          ).toFixed(2)
        );
      } else {
        this.model.predicted_cpb_hematocrit = "";
      }
    },

    calculateDiffPredicatedAndActualCPBHCT() {
      if (
        this.model.first_cpb_hematocrit &&
        this.model.predicted_cpb_hematocrit
      ) {
        this.model.diff_predicted_cpb_hct_vs_actual_cpb_hct = Number(
          Number(
            this.model.first_cpb_hematocrit -
              this.model.predicted_cpb_hematocrit
          ).toFixed(2)
        );
      } else {
        this.model.diff_predicted_cpb_hct_vs_actual_cpb_hct = "";
      }
    },
  },
};
</script>
