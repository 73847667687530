<template>
  <a-layout-content>
    <page-header>
      <template #title> Hospitals</template>

      <template #buttons>
        <a-space>
          <a-button :loading="downloading" @click="handleExportExcelEvent">
            <a-space><i class="ti ti-download ti-lg"></i> Excel</a-space>
          </a-button>

          <a-button type="primary" @click="handleCreateNewHospitalEvent">
            <a-space><i class="ti ti-plus ti-lg"></i> Add Hospital</a-space>
          </a-button>
        </a-space>
      </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #address="{ record }">
          {{ record.address_line_1 }}, {{ record.address_line_2 }}
        </template>

        <template #quickbooks="{ record }">
          <i
            v-if="record.quickbooks_class_id && record.quickbooks_customer_id"
            class="ti ti-check ti-lg"
            style="color: green"
          />
          <i v-else class="ti ti-x ti-lg" style="color: red" />
        </template>

        <template #action="{ record }">
          <template v-if="record.status == 1">
            <router-link
              :to="{ name: 'hospital-details', params: { id: record.id } }"
            >
              <a-tooltip title="View">
                <a-button type="link" size="small" class="pl-sm-0">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                @click="handleEditHospitalEvent(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-popconfirm
              title="Are you sure you want to delete this hospital?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '0' })"
            >
              <a-tooltip title="Delete">
                <a-button size="small" type="link" danger>
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>

          <template v-else>
            <a-popconfirm
              title="Are you sure you want to restore this hospital?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleStatusChangeEvent({ id: record.id, status: '1' })"
            >
              <a-tooltip title="Restore">
                <a-button size="small" type="link" class="pl-sm-0">
                  <i class="ti ti-rotate ti-lg"></i>
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </template>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";
import datatableMixins from "../../mixins/datatableMixins";
import { commonService } from "../../services";

export default {
  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.hospitalDatatableUrl,
      statusChangeUrl: this.$constants.hospitalChangeStatusUrl,
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          sorter: true,
          defaultSortOrder: "ascend",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.name
            ? [this.$route.query?.name]
            : undefined,
        },
        {
          title: "Company",
          dataIndex: "company_id",
          key: "company_id",
          customRender: ({ record }) => record?.companies[0]?.name ?? "N/A",
          defaultFilteredValue: this.$route.query?.company_id
            ? [Number(this.$route.query?.company_id)]
            : undefined,
          filterMultiple: false,
        },
        {
          title: "City",
          dataIndex: "city",
          key: "city",
          sorter: true,
        },
        {
          title: "State",
          dataIndex: "state",
          key: "state",
          sorter: true,
        },
        {
          title: "Zip Code",
          dataIndex: "zipcode",
          key: "zipcode",
          sorter: true,
        },
        {
          title: "QuickBooks",
          dataIndex: "quickbooks",
          key: "quickbooks",
          slots: {
            customRender: "quickbooks",
          },
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          width: 120,
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: [
        {
          key: "1",
          counts: 0,
          text: "Active",
          status: 1,
          slots: { tab: "customRender" },
        },
        {
          key: "0",
          counts: 0,
          text: "Inactive",
          status: 0,
          slots: { tab: "customRender" },
        },
      ],
      defaultFilterParams: {
        name: this.$route.query?.name ? [this.$route.query?.name] : undefined,
        company_id: this.$route.query?.company_id
          ? [this.$route.query?.company_id]
          : undefined,
        sortField: this.$route.query?.sortField ?? "name",
        sortOrder: this.$route.query?.sortOrder ?? "ascend",
      },

      companies: [],
      classes: [],
      accounts: [],
      customers: [],
      quickbookTerms: [],
      carolinaClasses: [],

      excelExportUrl: this.$constants.hospitalExportExcelUrl,
      downloading: false,
    };
  },

  mounted() {
    this.getCompanies();
    this.getQuickbooksData();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleEditHospitalEvent(record) {
      this.open({
        title: "Edit Hospital",
        path: "hospital.store",
        callback: this.callback,
        record: record,
        extra: {
          companies: this.companies,
          classes: this.classes,
          accounts: this.accounts,
          customers: this.customers,
          carolina_classes: this.carolinaClasses,
          quickbookTerms: this.quickbookTerms,
        },
      });
    },

    handleCreateNewHospitalEvent() {
      this.open({
        title: "Add Hospital",
        path: "hospital.store",
        callback: this.callback,
        extra: {
          companies: this.companies,
          classes: this.classes,
          accounts: this.accounts,
          customers: this.customers,
          carolina_classes: this.carolinaClasses,
          quickbookTerms: this.quickbookTerms,
        },
      });
    },

    callback() {
      this.getQuickbooksData();
      this.refresh();
    },

    getCompanies() {
      commonService.store(this.$constants.getCompaniesUrl).then((res) => {
        if (res.success) {
          this.companies = res.data;

          this.columns = this.columns.map((c) => {
            if (c.key == "company_id") {
              c.filters = this.companies.map((e) => ({
                value: e.id,
                text: e.name,
              }));
            }
            return c;
          });
        }
      });
    },

    getQuickbooksData() {
      commonService
        .store(this.$constants.getQuickbooksData, {
          names: [
            "classes",
            "accounts",
            "customers",
            "carolina_classes",
            "terms",
          ],
        })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "classes") {
                this.classes = JSON.parse(v.data);
              }
              if (v.name == "accounts") {
                this.accounts = JSON.parse(v.data);
              }
              if (v.name == "customers") {
                this.customers = JSON.parse(v.data);
              }
              if (v.name == "carolina_classes") {
                this.carolinaClasses = JSON.parse(v.data);
              }
              if (v.name == "terms") {
                this.quickbookTerms = JSON.parse(v.data);
              }
            });
          }
        });
    },

    handleExportExcelEvent(type) {
      var url = this.excelExportUrl;
      var fileName = "Hospitals.xlsx";

      this.downloading = true;
      commonService
        .downloadFile(url, {}, fileName)
        .then((res) => {
          this.$message.success(res);
          this.downloading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.downloading = false;
        });
    },
  },
};
</script>
