<template>
  <div>
    <template v-if="loading">
      <a-skeleton active />
    </template>

    <template v-else>
      <a-form
        name="bin_to_bin_product_form"
        ref="bin_to_bin_product_form"
        :model="formState"
        :rules="rules"
        layout="vertical"
        @finish="onSubmit"
      >
        <a-row :gutter="[20, 0]">
          <a-col :xs="24">
            <a-form-item label="Type" name="type">
              <a-radio-group
                v-model:value="formState.type"
                @change="handleTypeChangeEvent"
              >
                <a-radio
                  v-for="option in types"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.text }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :xs="24" :lg="12">
            <a-form-item label="Hospital From" name="hospital_from">
              <a-select
                v-model:value="formState.hospital_from"
                placeholder="Select hospital from"
                optionFilterProp="label"
                :showSearch="true"
                width="100%"
                disabled
              >
                <a-select-option
                  v-for="option in hospitals.filter(
                    (v) => v.id != formState.hospital_to
                  )"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :xs="24" :lg="12">
            <a-form-item
              v-if="formState.type == 0"
              label="Hospital To"
              name="hospital_to"
            >
              <a-select
                v-model:value="formState.hospital_to"
                placeholder="Select hospital to"
                optionFilterProp="label"
                :showSearch="true"
                width="100%"
                @change="handleToHospitalChangeEvent"
              >
                <a-select-option
                  v-for="option in hospitals.filter(
                    (v) => v.id != formState.hospital_from
                  )"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :xs="24" v-if="formState.hospital_to">
            <a-form-item
              label="Hospital To Templates"
              name="hospital_to_template_id"
            >
              <a-select
                v-model:value="formState.hospital_to_template_id"
                placeholder="Select hospital to template"
                optionFilterProp="label"
                :showSearch="true"
                width="100%"
                @change="handleToHospitalTemplateChangeEvent"
              >
                <a-select-option
                  v-for="option in toHospitalTemplates"
                  :key="option.id"
                  :label="option.name"
                  :value="option.id"
                >
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :xs="24">
            <a-form-item label="Remarks" name="remarks">
              <a-textarea
                v-model:value="formState.remarks"
                placeholder="Enter remarks here..."
                :auto-size="{ minRows: 2, maxRows: 5 }"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-alert
          v-if="showOrderProductError"
          class="mb-sm-1"
          message="Need to select at least one product for B2B Transfer."
          type="error"
        />

        <a-table
          :row-selection="rowSelection"
          :columns="computedColumns"
          :rowKey="(record, index) => record.id"
          :dataSource="formState.products"
          :pagination="false"
          size="small"
          :loading="tableLoading"
          class="ant-table-vertical-align-baseline"
          :scroll="{ x: true, y: 0 }"
          @change="handleTableChange"
        >
          <template #filterDropdown="filterDropdown">
            <xFilterInputSearchDropdown
              :filterDropdown="filterDropdown"
              @handleSearch="handleDatatableSearch"
              @handleReset="handleDatatableReset"
            />
          </template>

          <template #filterIcon="filterIcon">
            <xFilterIcon :filterIcon="filterIcon" />
          </template>

          <template #hospital_price="{ record, index }">
            <template
              v-if="
                formState.hospital_to == undefined ||
                formState.hospital_from == undefined
              "
            >
              N/A
            </template>

            <template v-else>
              <template v-if="fromHospitalProductPrice(record)">
                {{
                  $comman.withCurrency(fromHospitalProductPrice(record).price)
                }}
              </template>

              <template v-else>
                <a-form-item
                  label=""
                  ref="hospital_price"
                  :name="['products', index, 'hospital_price']"
                  :rules="[
                    {
                      message: 'Required',
                      type: 'integer',
                      validator: async (rule, value) =>
                        hospitalPriceValidation(rule, value, record),
                    },
                  ]"
                >
                  <a-input-number
                    type="number"
                    v-model:value="record.hospital_price"
                    placeholder="Enter here..."
                    :min="0.01"
                    :step="0.01"
                    :disabled="!formState.selected_ids.includes(record.id)"
                  />
                </a-form-item>
              </template>
            </template>
          </template>

          <template #transfer_qty="{ record, index }">
            <a-form-item
              label=""
              ref="transfer_qty"
              :name="['products', index, 'transfer_qty']"
              :rules="[
                {
                  message: 'Required',
                  type: 'integer',
                  validator: async (rule, value) =>
                    transferQtyValidation(rule, value, record),
                },
              ]"
            >
              <a-input-number
                type="number"
                v-model:value="record.transfer_qty"
                placeholder="Enter here..."
                :step="1"
                :min="formState.type == 1 ? -record.stock_qty : 1"
                :max="formState.type == 1 ? Infinity : record.stock_qty"
                :disabled="!formState.selected_ids.includes(record.id)"
              />
            </a-form-item>
          </template>
        </a-table>

        <side-drawer-buttons name="Transfer" />
      </a-form>
    </template>
  </div>
</template>

<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions, mapGetters } from "vuex";
import xFilterDateRangeDropdown from "../../../components/table/filterDateRangeDropdown.vue";
import xFilterIcon from "../../../components/table/filterIcon.vue";
import xFilterInputSearchDropdown from "../../../components/table/filterInputSearchDropdown.vue";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  components: {
    PlusOutlined,
    xFilterIcon,
    xFilterInputSearchDropdown,
    xFilterDateRangeDropdown,
  },

  data() {
    return {
      loading: true,
      tableLoading: false,
      showOrderProductError: false,
      searchProduct: undefined,
      formState: {
        hospital_from: undefined,
        hospital_to: undefined,
        hospital_to_template_id: undefined,
        type: 0,
        products: [],
        selected_ids: [],
        remarks: null,
      },
      hospitals: [],
      fromHospitalProductPrices: [],
      toHospitalTemplates: [],
      columns: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          customRender: ({ record }) => record.product?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.product.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "SKU",
          dataIndex: "sku",
          key: "sku",
          customRender: ({ record }) => record.product?.sku ?? "N/A",
        },
        {
          title: "Qty/Box",
          dataIndex: "qty",
          key: "qty",
          customRender: ({ record }) => {
            let noOfItems = record.product?.uom?.no_of_items ?? 1;
            noOfItems = noOfItems == 0 ? 1 : noOfItems;
            let box = Math.ceil(record.stock_qty / noOfItems);
            return `${record.stock_qty}/${box}`;
          },
        },
        {
          title: "Original Price",
          dataIndex: "original_price",
          key: "original_price",
          width: 100,
          customRender: ({ record }) =>
            record?.product?.price
              ? this.$comman.withCurrency(record.product.price)
              : "N/A",
        },
        {
          title: "Hospital Price",
          dataIndex: "hospital_price",
          key: "hospital_price",
          width: 100,
          slots: {
            customRender: "hospital_price",
          },
        },
        {
          title: "Transfer Qty",
          dataIndex: "transfer_qty",
          key: "transfer_qty",
          width: 100,
          slots: {
            customRender: "transfer_qty",
          },
        },
      ],
      rules: {
        hospital_from: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
          },
        ],
        hospital_to: [
          {
            required: true,
            message: "This field is required!",
            type: "number",
          },
        ],
      },
    };
  },

  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record"]),
    ...mapGetters("auth", ["isAdmin"]),

    rowSelection() {
      return {
        selectedRowKeys: this.formState.selected_ids,
        onChange: this.onSelectChange,
      };
    },

    computedColumns() {
      return this.columns.filter(
        (item) =>
          !["hospital_price", "original_price"].includes(item.key) ||
          (this.formState.type == 0 && this.isAdmin)
      );
    },

    types() {
      let options = this.$constants.binToBinProductTypes;
      if (this.$comman.hasRole([1,5])) {
        return options;
      }
      return options.filter((e) => [0].includes(e.value));
    },
  },

  mounted() {
    commonService
      .get(this.$constants.getHospitalsUrl)
      .then((res) => {
        this.hospitals = res.data;
      })
      .finally(() => {
        this.formState.hospital_from = this.record.hospital_id;
        this.formState.products = this.record.hospital_products;
        this.loading = false;
      });
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    handleTypeChangeEvent() {
      this.onSelectChange([]);
      this.formState.hospital_to = undefined;
      this.fromHospitalProductPrices = [];
    },

    onSelectChange(selectedRowKeys) {
      let difference = [
        ...this.formState.selected_ids.filter(
          (x) => !selectedRowKeys.includes(x)
        ),
      ];
      difference.forEach((element) => {
        let index = this.formState.products
          .filter((e) => {
            if (this.searchProduct) {
              e.product.name
                .toString()
                .toLowerCase()
                .includes(this.searchProduct.toLowerCase());
            }
            return e;
          })
          .findIndex((v) => v.id == element);
        this.$refs.bin_to_bin_product_form.clearValidate([
          ["products", index, "transfer_qty"],
          ["products", index, "hospital_price"],
        ]);
      });

      if (difference.length > 0) {
        this.formState.products = this.formState.products.map((e) =>
          difference.includes(e.id)
            ? { ...e, transfer_qty: undefined, hospital_price: undefined }
            : e
        );
      }

      this.formState.selected_ids = selectedRowKeys;
      if (this.formState.selected_ids.length == 0) {
        this.showOrderProductError = true;
      } else {
        this.showOrderProductError = false;
      }
    },

    handleToHospitalChangeEvent() {
      this.tableLoading = true;
      this.formState.hospital_to_template_id = undefined;
      this.formState.selected_ids = [];
      this.formState.products = this.formState.products.map((e) => {
        return { ...e, transfer_qty: undefined };
      });
      Promise.all([
        this.getHospitalProductPrices(),
        this.fetchToHospitalTemplates(),
      ]).finally(() => (this.tableLoading = false));
    },

    getHospitalProductPrices() {
      return commonService
        .get(this.$constants.hospitalProductPricesUrl, {
          hospital_id: this.formState.hospital_to,
        })
        .then((res) => {
          this.fromHospitalProductPrices = res.data;
        });
    },

    fromHospitalProductPrice(item) {
      return this.fromHospitalProductPrices.find(
        (v) => v.product_id == item.product_id
      );
    },

    transferQtyValidation(rule, value, record) {
      if (
        this.formState.selected_ids.includes(record.id) &&
        ([undefined, ""].includes(record.transfer_qty) ||
          record.transfer_qty == 0)
      ) {
        return Promise.reject(rule.message);
      }
      return Promise.resolve();
    },

    hospitalPriceValidation(rule, value, record) {
      const reg = /^\d+(\.\d{1,2})*$/;
      if (
        this.formState.selected_ids.includes(record.id) &&
        (record.hospital_price == undefined || !reg.test(record.hospital_price))
      ) {
        return Promise.reject(rule.message);
      }
      return Promise.resolve();
    },

    onSubmit() {
      if (this.formState.selected_ids.length == 0) {
        this.showOrderProductError = true;
        return;
      }

      let data = JSON.parse(JSON.stringify(this.formState));
      delete data.hospital_to_template_id;
      data.products = data.products.filter((v) =>
        data.selected_ids.includes(v.id)
      );

      this.loadingStart();
      commonService
        .store(this.$constants.binToBinProductStoreUrl, data)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    handleTableChange(_, filters) {
      this.searchProduct = filters?.name[0] ?? undefined;
    },

    handleDatatableSearch({ selectedKeys, confirm, dataIndex }) {
      confirm();
    },

    handleDatatableReset({ clearFilters }) {
      clearFilters();
    },

    fetchToHospitalTemplates() {
      return commonService
        .get(this.$constants.hospitalDisposableTemplate, {
          hospital_id: this.formState.hospital_to,
        })
        .then((res) => {
          this.toHospitalTemplates = res.data;
        });
    },

    handleToHospitalTemplateChangeEvent() {
      let selectedTemplate = this.toHospitalTemplates.find(
        (e) => e.id == this.formState.hospital_to_template_id
      );

      if (selectedTemplate) {
        let templateProductIds = selectedTemplate.items
          .sort((a, b) => b.product.name.localeCompare(a.product.name))
          .map((e) => e.product_id);
        let hospitalProductIds = this.formState.products.map(
          (e) => e.product_id
        );
        let commanProductIds = templateProductIds.filter((e) =>
          hospitalProductIds.includes(e)
        );

        this.formState.selected_ids = this.formState.products
          .filter((e) => commanProductIds.includes(e.product_id))
          .map((e) => e.id);
        this.formState.products = this.formState.products
          .map((e) => {
            let templateItem = selectedTemplate.items.find(
              (item) => e.product_id == item.product_id
            );
            return templateItem
              ? {
                  ...e,
                  transfer_qty: templateItem?.qty ?? null,
                }
              : e;
          })
          .sort(
            (a, b) =>
              commanProductIds.indexOf(b.product_id) -
              commanProductIds.indexOf(a.product_id)
          );
      }
    },
  },
};
</script>
