<template>
  <a-layout-content>
    <a-page-header
      class="page-header"
      title="POC Quality Control Detail"
      style="margin-bottom: 24px; background-color: #fff"
      @back="() => $router.go(-1)"
    />

    <a-card :loading="cardLoader" size="small">
      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '200px' }"
      >
        <a-descriptions-item label="Product Name">
          {{ record.asset_inventory_data?.asset_product?.name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Manufacturer Name">
          {{ record.asset_inventory_data?.asset_manufacturer?.name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Owned By">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'owned_by',
                $constants.assetInventoryOwnedBy,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "owned_by",
                $constants.assetInventoryOwnedBy
              )
            }}
          </a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="Owned Name">
          <template v-if="record.owned_by == 1">
            {{ record.asset_inventory_data?.company?.name ?? "N/A" }}
          </template>
          <template v-else>
            {{ record.asset_inventory_data?.hospital?.name ?? "N/A" }}
          </template>
        </a-descriptions-item>
        <a-descriptions-item
          v-if="record.asset_inventory_data?.asset_inventory_hospital"
          label="Allocated To"
          :span="2"
        >
          {{
            record.asset_inventory_data?.asset_inventory_hospital?.hospital
              ?.name ?? "N/A"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Record No">
          {{ record.asset_inventory_data?.record_no ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Serial No">
          {{ record.asset_inventory_data?.serial_no ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Asset ID Tag">
          {{ record.asset_inventory_data?.asset_id_tag ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Secondary ID Tag">
          {{ record.asset_inventory_data?.secondary_id_tag ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Description" :span="2">
          {{ record.asset_inventory_data?.description ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Employee Name" :span="2">
          {{ record.employee?.full_name ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Completed Date">
          {{ $customDate.mdy(record.completed_date) }}
        </a-descriptions-item>
        <a-descriptions-item label="Due Date">
          {{ $customDate.mdy(record.due_date) }}
        </a-descriptions-item>
      </a-descriptions>

      <a-divider>CG8+</a-divider>
      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-descriptions
            title="Level 1"
            :column="1"
            size="small"
            bordered
            :labelStyle="{ width: '200px' }"
          >
            <a-descriptions-item label="pH">
              {{ record.cg8_plus_level_1_ph ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="pCO2">
              {{ record.cg8_plus_level_1_pco2 ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="pO2">
              {{ record.cg8_plus_level_1_po2 ?? "N/A" }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="12">
          <a-descriptions
            title="Level 3"
            :column="1"
            size="small"
            bordered
            :labelStyle="{ width: '200px' }"
          >
            <a-descriptions-item label="pH">
              {{ record.cg8_plus_level_3_ph ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="pCO2">
              {{ record.cg8_plus_level_3_pco2 ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="pO2">
              {{ record.cg8_plus_level_3_po2 ?? "N/A" }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>

      <a-divider>CG4+</a-divider>
      <a-row :gutter="[20, 0]">
        <a-col :span="12">
          <a-descriptions
            title="Level 1"
            :column="1"
            size="small"
            bordered
            :labelStyle="{ width: '200px' }"
          >
            <a-descriptions-item label="pH">
              {{ record.cg4_plus_level_1_ph ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="pCO2">
              {{ record.cg4_plus_level_1_pco2 ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="pO2">
              {{ record.cg4_plus_level_1_po2 ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="Lactate">
              {{ record.cg4_plus_level_1_lactate ?? "N/A" }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
        <a-col :span="12">
          <a-descriptions
            title="Level 3"
            :column="1"
            size="small"
            bordered
            :labelStyle="{ width: '200px' }"
          >
            <a-descriptions-item label="pH">
              {{ record.cg4_plus_level_3_ph ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="pCO2">
              {{ record.cg4_plus_level_3_pco2 ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="pO2">
              {{ record.cg4_plus_level_3_po2 ?? "N/A" }}
            </a-descriptions-item>
            <a-descriptions-item label="Lactate">
              {{ record.cg4_plus_level_3_lactate ?? "N/A" }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>

      <a-divider></a-divider>
      <a-table
        :dataSource="cg8Data"
        :columns="cg8Columns"
        bordered
        :pagination="false"
        size="small"
      >
        <template #name="{ text }">
          <b>{{ text }}</b>
        </template>
      </a-table>
      <a-divider>ACT</a-divider>
      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '200px' }"
      >
        <a-descriptions-item label="Level 1">
          {{ record.act_lavel_1 ?? "N/A" }}
        </a-descriptions-item>
        <a-descriptions-item label="Level 2">
          {{ record.act_lavel_2 ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>
      <a-divider></a-divider>

      <a-descriptions
        title=""
        :column="2"
        size="small"
        bordered
        :labelStyle="{ width: '450px' }"
      >
        <a-descriptions-item
          label="Is this new I-STAT device undergoing its initial QC?"
        >
          {{ record.is_device_undergoing }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Is this new I-STAT cartridge lot undergoing its initial QC?"
        >
          {{ record.is_cartridge_lot_undergoing }}
        </a-descriptions-item>
        <a-descriptions-item label="Notes">
          {{ record.notes }}
        </a-descriptions-item>
      </a-descriptions>

      <a-divider></a-divider>
      <a-descriptions
        title=""
        :column="1"
        size="small"
        bordered
        :labelStyle="{ width: '200px' }"
      >
        <a-descriptions-item label="Signature">
          <img :width="200" :src="record.signature" />
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
  </a-layout-content>
</template>

<script>
import backButton from "../../components/backButton.vue";
import { commonService } from "../../services";

export default {
  components: { backButton },
  data() {
    return {
      cardLoader: false,
      id: this.$route.params.id ?? 0,
      record: {},
      cg8Columns: [
        {
          title: "Name",
          dataIndex: "level",
          slots: {
            customRender: "name",
          },
        },
        { title: "Lot #", dataIndex: "lot" },
        { title: "Exp. Date", dataIndex: "exp_date" },
      ],
    };
  },

  mounted() {
    this.fetchCellSaverQC();
  },
  computed: {
    cg8Data() {
      return [
        {
          level: "i-STAT TriControls Level 1",
          lot: this.record?.istat_tricontrols_lavel_1_lot_number ?? "N/A",
          exp_date:
            this.$customDate.mdy(
              this.record?.istat_tricontrols_lavel_1_expiry_date
            ) ?? "N/A",
        },
        {
          level: "i-STAT TriControls Level 3",
          lot: this.record?.istat_tricontrols_lavel_3_lot_number ?? "N/A",
          exp_date:
            this.$customDate.mdy(
              this.record?.istat_tricontrols_lavel_3_expiry_date
            ) ?? "N/A",
        },
        {
          level: "CG8+ Cartridge",
          lot: this.record?.cg8_plus_cartridge_lot_number ?? "N/A",
          exp_date:
            this.$customDate.mdy(this.record?.cg8_plus_cartridge_expiry_date) ??
            "N/A",
        },
        {
          level: "CG4+ Cartridge",
          lot: this.record?.cg4_plus_cartridge_lot_number ?? "N/A",
          exp_date:
            this.$customDate.mdy(this.record?.cg4_plus_cartridge_expiry_date) ??
            "N/A",
        },
        {
          level: "i-STAT ACT Control Level 1",
          lot: this.record?.istat_act_control_lavel_1_lot_number ?? "N/A",
          exp_date:
            this.$customDate.mdy(
              this.record?.istat_act_control_lavel_1_expiry_date
            ) ?? "N/A",
        },
        {
          level: "i-STAT ACT Control Level 2",
          lot: this.record?.istat_act_control_lavel_2_lot_number ?? "N/A",
          exp_date:
            this.$customDate.mdy(
              this.record?.istat_act_control_lavel_2_expiry_date
            ) ?? "N/A",
        },
        {
          level: "i-STAT Kaolin ACT Cartridge",
          lot: this.record?.istat_kaolin_act_cartridge_lot_number ?? "N/A",
          exp_date:
            this.$customDate.mdy(
              this.record?.istat_kaolin_act_cartridge_expiry_date
            ) ?? "N/A",
        },
      ];
    },
  },
  methods: {
    fetchCellSaverQC() {
      this.cardLoader = true;
      commonService
        .get(this.$constants.pointOfCareQcShowUrl, { id: this.id })
        .then((res) => {
          if (!res.data) {
            this.$message.error(res.message);
            this.$router.go(-1);
            return;
          }

          this.record = res.data;
          this.cardLoader = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style></style>
