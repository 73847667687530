<template>
  <a-form
    :model="model"
    :rules="rules"
    layout="vertical"
    @finish="handleFinish"
  >
    <a-form-item label="To" ref="to_emails" name="to_emails">
      <a-select
        v-model:value="model.to_emails"
        mode="tags"
        style="width: 100%"
        placeholder="Enter emails here..."
        @change="handleChange"
      >
      </a-select>
    </a-form-item>

    <a-form-item label="CC" ref="cc_emails" name="cc_emails">
      <a-select
        v-model:value="model.cc_emails"
        mode="tags"
        style="width: 100%"
        placeholder="Enter emails here..."
        @change="handleChange1"
      >
      </a-select>
    </a-form-item>

    <a-form-item ref="subject" label="Subject" name="subject">
      <a-input
        v-model:value="model.subject"
        placeholder="Enter subject here..."
      />
    </a-form-item>

    <a-form-item ref="content" label="Body" name="content">
      <ckeditor
        :editor="editor"
        v-model="model.content"
        :config="editorConfig"
      />
    </a-form-item>
    <template v-if="employee?.email_signature_html_code">
      <a-divider orientation="left"> Email Signature </a-divider>
      <div v-html="employee?.email_signature_html_code" />
    </template>
    <!-- <a-space direction="vertical"> -->
    <a-row style="margin-bottom: 10px" :gutter="[16, 16]">
      <a-col v-for="r in record" :key="r" :span="6">
        <a-button
          :loading="renderingPdf[r.id] || false"
          type="link"
          @click="renderPdf(r)"
        >
          <a-space>
            <i class="ti ti-paperclip"></i>
            Invoice #{{ r.invoice_no }}
          </a-space>
        </a-button>
      </a-col>
    </a-row>
    <a-space direction="vertical">
      <a-space>
        <a-button type="primary" htmlType="submit" :loading="loading">
          <a-space>
            <i v-if="!loading" class="ti ti-send ti-lg"></i>
            Send Email
          </a-space>
        </a-button>

        <a-button
          v-if="!loading"
          type="primary"
          danger
          htmlType="reset"
          @click="$emit('close')"
        >
          <a-space> <i class="ti ti-x ti-lg"></i> Cancel </a-space>
        </a-button>
      </a-space>
    </a-space>
    <!-- </a-space> -->
  </a-form>
</template>

<script>
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import signature from "../../../assets/louis_signature";
import { commonService } from "../../../services";
import { comman } from "../../../helper/comman";
import { mapGetters } from "vuex";

export default {
  name: "hospital-qi-report-notification-mail-from",

  props: {
    record: {
      required: true,
      default: null,
    },
  },

  emits: ["close"],

  data() {
    return {
      loading: false,
      renderingPdf: {},
      model: {
        to_emails: [],
        cc_emails: [],
        subject: "",
        content: "",
      },
      rules: {
        to_emails: [
          {
            required: true,
            message: "This field is required!",
            type: "array",
          },
        ],
        cc_emails: [
          {
            required: false,
            message: "This field is required!",
            type: "array",
          },
        ],
        subject: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
        content: [
          {
            required: true,
            message: "This field is required!",
          },
        ],
      },
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["employee"]),
  },
  mounted() {
    this.updateModelData();
    this.model.to_emails =
      this.record[0]?.hospital?.ar_emails?.split(",") ?? [];
    this.model.cc_emails =
      this.record[0]?.hospital?.ar_cc_emails?.split(",") ?? [];
  },

  methods: {
    updateModelData() {
      const emailData = this.record.map((rec) => ({
        id: rec.id,
        to_emails: rec.to_emails || [],
        cc_emails: rec.cc_emails || [],
        customer_name: rec.customer_name,
        invoice_date: rec.date,
        due_date: rec.due_date,
        invoice_no: rec.invoice_no,
        amount: comman.withCurrency(rec.amount),
        open_balance: comman.withCurrency(rec.open_balance),
        due_since: comman.getDataDiffDays(rec.due_date),
      }));
      this.model.to_emails = [
        ...new Set(emailData.flatMap((rec) => rec.to_emails)),
      ];
      this.model.cc_emails = [
        ...new Set(emailData.flatMap((rec) => rec.cc_emails)),
      ];

      this.model.subject = `Payment Status Inquiry - Invoice: ${emailData
        .map((rec) => `#${rec.invoice_no}`)
        .join(", ")}`;

      const tableRows = emailData
        .map(
          (rec) =>
            `<tr>
            <td>${rec.invoice_no}</td>
            <td>${this.$customDate.mdy(rec.invoice_date)}</td>
            <td>${this.$customDate.mdy(rec.due_date)}</td>
            <td>${rec.open_balance}</td>
          </tr>`
        )
        .join("");

      this.model.content = `Dear ${this.record[0].customer_name}, <br/><br/>
     I’m reaching out to follow up on the attached invoice(s) and inquire about the payment status. Please find the details below for your reference:<br/><br/>
      <table border="1" style="border: 1px solid #dddddd; width:100%">
        <thead>
          <tr>
            <th>Invoice #</th>
            <th>Invoice Date</th>
            <th>Due Date</th>
            <th>Amount Due</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table><br/><br/>
      If payment has already been processed, please disregard this message. Otherwise, we would appreciate an update on the expected payment timeline. If there are any questions or if you need additional details, please don’t hesitate to reach out.
      <br/><br/>
      Thank you for your time and attention. We appreciate your partnership and look forward to your response.<br/><br/>Best regards,<br/><br/>`;
    },

    handleFinish() {
      this.loading = true;

      let invoices = this.record.map((rec) => ({
        qbo_invoice_id: rec.qbo_invoice_id,
        qbo_invoice_number: rec.invoice_no,
      }));
      let data = {
        ...this.model,
        content: this.model.content + this.employee.email_signature_html_code,
        invoices,
      };
      commonService
        .store(this.$constants.financeAccountReceivable, data)
        .then((res) => {
          this.$message.success(res.message);
          this.$emit("close", res.data);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleChange(val) {
      if (val.length > 0) {
        this.rules.to_emails[0].fields = [];
        for (let index = 0; index < val.length; index++) {
          this.rules.to_emails[0].fields.push({
            type: "email",
            message: `Invalid email!`,
          });
        }
      } else {
        this.rules.to_emails[0].fields = [];
      }
    },
    handleChange1(val) {
      if (val.length > 0) {
        this.rules.cc_emails[0].fields = [];
        for (let index = 0; index < val.length; index++) {
          this.rules.cc_emails[0].fields.push({
            type: "email",
            message: `Invalid email!`,
          });
        }
      } else {
        this.rules.cc_emails[0].fields = [];
      }
    },

    formData() {
      const start_date = this.$customDate.ymd(this.record.start_date);
      const end_date = this.$customDate.ymd(this.record.end_date);
      return {
        start_date,
        end_date,
        comments:
          "This QI report is generated from hospital QI report notification page.",
        signature,
        employee_name: "Louis Verdetto",
        signatured_at: this.$customDate.mdyhis(this.$comman.momentTz()),
        hospital_id: this.record.hospital_id,
      };
    },

    renderPdf(record) {
      this.renderingPdf[record.id] = true;
      commonService
        .renderFile(
          this.$constants.qboInvoicePdf,
          {
            qbo_invoice_id: record.qbo_invoice_id,
            qbo_invoice_number: record.invoice_no,
          },
          "application/pdf;base64"
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.renderingPdf[record.id] = false;
        });
    },
  },
  watch: {
    record: {
      handler(newRecords) {
        if (Array.isArray(newRecords) && newRecords.length > 0) {
          this.updateModelData();
          this.model.to_emails =
            this.record[0]?.hospital?.ar_emails?.split(",") ?? [];
          this.model.cc_emails =
            this.record[0]?.hospital?.ar_cc_emails?.split(",") ?? [];
        }
      },
      deep: true,
    },
  },
};
</script>
