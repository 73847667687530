<template>
  <div style="margin-bottom: 10px">
    <a-descriptions size="small" bordered :column="2">
      <a-descriptions-item label="Invoice Date">{{
        this.$customDate.mdy(record.date)
      }}</a-descriptions-item>
      <a-descriptions-item label="Due Date">{{
        this.$customDate.mdy(record.due_date)
      }}</a-descriptions-item>
      <a-descriptions-item label="Amount">{{
        $comman.withCurrency(record.amount)
      }}</a-descriptions-item>
      <a-descriptions-item label="Open Balance">{{
        $comman.withCurrency(record.open_balance)
      }}</a-descriptions-item>
      <a-descriptions-item label="Due Since">
        {{ $comman.getDataDiffDays(record.due_date) }} days
      </a-descriptions-item>
    </a-descriptions>
  </div>
  <a-form :model="model" layout="vertical" @finish="onSubmit" ref="noteForm">
    <a-form-item
      label="P.O. Number"
      name="invoice_po_number"
      :rules="{
        required: true,
        message: 'This field is required.',
      }"
    >
      <a-input
        v-model:value="model.invoice_po_number"
        placeholder="Enter here..."
      />
    </a-form-item>
    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../../mixins/formMixins";
import { commonService } from "../../../../services";

export default {
  emits: ["callback"],

  data() {
    return {
      model: {
        invoice_po_number: null,
      },
    };
  },
  mixins: [formMixins],

  computed: {
    ...mapGetters("drawer", ["record", "loading"]),
  },
  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    async onSubmit() {
      this.loadingStart();
      const val = {
        qbo_invoice_po_number: this.model.invoice_po_number,
        qbo_invoice_id: this.record.qbo_invoice_id,
      };
      try {
        const res = await commonService.store(
          this.$constants.qboupdatePoNumber,
          val
        );
        this.$message.success(res.message);
        this.close();
        this.record.invoice_po_number = res.data.qbo_invoice_po_number;
        this.$emit("callback", res.data);
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loadingStop();
      }
    },
  },
  mounted() {
    this.model.invoice_po_number = this.record?.invoice_po_number;
  },
};
</script>
