<template>
  <div style="margin-bottom: 10px">
    <a-descriptions size="small" bordered :column="2">
      <a-descriptions-item label="Invoice Date">{{
        this.$customDate.mdy(record.date)
      }}</a-descriptions-item>
      <a-descriptions-item label="Due Date">{{
        this.$customDate.mdy(record.due_date)
      }}</a-descriptions-item>
      <a-descriptions-item label="Amount">{{
        $comman.withCurrency(record.amount)
      }}</a-descriptions-item>
      <a-descriptions-item label="Open Balance">{{
        $comman.withCurrency(record.open_balance)
      }}</a-descriptions-item>
      <a-descriptions-item label="Due Since">
        {{ $comman.getDataDiffDays(record.due_date) }} days
      </a-descriptions-item>
    </a-descriptions>
  </div>
  <a-table
    size="small"
    :columns="columns"
    :dataSource="record.emails"
    :pagination="false"
    :rowKey="(record, index) => record.id"
  >
  </a-table>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { constants } from "../../../../helper/constants";

export default {
  emits: ["callback"],

  data() {
    return {
      columns: [
        {
          title: "Sent By",
          dataIndex: ["employee", "full_name"],
          key: "full_name",
        },
        {
          title: "Sent At",
          dataIndex: "created_at",
          key: "created_at",
          customRender: ({ text }) =>
            this.$customDate.mdyhis(moment(text).tz(constants.timezone)),
        },
      ],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "loading"]),
  },
};
</script>
