<template>
  <a-layout-content>
    <page-header>
      <template #title> Orders</template>

      <template #buttons>
        <a-space>
          <a-button
            type="primary"
            ghost
            @click="quickbooksGetAccount"
            :loading="quickbookGetAccountLoading"
          >
            <a-space>
              <i class="ti ti-refresh ti-1x"></i>
              QBO Sync
            </a-space>
          </a-button>

          <a-button
            type="primary"
            :loading="excelDownloading"
            @click="excelDownload"
          >
            <a-space>
              <i class="ti ti-download ti-lg"></i>
              Excel
            </a-space>
          </a-button>
        </a-space>
      </template>
    </page-header>

    <a-card size="small" class="mb-sm-1">
      <a-row type="flex" :gutter="[10]">
        <a-col flex="auto">
          <a-input
            v-model:value="query"
            placeholder="Search Mfg. Name/Product Name/SKU"
          />
        </a-col>
        <a-col flex="100px">
          <a-space>
            <a-button
              type="primary"
              :disabled="!query"
              :loading="datatableLoading"
              @click="handleSearch"
              block
            >
              Search
            </a-button>
            <a-button
              :disabled="!query"
              type="primary"
              @click="handleReset"
              danger
              block
            >
              Reset
            </a-button>
            <template v-if="activeTabKey === '1'">
              <a-button
                type="primary"
                :loading="excelExportLoading"
                @click="ProcessingOrdersItems"
              >
                <a-space>
                  <i class="ti ti-file-spreadsheet ti-lg"></i>
                  Processing Items
                </a-space>
              </a-button>
            </template>
          </a-space>
        </a-col>
      </a-row>
    </a-card>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-row class="mb-sm-1" justify="space-between">
        <div>
          <div v-if="activeTabKey == 0" style="display: flex">
            <a-select
              v-model:value="bulkActionValue"
              placeholder="Select any one"
              style="width: 150px; margin-right: 20px"
            >
              <template v-if="activeTabKey == 0">
                <a-select-option value="1"> Process Order</a-select-option>
              </template>
            </a-select>

            <a-popconfirm
              :visible="visible"
              title="Are you sure you want to place this order?"
              ok-text="Yes"
              cancel-text="No"
              @visibleChange="handleVisibleChange"
              @confirm="bulkAction"
              @cancel="visible = false"
            >
              <a-button
                html-type="button"
                type="primary"
                :disabled="
                  this.bulkActionValue == null ||
                  this.selectedRowKeys.length == 0
                "
              >
                Submit
              </a-button>
            </a-popconfirm>
          </div>
        </div>
      </a-row>

      <a-table
        :row-selection="rowSelection"
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
        :rowClassName="(record) => (record.is_flagged ? 'flagged-row' : '')"
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #order_no="{ record }">
          <a-space>
            <span>{{ record.order_no_with_hash }}</span>
            <a-tooltip
              v-if="'is_classes_mapped' in record && !record.is_classes_mapped"
              :title="record.class_not_mapped_message"
            >
              <InfoCircleOutlined style="color: red" />
            </a-tooltip>
          </a-space>
        </template>

        <template #delivery_type="{ record }">
          <a-tag
            :color="
              $comman.getValueFromObject(
                record,
                'delivery_type',
                $constants.orderDeliveryTypes,
                'color'
              )
            "
          >
            {{
              $comman.getValueFromObject(
                record,
                "delivery_type",
                $constants.orderDeliveryTypes
              )
            }}
          </a-tag>
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: 'admin-view-order',
                params: { order_no: record.order_no },
              }"
            >
              <a-tooltip title="View">
                <a-button type="link" size="small">
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <template v-if="record.status == 0">
              <router-link
                :to="{
                  name: 'admin-update-order',
                  params: { order_no: record.order_no },
                }"
              >
                <a-tooltip title="Edit">
                  <a-button type="link" size="small">
                    <i class="ti ti-pencil ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>

              <a-popconfirm
                v-if="record.is_classes_mapped"
                title="Are you sure you want to place this order?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '1' })
                "
              >
                <a-tooltip title="Process Order" placement="bottom">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-status-change ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>

              <a-popconfirm
                title="Are you sure you want to reject this order?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="
                  handleStatusChangeEvent({ id: record.id, status: '4' })
                "
              >
                <a-tooltip title="Reject" placement="bottom">
                  <a-button size="small" type="link" danger>
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-else-if="record.status == 1">
              <a-tooltip title="Add Dispatch Info">
                <a-button
                  type="link"
                  size="small"
                  @click="handleAddDispatchEvent(record)"
                >
                  <i class="ti ti-truck-loading ti-lg"></i>
                </a-button>
              </a-tooltip>

              <a-popconfirm
                title="Are you sure you want to resend order processing mail?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="sendProcessingMail(record)"
              >
                <a-tooltip title="Send Email" placement="bottom">
                  <a-button type="link" size="small">
                    <i class="ti ti-mail ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>

              <a-popconfirm
                v-if="record.is_flagged == 0"
                title="Are you sure you want to flag this order?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="flagOrder(record)"
              >
                <a-tooltip title="Mark as Flagged" placement="bottom">
                  <a-button type="link" size="small">
                    <i class="ti ti-flag ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>

              <a-popconfirm
                v-else
                title="Are you sure you want to unflag this order?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="unflagOrder(record)"
              >
                <a-tooltip title="Mark as Unflagged" placement="bottom">
                  <a-button type="link" size="small">
                    <i class="ti ti-flag-off ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>

              <a-tooltip title="Add Reject Info" placement="bottom">
                <a-button
                  type="link"
                  size="small"
                  @click="handleAddRejectEvent(record)"
                >
                  <i class="ti ti-circle-x ti-lg"></i>
                </a-button>
              </a-tooltip>
            </template>

            <template v-if="[1, 2].includes(record.status)">
              <a-tooltip title="Notes">
                <a-badge :count="record.notes.length" :offset="[-6, 0]">
                  <a-button type="link" size="small" @click="addNotes(record)">
                    <i class="ti ti-notes ti-lg"></i>
                  </a-button>
                </a-badge>
              </a-tooltip>
            </template>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";
import { commonService } from "../../../services";

export default {
  components: {
    InfoCircleOutlined,
  },

  mixins: [datatableMixins],

  data() {
    return {
      quickbookGetAccountLoading: false,
      datatableUrl: this.$constants.adminOrderDatatableUrl,
      query: this.$route.query.search,
      debounceTimeout: null,

      activeTabKey: this.$route.query?.status ?? "0",
      tabList: this.$constants.orderStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        order_no: this.$route.query?.order_no
          ? [this.$route.query?.order_no]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        creator_name: this.$route.query?.creator_name
          ? [this.$route.query?.creator_name]
          : undefined,
        delivery_type: this.$route.query?.delivery_type
          ? [this.$route.query?.delivery_type]
          : undefined,
      },

      selectedRowKeys: [],
      bulkActionValue: null,
      bulkActionUrl: this.$constants.adminOrderBulkActionUrl,
      statusChangeUrl: this.$constants.adminOrderStatusChangeUrl,
      visible: false,

      excelDownloading: false,
      excelExportLoading: false,
    };
  },

  computed: {
    rowSelection() {
      return this.activeTabKey === "0"
        ? {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: (record) => ({
              disabled: !record.is_classes_mapped,
            }),
          }
        : null;
    },

    columns() {
      return [
        {
          title: "Order Number",
          dataIndex: "order_no",
          key: "order_no",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "order_no",
          },
          defaultFilteredValue: this.$route.query?.order_no
            ? [this.$route.query?.order_no]
            : undefined,
        },
        {
          title: "Hospital",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "Created By",
          dataIndex: "creator_name",
          key: "creator_name",
          customRender: ({ record }) => record.creator?.full_name,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.creator_name
            ? [this.$route.query?.creator_name]
            : undefined,
        },
        {
          title: "Products/ Dispatched/ Received",
          dataIndex: "product_info",
          width: 110,
          key: "product_info",
          customRender: ({ record }) => {
            return `${record.products.length}/${
              record.products.filter((v) => v.status >= 1).length
            }/${record.products.filter((v) => v.status >= 2).length}`;
          },
        },
        {
          title: "Delivery",
          dataIndex: "delivery_type",
          key: "delivery_type",
          slots: {
            customRender: "delivery_type",
          },
          filters: this.$constants.orderDeliveryTypes,
          filterMultiple: false,
          defaultFilteredValue: this.$route.query?.delivery_type
            ? [parseInt(this.$route.query?.delivery_type)]
            : undefined,
        },
        ...(this.activeTabKey == "0"
          ? [
              {
                title: "Amount",
                dataIndex: "total_amount",
                key: "total_amount",
                align: "right",
                customRender: ({ text }) => this.$comman.withCurrency(text),
              },
            ]
          : []),
        {
          title: "Order Date",
          dataIndex: "order_date",
          key: "order_date",
          sorter: true,
          width: 100,
          defaultSortOrder: "descend",
          customRender: ({ record }) => this.$customDate.mdy(record.order_date),
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ];
    },
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    handleStatusChangeEvent(val) {
      this.datatableLoading = true;
      commonService.store(this.statusChangeUrl, val).then((res) => {
        if (res.success) {
          this.refresh();
          this.$message.success(res.message);
        } else {
          this.$message.error("Something went wrong.");
        }
      });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    bulkAction() {
      this.datatableLoading = true;
      commonService
        .store(this.bulkActionUrl, {
          ids: this.selectedRowKeys,
          status: this.bulkActionValue,
        })
        .then((res) => {
          this.datatableLoading = false;
          if (res.success) {
            this.selectedRowKeys = [];
            this.bulkActionValue = null;
            this.$message.success(res.message);
            this.refresh();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    sendProcessingMail(order) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminOrderprocessMailUrl, {
          id: order.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refresh();
          this.datatableLoading = false;
        });
    },

    handleAddDispatchEvent(record) {
      this.open({
        title: "Add Order Dispatch Info",
        path: "admin.order.dispatch",
        callback: this.refresh,
        record: record,
        width: "1000px",
      });
    },

    handleAddRejectEvent(record) {
      this.open({
        title: "Add Order Rejection Info",
        path: "admin.order.reject",
        callback: this.refresh,
        record: record,
        width: "1000px",
      });
    },

    addNotes(record) {
      this.open({
        title: `Order Notes | ${record.order_no_with_hash}`,
        path: "admin.order.notes",
        callback: (notes) => (record.notes = notes),
        record: record,
      });
    },

    handleVisibleChange(bool) {
      if (!bool) {
        this.visible = false;
        return;
      }
      if (!(this.bulkActionValue == null || this.selectedRowKeys.length == 0)) {
        this.visible = true;
      }
    },

    flagOrder(order) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminOrderFlagUrl, {
          id: order.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refresh();
        });
    },

    unflagOrder(order) {
      this.datatableLoading = true;
      commonService
        .store(this.$constants.adminOrderUnflagUrl, {
          id: order.id,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refresh();
        });
    },

    excelDownload() {
      this.excelDownloading = true;
      var currentStatus =
        this.$constants.orderStatuses.find((e) => e.value == this.activeTabKey)
          ?.text ?? "-";

      commonService
        .downloadFile(
          this.$constants.adminOrderExportExcelUrl,
          { status: this.activeTabKey },
          `${moment().unix()}-${currentStatus}-Orders.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
          this.excelDownloading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.excelDownloading = false;
        });
    },

    ProcessingOrdersItems() {
      this.excelExportLoading = true;
      commonService
        .downloadFile(
          this.$constants.adminOrdersProcessingItemExportExcel,
          {},
          `Processing-Orders-Items.xlsx`
        )
        .then((res) => {
          this.$message.success(res);
          this.excelExportLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.excelExportLoading = false;
        });
    },
    handleSearch() {
      this.refresh({ search: this.query });
    },

    handleReset() {
      this.query = null;
      this.refresh({ search: "" });
    },

    quickbooksGetAccount() {
      this.quickbookGetAccountLoading = true;
      commonService
        .store(this.$constants.getAccountFromQuickbooksUrl)
        .then((res) => {
          this.$message.success("QBO classes synced successfully.");
          this.handleSearch();
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.quickbookGetAccountLoading = false;
        });
    },
  },
};
</script>
