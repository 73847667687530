<template>
  <template v-if="model.services.includes(10) || model.services.includes(11)">
    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item
          label="Select hyperthermic device used"
          name="hyperthermic_pump_device"
          :rules="{
            required: true,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-select
            v-model:value="model.hyperthermic_pump_device"
            placeholder="Search device by serial number"
            optionFilterProp="label"
            showSearch
          >
            <template v-if="hyperthemicPumpFetching" #notFoundContent>
              <a-spin size="small" />
            </template>

            <a-select-option
              v-for="option in hyperthermicPumpDevice"
              :key="option.id"
              :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
              :value="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
            >
              {{ option.asset_product?.name }} | {{ option.model_no }} |
              {{ option.serial_no }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item
          label="Hyperthermic device cleaned after procedure?"
          name="is_hyperthermic_pump"
          :rules="{
            required: true,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-select
            v-model:value="model.is_hyperthermic_pump"
            placeholder="Select One..."
          >
            <a-select-option
              v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
              :key="yesNoNotApplicable.value"
              :label="yesNoNotApplicable.text"
              :value="yesNoNotApplicable.value"
            >
              {{ yesNoNotApplicable.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item
          v-if="
            model.is_hyperthermic_pump && model.is_hyperthermic_pump == 'No'
          "
          label="Remarks"
          name="hyperthermic_pump_remarks"
          :rules="{
            required: true,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-textarea
            v-model:value="model.hyperthermic_pump_remarks"
            placeholder="Enter remarks..."
          />
        </a-form-item>
      </a-col>
    </a-row>
  </template>

  <template v-else>
    <a-row :gutter="[20, 0]">
      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item
          :label="$comman.heartLungMachineLabel(hospital)"
          name="heart_lung_device"
          :rules="{
            required: heartLungRequired,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-select
            v-model:value="model.heart_lung_device"
            placeholder="Search device by serial number"
            optionFilterProp="label"
            showSearch
            @change="updateHeartLungDevice"
          >
            <template v-if="heartLungFetching" #notFoundContent>
              <a-spin size="small" />
            </template>

            <a-select-option
              v-for="option in heartLungCarts"
              :key="option.unique_code"
              :label="option.name"
              :value="option.unique_code"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item
          :label="$comman.heartLungMachineCleanLabel(hospital)"
          name="is_heart_lung"
          :rules="{
            required: heartLungRequired,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-select
            v-model:value="model.is_heart_lung"
            placeholder="Select One..."
          >
            <a-select-option
              v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
              :key="yesNoNotApplicable.value"
              :label="yesNoNotApplicable.text"
              :value="yesNoNotApplicable.value"
            >
              {{ yesNoNotApplicable.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item
          v-if="model.is_heart_lung && model.is_heart_lung == 'No'"
          label="Remarks"
          name="heart_lung_remarks"
          :rules="{
            required: true,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-textarea
            v-model:value="model.heart_lung_remarks"
            placeholder="Enter remarks..."
          />
        </a-form-item>
      </a-col>
    </a-row>

    <template v-if="!onlyNRMServiceSelected">
      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :md="12" :lg="8">
          <a-form-item
            label="Select heater cooler device used"
            name="heater_cooler_device"
            :rules="{
              required: heaterCoolerRequired,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-select
              v-model:value="model.heater_cooler_device"
              placeholder="Search device by serial number"
              optionFilterProp="label"
              showSearch
            >
              <template v-if="heaterCoolerFetching" #notFoundContent>
                <a-spin size="small" />
              </template>

              <a-select-option
                v-for="option in heaterCoolerDevice"
                :key="option.id"
                :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
                :value="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
              >
                {{ option.asset_product?.name }} | {{ option.model_no }} |
                {{ option.serial_no }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12" :lg="8">
          <a-form-item
            label="Heater cooler cleaned after procedure?"
            name="is_heater_cooler"
            :rules="{
              required: heaterCoolerRequired,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-select
              v-model:value="model.is_heater_cooler"
              placeholder="Select One..."
            >
              <a-select-option
                v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
                :key="yesNoNotApplicable.value"
                :label="yesNoNotApplicable.text"
                :value="yesNoNotApplicable.value"
              >
                {{ yesNoNotApplicable.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12" :lg="8">
          <a-form-item
            v-if="model.is_heater_cooler && model.is_heater_cooler == 'No'"
            label="Remarks"
            name="heater_cooler_remarks"
            :rules="{
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-textarea
              v-model:value="model.heater_cooler_remarks"
              placeholder="Enter remarks..."
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="[20, 0]">
        <a-col :xs="24" :md="12" :lg="8">
          <a-form-item
            label="Select cell saver device used"
            name="cell_saver_device"
            :rules="{
              required: cellSaverRequired,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-select
              v-model:value="model.cell_saver_device"
              placeholder="Search device by serial number"
              optionFilterProp="label"
              showSearch
            >
              <template v-if="cellSaverFetching" #notFoundContent>
                <a-spin size="small" />
              </template>

              <a-select-option
                v-for="option in cellSaverDevice"
                :key="option.id"
                :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
                :value="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
              >
                {{ option.asset_product?.name }} | {{ option.model_no }} |
                {{ option.serial_no }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12" :lg="8">
          <a-form-item
            label="Cell saver device cleaned after procedure?"
            name="is_cell_saver"
            :rules="{
              required: cellSaverRequired,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-select
              v-model:value="model.is_cell_saver"
              placeholder="Select One..."
            >
              <a-select-option
                v-for="yesNoNotApplicable in $constants.yesNoNotApplicable"
                :key="yesNoNotApplicable.value"
                :label="yesNoNotApplicable.text"
                :value="yesNoNotApplicable.value"
              >
                {{ yesNoNotApplicable.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xs="24" :md="12" :lg="8">
          <a-form-item
            v-if="model.is_cell_saver && model.is_cell_saver == 'No'"
            label="Remarks"
            name="cell_saver_remarks"
            :rules="{
              required: true,
              message: 'This field is required!',
              trigger: $constants.ruleTrigger,
            }"
          >
            <a-textarea
              v-model:value="model.cell_saver_remarks"
              placeholder="Enter remarks..."
            />
          </a-form-item>
        </a-col>
      </a-row>
    </template>

    <a-row v-if="showPointOfCareDeviceSelection" :gutter="[20, 0]">
      <a-col :xs="24" :md="12" :lg="8">
        <a-form-item
          label="Select point of care device used"
          name="point_of_care_device"
          :rules="{
            required: true,
            message: 'This field is required!',
            trigger: $constants.ruleTrigger,
          }"
        >
          <a-select
            v-model:value="model.point_of_care_device"
            placeholder="Search device by serial number"
            optionFilterProp="label"
            showSearch
          >
            <template v-if="pointOfCareFetching" #notFoundContent>
              <a-spin size="small" />
            </template>

            <a-select-option
              v-for="option in pointOfCareDevice"
              :key="option.id"
              :label="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
              :value="`${option.asset_product?.name} | ${option.model_no} | ${option.serial_no}`"
            >
              {{ option.asset_product?.name }} | {{ option.model_no }} |
              {{ option.serial_no }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
  </template>
</template>

<script>
import { commonService } from "../../../services";

export default {
  name: "case-report-step-8",
  inheritAttrs: false,
  props: ["step", "model", "hospital"],
  emits: ["validate"],

  data() {
    return {
      heartLungFetching: false,
      heartLungCarts: [],
      heartLungDevice: [],
      heaterCoolerFetching: false,
      heaterCoolerDevice: [],
      cellSaverFetching: false,
      cellSaverDevice: [],
      hyperthemicPumpFetching: false,
      hyperthermicPumpDevice: [],
      pointOfCareFetching: false,
      pointOfCareDevice: [],
    };
  },

  mounted() {
    // this.fetchHeartLungDevices();
    if (this.model.services.includes(10) || this.model.services.includes(11)) {
      this.fetchHyperthermicPumpDevices();
    } else {
      this.fetchHospitalCarts();
      if (!this.onlyNRMServiceSelected) {
        this.fetchHeaterCoolerDevices();
        this.fetchCellSaverDevices();
      }
    }

    if (this.showPointOfCareDeviceSelection) {
      this.fetchPointOfCareDevices();
    }
  },

  computed: {
    onlyNRMServiceSelected() {
      return (
        (this.model.services.includes(19) ||
          this.model.services.includes(23)) &&
        this.model.services.length == 1
      );
    },

    heartLungRequired() {
      let isHeartLung = false;
      this.model.services.forEach((element) => {
        if (!isHeartLung) {
          isHeartLung = [6, 7, 8, 13, 14, 19, 23].includes(element);
        }
      });
      return isHeartLung;
    },

    heaterCoolerRequired() {
      let isHeaterCooler = false;
      this.model.services.forEach((element) => {
        if (!isHeaterCooler) {
          isHeaterCooler = [6, 7, 8, 13, 14].includes(element);
        }
      });
      return isHeaterCooler;
    },

    cellSaverRequired() {
      let isCellSaver = false;
      this.model.services.forEach((element) => {
        if (!isCellSaver) {
          isCellSaver = [1, 2, 3, 4, 7, 8, 14, 21].includes(element);
        }
      });
      return isCellSaver;
    },

    showPointOfCareDeviceSelection() {
      return [18].reduce(
        (bool, curr) => bool || this.model.services.includes(curr),
        false
      );
    },
  },

  methods: {
    fetchHeartLungDevices() {
      this.heartLungFetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_heart_lung: true,
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.heartLungDevice = res.data;
        })
        .catch(() => (this.heartLungDevice = []))
        .finally(() => (this.heartLungFetching = false));
    },

    fetchHospitalCarts() {
      this.heartLungFetching = true;
      return commonService
        .get(this.$constants.getCartAssetInventoryHospitalUrl, {
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.heartLungCarts = res.data
            .reduce((pre, curr) => {
              let isExists = pre.find(
                (item) => item.unique_code == curr.unique_code
              );
              if (!isExists) {
                pre.push({
                  name: curr.name,
                  unique_code: curr.unique_code,
                  cart_allocated_date: curr.cart_allocated_date,
                  hospital_id: curr.hospital_id,
                  inventory: [curr],
                });
              } else {
                isExists.inventory.push(curr);
              }
              return pre;
            }, [])
            .filter((item) =>
              item.inventory.find(
                (inv) =>
                  inv?.asset_inventory?.asset_product?.asset_category_id == 3
              )
            );
        })
        .catch(() => (this.heartLungCarts = []))
        .finally(() => (this.heartLungFetching = false));
    },

    fetchHeaterCoolerDevices() {
      this.heaterCoolerFetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_heater_cooler: true,
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.heaterCoolerDevice = res.data;
        })
        .catch(() => (this.heaterCoolerDevice = []))
        .finally(() => (this.heaterCoolerFetching = false));
    },

    fetchCellSaverDevices() {
      this.cellSaverFetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_cell_saver: true,
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.cellSaverDevice = res.data;
        })
        .catch(() => (this.cellSaverDevice = []))
        .finally(() => (this.cellSaverFetching = false));
    },

    fetchPointOfCareDevices() {
      this.pointOfCareFetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_point_of_care: true,
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.pointOfCareDevice = res.data;
        })
        .catch(() => (this.pointOfCareDevice = []))
        .finally(() => (this.pointOfCareFetching = false));
    },

    updateHeartLungDevice(unique_code) {
      this.model.heart_lung_device_data = this.heartLungCarts.find(
        (item) => item.unique_code == unique_code
      );
    },

    fetchHyperthermicPumpDevices() {
      this.hyperthemicPumpFetching = true;
      return commonService
        .get(this.$constants.assetInventorySearchUrl, {
          status: [1, 2],
          is_hyperthermic_pump: true,
          hospital_id: this.model.hospital_id,
        })
        .then((res) => {
          this.hyperthermicPumpDevice = res.data;
        })
        .catch(() => (this.hyperthermicPumpDevice = []))
        .finally(() => (this.hyperthemicPumpFetching = false));
    },
  },
};
</script>
