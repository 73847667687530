<template>
  <a-descriptions
    size="small"
    bordered
    :labelStyle="{ color: '#666666', width: '150px' }"
    :column="1"
    class="mb-sm-1"
    ><!--:labelStyle="{ fontWeight: 500, color:'#666666' }"-->
    <a-descriptions-item label="Date:">
      {{ record.date ? $customDate.mdy(record.date) : "N/A" }}
    </a-descriptions-item>
    <a-descriptions-item label="Facility:">
      {{ record?.hospital?.name }}
    </a-descriptions-item>
    <a-descriptions-item label="Report No:">
      {{ record?.report_no }}
    </a-descriptions-item>
    <a-descriptions-item label="Created By:">
      {{ record?.employee?.full_name }}
    </a-descriptions-item>
  </a-descriptions>
  <a-form :model="model" layout="vertical" @finish="onSubmit">
    <a-form-item
      label="Procedure Date"
      name="date"
      :rules="[{ required: true, message: 'This field is required' }]"
    >
      <a-date-picker
        v-model:value="case_report_date"
        :format="$constants.datepickerFormat"
        :placeholder="$constants.datePickerPlaceholder"
        disabled
      />
    </a-form-item>
    <a-form-item
      label="Override With Invoice Date"
      name="date"
      :rules="[{ required: true, message: 'This field is required' }]"
    >
      <a-date-picker
        v-model:value="model.date"
        :format="$constants.datepickerFormat"
        :placeholder="$constants.datePickerPlaceholder"
      />
    </a-form-item>
    <side-drawer-buttons name="Submit" />
  </a-form>
</template>

<script>
import { commonService } from "@/services";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      model: {
        ids: [],
        date: null,
      },
      case_report_date: null,
    };
  },

  emits: ["callback"],

  computed: {
    ...mapGetters("drawer", ["record"]),
  },

  mounted() {
    this.model.ids = [this.record.id];
    this.case_report_date = this.record.date;
    this.model.date = this.model.date || moment();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    onSubmit() {
      const payload = {
        ids: this.model.ids,
        date: moment(this.model.date).format("YYYY-MM-DD"),
      };

      this.loadingStart();
      commonService
        .store(this.$constants.uploadInvoiceOnQuickbooksUrl, payload)
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
            this.close();
            this.$emit("callback");
          }
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },
  },
};
</script>
