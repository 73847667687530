<template>
  <a-row :gutter="[20, 0]">
    <a-col :xs="12">
      <a-form-item ref="nrp_is_dry_run_case" name="nrp_is_dry_run_case">
        <a-checkbox v-model:checked="model.nrp_is_dry_run_case">
          No circulatory cessation, Dry run case?
        </a-checkbox>
      </a-form-item>
    </a-col>

    <a-col :xs="12">
      <a-form-item ref="nrp_is_case_aborted" name="nrp_is_case_aborted">
        <a-checkbox v-model:checked="model.nrp_is_case_aborted">
          Case aborted?
        </a-checkbox>
      </a-form-item>
    </a-col>

    <template v-if="!model.nrp_is_dry_run_case && !model.nrp_is_case_aborted">
      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="NRP Initiation Date"
          name="nrp_initiation_date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_initiation_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
            @change="calculateNrpTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="NRP Initiation Time"
          name="nrp_initiation_time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.fullTimeRequired,
          }"
        >
          <a-input
            v-model:value="model.nrp_initiation_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
            @change="calculateNrpTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="NRP End Date"
          name="nrp_end_date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_end_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
            @change="calculateNrpTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="NRP End Time"
          name="nrp_end_time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.fullTimeRequired,
          }"
        >
          <a-input
            v-model:value="model.nrp_end_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
            @change="calculateNrpTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :md="12">
        <a-form-item name="nrp_total_time" label="NRP Total Time (Minutes)">
          <a-input
            v-model:value="model.nrp_total_time"
            placeholder="total time"
            :disabled="true"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Withdrawal of Life Sustaining Therapy Date"
          name="nrp_withdrawal_of_life_sustaining_therapy_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_withdrawal_of_life_sustaining_therapy_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
            @change="calculateNrpWithdrawalTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Withdrawal of Life Sustaining Therapy Time"
          name="nrp_withdrawal_of_life_sustaining_therapy_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_withdrawal_of_life_sustaining_therapy_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
            @change="calculateNrpWithdrawalTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Circulatory Cessation Date"
          name="nrp_circulatory_cessation_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_circulatory_cessation_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Circulatory Cessation Time"
          name="nrp_circulatory_cessation_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_circulatory_cessation_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Declaration of Death Date"
          name="nrp_declaration_of_death_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_declaration_of_death_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Declaration of Death Time"
          name="nrp_declaration_of_death_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_declaration_of_death_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Incision Date"
          name="nrp_incision_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_incision_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
            @change="calculateNrpIncisionTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Incision Time"
          name="nrp_incision_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_incision_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
            @change="calculateNrpIncisionTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Heart Flush Date"
          name="nrp_heart_flush_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_heart_flush_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Heart Flush Time"
          name="nrp_heart_flush_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_heart_flush_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Lung Flush Date"
          name="nrp_lung_flush_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_lung_flush_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Lung Flush Time"
          name="nrp_lung_flush_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_lung_flush_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="NRP Aortic Cannulation Date"
          name="nrp_aortic_cannulation_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_aortic_cannulation_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="NRP Aortic Cannulation Time"
          name="nrp_aortic_cannulation_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_aortic_cannulation_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Head Vessels Ligated Date"
          name="nrp_head_vessels_ligated_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_head_vessels_ligated_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Head Vessels Ligated Time"
          name="nrp_head_vessels_ligated_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_head_vessels_ligated_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="NRP Venous Cannulation Date"
          name="nrp_venous_cannulation_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_venous_cannulation_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="NRP Venous Cannulation Time"
          name="nrp_venous_cannulation_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_venous_cannulation_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Aortic and IVC Clamp Date"
          name="nrp_aortic_and_ivc_clamp_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_aortic_and_ivc_clamp_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Aortic and IVC Clamp Time"
          name="nrp_aortic_and_ivc_clamp_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_aortic_and_ivc_clamp_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Cold Aortic Flush Post NRP Date"
          name="nrp_cold_aortic_flush_post_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_cold_aortic_flush_post_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Cold Aortic Flush Post NRP Time"
          name="nrp_cold_aortic_flush_post_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_cold_aortic_flush_post_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Functional Warm Ischemic Date"
          name="nrp_functional_warm_ischemic_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_functional_warm_ischemic_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
            @change="calculateNrpFunctionalWarmIschemicTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="Functional Warm Ischemic Time"
          name="nrp_functional_warm_ischemic_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_functional_warm_ischemic_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
            @change="calculateNrpFunctionalWarmIschemicTotalTime"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="End of Observation Period Date"
          name="nrp_end_of_observation_period_date"
          :rules="{
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.nrp_end_of_observation_period_date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="6">
        <a-form-item
          label="End of Observation Period Time"
          name="nrp_end_of_observation_period_time"
          :rules="{
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.nrp_end_of_observation_period_time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12">
        <a-form-item
          name="nrp_venous_cannula_location"
          label="Venous Cannula Location"
        >
          <a-input
            v-model:value="model.nrp_venous_cannula_location"
            placeholder="location"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12">
        <a-form-item
          name="nrp_arterial_cannula_location"
          label="Arterial Cannula Location"
        >
          <a-input
            v-model:value="model.nrp_arterial_cannula_location"
            placeholder="location"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12">
        <a-form-item
          name="nrp_venous_clamp_location"
          label="Venous Clamp Location"
        >
          <a-input
            v-model:value="model.nrp_venous_clamp_location"
            placeholder="location"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12">
        <a-form-item
          name="nrp_aortic_clamp_locations"
          label="Aortic Clamp Location(s)"
        >
          <a-input
            v-model:value="model.nrp_aortic_clamp_locations"
            placeholder="location(s)"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="8">
        <a-form-item
          name="nrp_time_from_withdrawal_of_care"
          label="Time from Withdrawal of Care to on NRP (Minutes)"
        >
          <a-input
            v-model:value="model.nrp_time_from_withdrawal_of_care"
            placeholder="withdrawal time"
            :disabled="true"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="8">
        <a-form-item
          name="nrp_time_from_incision"
          label="Time from Incision to on NRP (Minutes)"
        >
          <a-input
            v-model:value="model.nrp_time_from_incision"
            placeholder="incision time"
            :disabled="true"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="8">
        <a-form-item
          name="nrp_time_from_functional_warm_ischemic"
          label="Time from Functional Warm Ischemic to on NRP (Minutes)"
        >
          <a-input
            v-model:value="model.nrp_time_from_functional_warm_ischemic"
            placeholder="functional warm ischemic time"
            :disabled="true"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="8">
        <a-form-item
          name="nrp_functional_warm_ischemic_time_params"
          label="Functional Warm Ischemic Time Parameters"
        >
          <a-input
            v-model:value="model.nrp_functional_warm_ischemic_time_params"
            placeholder="Enter Parameters"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="8">
        <a-form-item
          label="Minimum Flow During NRP (LPM)"
          name="nrp_minimum_flow"
          :rules="{
            type: 'number',
            message: 'This field is required and must be valid input!',
            validator: $validation.decimal,
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.nrp_minimum_flow"
            placeholder="LPM"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="12" :md="8">
        <a-form-item
          label="Maximum Flow During NRP (LPM)"
          name="nrp_minimum_flow"
          :rules="{
            type: 'number',
            message: 'This field is required and must be valid input!',
            validator: $validation.decimal,
          }"
        >
          <a-input-number
            type="number"
            v-model:value="model.nrp_maximum_flow"
            placeholder="LPM"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="8">
        <a-form-item label="Highest Lactate during NRP">
          <a-input
            v-model:value="model.nrp_max_lac"
            placeholder="Highest Lactate during NRP"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="8">
        <a-form-item label="Lowest Lactate during NRP">
          <a-input
            v-model:value="model.nrp_min_lac"
            placeholder="Lowest Lactate during NRP"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="8">
        <a-form-item label="Percent Change from First to Last Lactate">
          <a-input-number
            v-model:value="model.nrp_percentage_lac"
            placeholder="Percent Change from First to Last Lactate"
            :formatter="$comman.percentageFormatter"
            :parser="$comman.percentageParser"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="8">
        <a-form-item label="Starting BASE Excess">
          <a-input
            v-model:value="model.nrp_first_base"
            placeholder="Starting BASE Excess"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="8">
        <a-form-item label="Ending BASE Excess">
          <a-input
            v-model:value="model.nrp_last_base"
            placeholder="Ending BASE Excess"
          />
        </a-form-item>
      </a-col>

      <a-col :xs="24" :sm="8">
        <a-form-item label="Percent Change from Staring to Ending BASE">
          <a-input-number
            v-model:value="model.nrp_percentage_base"
            placeholder="Percent Change from Staring to Ending BASE"
            :formatter="$comman.percentageFormatter"
            :parser="$comman.percentageParser"
          />
        </a-form-item>
      </a-col>
    </template>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Organ(s) Considered"
        name="organs_considered"
        :rules="{
          required: true,
          type: 'array',
          min: 1,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.organs_considered"
          mode="multiple"
          style="width: 100%"
          :token-separators="[',']"
          placeholder="Organ(s) Considered"
          :options="$constants.organs"
        />
      </a-form-item>
    </a-col>

    <a-col :xs="24" :md="12">
      <a-form-item
        label="Organ(s) Recovered"
        name="organs_recovered"
        :rules="{
          required: true,
          type: 'array',
          min: 1,
          message: 'This field is required!',
          trigger: $constants.ruleTrigger,
        }"
      >
        <a-select
          v-model:value="model.organs_recovered"
          mode="multiple"
          style="width: 100%"
          :token-separators="[',']"
          placeholder="Organ(s) Recovered"
          :options="$constants.organs"
          @change="() => this.$emit('handleOrganRecoveredChange')"
        />
      </a-form-item>
    </a-col>
  </a-row>

  <a-form-item
    ref="nrp_blended_technology_case"
    name="nrp_blended_technology_case"
  >
    <a-checkbox v-model:checked="model.nrp_blended_technology_case">
      <span style="font-weight: 500; font-size: 16px">
        Blended technology case?
      </span>
    </a-checkbox>
  </a-form-item>

  <template v-if="model.nrp_blended_technology_case">
    <template v-for="i in [1, 2, 3]" :key="i">
      <a-row :gutter="[20, 0]">
        <a-col :xs="8">
          <a-form-item
            :label="`Organ #${i}`"
            :name="`nrp_blended_organ_${i}`"
            :rules="{
              required: false,
              message: 'This field is required!',
              transform: (value) => (value ? value.trim() : ''),
            }"
          >
            <a-select
              v-model:value="model[`nrp_blended_organ_${i}`]"
              style="width: 100%"
              :placeholder="`Organ #${i}`"
              :options="$constants.organs"
              showSearch
            />
          </a-form-item>
        </a-col>

        <a-col :xs="8">
          <a-form-item
            :label="`Method #${i}`"
            :name="`nrp_blended_method_${i}`"
            :rules="{
              required: false,
              message: 'This field is required!',
              transform: (value) => (value ? value.trim() : ''),
            }"
          >
            <a-select
              v-model:value="model[`nrp_blended_method_${i}`]"
              style="width: 100%"
              :placeholder="`Method #${i}`"
              :options="$constants.blended_methods"
              showSearch
            />
          </a-form-item>
        </a-col>

        <a-col v-if="model[`nrp_blended_method_${i}`] == 'Other'" :xs="8">
          <a-form-item
            :label="`Other Method #${i}`"
            :name="`nrp_blended_other_method_${i}`"
            :rules="{
              required: false,
              message: 'This field is required!',
              transform: (value) => (value ? value.trim() : ''),
            }"
          >
            <a-input
              v-model:value="model[`nrp_blended_other_method_${i}`]"
              :placeholder="`Other Method #${i}`"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </template>
  </template>

  <a-divider />
  <a-row type="flex" class="mb-sm-1">
    <a-col flex="auto">
      <a-typography-title :level="5">
        Medication Administration
      </a-typography-title>
    </a-col>
    <a-col v-if="!isUsedLiveCharting" flex="100px">
      <a-button type="primary" @click="addEditMedicineRecord(null, null)">
        <a-space>
          <i class="ti ti-plus ti-lg"></i>
          Add Medication
        </a-space>
      </a-button>
    </a-col>
  </a-row>

  <a-table
    :columns="medicinesColumn"
    :rowKey="(_, index) => `${index}_medicines`"
    :dataSource="model.nrp_medicines"
    :pagination="false"
    size="small"
    class="ant-table-vertical-align-baseline"
    :scroll="{ x: true, y: 0 }"
  >
    <template #action="{ record, index }">
      <a-button
        size="small"
        type="link"
        @click="addEditMedicineRecord(record, index)"
      >
        <i class="ti ti-pencil ti-lg"></i>
      </a-button>

      <a-popconfirm
        title="Are you sure you want to delete this medicine record?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="deleteMedicineRecord(index)"
      >
        <a-tooltip title="Delete" placement="bottom">
          <a-button size="small" type="link" danger>
            <i class="ti ti-trash ti-lg"></i>
          </a-button>
        </a-tooltip>
      </a-popconfirm>
    </template>
  </a-table>

  <a-divider />
  <a-row type="flex" class="mb-sm-1">
    <a-col flex="auto">
      <a-typography-title :level="5">
        Blood Product Administration
      </a-typography-title>
    </a-col>
    <a-col v-if="!isUsedLiveCharting" flex="100px">
      <a-button type="primary" @click="addEditBloodProductRecord(null, null)">
        <a-space>
          <i class="ti ti-plus ti-lg"></i>
          Add Blood Product
        </a-space>
      </a-button>
    </a-col>
  </a-row>

  <a-table
    :columns="bloodProductsColumn"
    :rowKey="(_, index) => `${index}_blood_products`"
    :dataSource="model.nrp_blood_products"
    :pagination="false"
    size="small"
    class="ant-table-vertical-align-baseline"
    :scroll="{ x: true, y: 0 }"
  >
    <template #action="{ record, index }">
      <a-button
        size="small"
        type="link"
        @click="addEditBloodProductRecord(record, index)"
      >
        <i class="ti ti-pencil ti-lg"></i>
      </a-button>

      <a-popconfirm
        title="Are you sure you want to delete this blood product record?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="deleteBloodProductRecord(index)"
      >
        <a-tooltip title="Delete" placement="bottom">
          <a-button size="small" type="link" danger>
            <i class="ti ti-trash ti-lg"></i>
          </a-button>
        </a-tooltip>
      </a-popconfirm>
    </template>
  </a-table>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "case-report-step-6-for-nrp",
  inheritAttrs: false,
  props: ["step", "model", "record"],
  emits: ["validate", "handleOrganRecoveredChange"],

  data() {
    return {
      //
    };
  },

  mounted() {
    if (!this.isUsedLiveCharting) {
      this.calculateNrpTotalTime();
    }
  },

  computed: {
    isUsedLiveCharting() {
      return this.record?.live_chart ?? false;
    },

    medicinesColumn() {
      return [
        {
          title: "Date & Time",
          dataIndex: "date",
          key: "date",
          customRender: ({ record }) => `${record.date} ${record.time}`,
        },
        {
          title: "Drug",
          dataIndex: "drug",
          key: "drug",
        },
        {
          title: "Dose",
          dataIndex: "dose",
          key: "dose",
          customRender: ({ record }) => `${record.dose} ${record.doseUnit}`,
        },
        {
          title: "Administered By",
          dataIndex: "administered_by",
          key: "administered_by",
        },
        {
          title: "Lot No#",
          dataIndex: "lot_no",
          key: "lot_no",
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "Expiry Date",
          dataIndex: "expiry_date",
          key: "expiry_date",
          customRender: ({ text }) => text ?? "N/A",
        },
        ...[
          !this.isUsedLiveCharting
            ? {
                title: "Action",
                dataIndex: "action",
                key: "action",
                width: 150,
                fixed: "right",
                slots: { customRender: "action" },
                width: "10%",
              }
            : [],
        ],
      ];
    },

    bloodProductsColumn() {
      return [
        {
          title: "Date & Time",
          dataIndex: "date",
          key: "date",
          customRender: ({ record }) => `${record.date} ${record.time}`,
        },
        {
          title: "Product Type",
          dataIndex: "product_type",
          key: "product_type",
        },
        {
          title: "Unit Type",
          dataIndex: "unit_type",
          key: "unit_type",
        },
        {
          title: "Unit ID No",
          dataIndex: "unit_id_no",
          key: "unit_id_no",
        },
        {
          title: "Unit Expiration Date",
          dataIndex: "unit_expiration_date",
          key: "unit_expiration_date",
        },
        {
          title: "Volume (mL)",
          dataIndex: "blood_product_volume",
          key: "blood_product_volume",
        },
        ...[
          !this.isUsedLiveCharting
            ? {
                title: "Action",
                dataIndex: "action",
                key: "action",
                width: 150,
                fixed: "right",
                slots: { customRender: "action" },
                width: "10%",
              }
            : [],
        ],
      ];
    },
  },

  methods: {
    ...mapActions("modal", ["open"]),

    convertToMoment(date, time) {
      return moment(date + " " + time, "YYYY-MM-DD HH:mm:ss", true);
    },

    calculateNrpTotalTime() {
      if (
        this.model.nrp_initiation_date &&
        this.model.nrp_initiation_time &&
        this.model.nrp_initiation_time.length == 8
      ) {
        this.calculateNrpIncisionTotalTime();
        this.calculateNrpWithdrawalTotalTime();
        this.calculateNrpFunctionalWarmIschemicTotalTime();
      }

      if (
        this.model.nrp_initiation_date &&
        this.model.nrp_initiation_time &&
        this.model.nrp_initiation_time.length == 8 &&
        this.model.nrp_end_date &&
        this.model.nrp_end_time &&
        this.model.nrp_end_time.length == 8
      ) {
        let initiation = this.convertToMoment(
          this.$customDate.ymd(this.model.nrp_initiation_date),
          this.model.nrp_initiation_time
        );
        let end = this.convertToMoment(
          this.$customDate.ymd(this.model.nrp_end_date),
          this.model.nrp_end_time
        );
        this.model.nrp_total_time = Math.round(
          moment.duration(end.diff(initiation)).asMinutes()
        );
      }
    },

    calculateNrpWithdrawalTotalTime() {
      if (
        this.model.nrp_initiation_date &&
        this.model.nrp_initiation_time &&
        this.model.nrp_initiation_time.length == 8 &&
        this.model.nrp_withdrawal_of_life_sustaining_therapy_date &&
        this.model.nrp_withdrawal_of_life_sustaining_therapy_time &&
        this.model.nrp_withdrawal_of_life_sustaining_therapy_time.length == 8
      ) {
        let end = this.convertToMoment(
          this.$customDate.ymd(this.model.nrp_initiation_date),
          this.model.nrp_initiation_time
        );
        let start = this.convertToMoment(
          this.$customDate.ymd(
            this.model.nrp_withdrawal_of_life_sustaining_therapy_date
          ),
          this.model.nrp_withdrawal_of_life_sustaining_therapy_time
        );
        this.model.nrp_time_from_withdrawal_of_care = Math.round(
          moment.duration(end.diff(start)).asMinutes()
        );
      } else {
        this.model.nrp_time_from_withdrawal_of_care = null;
      }
    },

    calculateNrpIncisionTotalTime() {
      if (
        this.model.nrp_initiation_date &&
        this.model.nrp_initiation_time &&
        this.model.nrp_initiation_time.length == 8 &&
        this.model.nrp_incision_date &&
        this.model.nrp_incision_time &&
        this.model.nrp_incision_time.length == 8
      ) {
        let end = this.convertToMoment(
          this.$customDate.ymd(this.model.nrp_initiation_date),
          this.model.nrp_initiation_time
        );
        let start = this.convertToMoment(
          this.$customDate.ymd(this.model.nrp_incision_date),
          this.model.nrp_incision_time
        );
        this.model.nrp_time_from_incision = Math.round(
          moment.duration(end.diff(start)).asMinutes()
        );
      } else {
        this.model.nrp_time_from_incision = null;
      }
    },

    calculateNrpFunctionalWarmIschemicTotalTime() {
      if (
        this.model.nrp_initiation_date &&
        this.model.nrp_initiation_time &&
        this.model.nrp_initiation_time.length == 8 &&
        this.model.nrp_functional_warm_ischemic_date &&
        this.model.nrp_functional_warm_ischemic_time &&
        this.model.nrp_functional_warm_ischemic_time.length == 8
      ) {
        let end = this.convertToMoment(
          this.$customDate.ymd(this.model.nrp_initiation_date),
          this.model.nrp_initiation_time
        );
        let start = this.convertToMoment(
          this.$customDate.ymd(this.model.nrp_functional_warm_ischemic_date),
          this.model.nrp_functional_warm_ischemic_time
        );
        this.model.nrp_time_from_functional_warm_ischemic = Math.round(
          moment.duration(end.diff(start)).asMinutes()
        );
      } else {
        this.model.nrp_time_from_functional_warm_ischemic = null;
      }
    },

    deleteMedicineRecord(index) {
      this.model.nrp_medicines = this.model.nrp_medicines.filter(
        (_, i) => index != i
      );
    },

    addEditMedicineRecord(record, index) {
      this.open({
        title: "Medication",
        path: "newCaseReport.drawerForm.nrpMedication",
        callback: (e) => {
          let data = JSON.parse(
            JSON.stringify({
              date: this.$customDate.mdy(e.date),
              time: e.time,
              drug: e.drug,
              dose: e.dose,
              doseUnit: e.doseUnit,
              administered_by: e.administered_by,
              lot_no: e.lot_no,
              expiry_date: e.expiry_date
                ? this.$customDate.mdy(e.expiry_date)
                : null,
            })
          );
          if (index != null) {
            this.model.nrp_medicines = this.model.nrp_medicines.map((v, i) => {
              if (i == index) {
                return data;
              }
              return v;
            });
          } else {
            this.model.nrp_medicines.push(data);
          }
        },
        record,
        extra: this.hospital,
      });
    },

    deleteBloodProductRecord(index) {
      this.model.nrp_blood_products = this.model.nrp_blood_products.filter(
        (_, i) => index != i
      );
    },

    addEditBloodProductRecord(record, index) {
      this.open({
        title: "Blood Product",
        path: "newCaseReport.drawerForm.nrpBloodProduct",
        callback: (e) => {
          let data = {
            date: this.$customDate.mdy(e.date),
            time: e.time,
            product_type: e.product_type,
            unit_type: e.unit_type,
            unit_id_no: e.unit_id_no,
            unit_expiration_date: this.$customDate.mdy(e.unit_expiration_date),
            blood_product_volume: e.blood_product_volume,
            is_checked: false,
          };
          if (index != null) {
            this.model.nrp_blood_products[index] = data;
          } else {
            this.model.nrp_blood_products.push(data);
          }
        },
        record,
        extra: {
          timezone: this.hospital?.timezone,
          blood_type: this.model.blood_type,
        },
      });
    },
  },
};
</script>
