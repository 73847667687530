<template>
  <a-layout-content>
    <page-header>
      <template #title> Case Report </template>
    </page-header>

    <a-card
      :tabList="tabList"
      :activeTabKey="activeTabKey"
      @tabChange="(key) => (activeTabKey = key)"
      size="small"
    >
      <template #customRender="item">
        {{ item.text }} ({{ item.counts }})
      </template>

      <a-table
        class="ant-table-striped"
        :columns="columns"
        :rowKey="(record, index) => record.id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="datatableLoading"
        @change="handleTableChange"
        @refresh="refresh"
        size="small"
        :scroll="scroll"
        :rowClassName="
          (record, index) =>
            record.is_flagged
              ? 'flagged-row'
              : index % 2 === 1
              ? 'table-striped'
              : null
        "
      >
        <template #filterDropdown="filterDropdown">
          <xFilterInputSearchDropdown
            :filterDropdown="filterDropdown"
            @handleSearch="handleDatatableSearch"
            @handleReset="handleDatatableReset"
          />
        </template>

        <template #filterIcon="filterIcon">
          <xFilterIcon :filterIcon="filterIcon" />
        </template>

        <template #date="{ text }">
          {{ $customDate.mdy(text) }}
        </template>

        <template #action="{ record }">
          <a-space :size="1">
            <router-link
              :to="{
                name: 'manager.show-case-report',
                params: { uuid: record.uuid },
              }"
            >
              <a-tooltip title="View">
                <a-button
                  type="link"
                  size="small"
                  @click="
                    $comman.caseReportLogStore({
                      id: record.id,
                      action: 'View Record',
                    })
                  "
                >
                  <i class="ti ti-eye ti-lg"></i>
                </a-button>
              </a-tooltip>
            </router-link>

            <template v-if="record.status != 3">
              <router-link
                :to="{
                  name: 'manager.create-case-report',
                  query: { uuid: record.uuid },
                }"
              >
                <a-tooltip title="Edit">
                  <a-button
                    type="link"
                    size="small"
                    @click="
                      $comman.caseReportLogStore({
                        id: record.id,
                        action: 'Edit Record',
                      })
                    "
                  >
                    <i class="ti ti-pencil ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </router-link>
            </template>
            <a-tooltip title="Notes">
              <a-badge :count="record.notes.length" :offset="[-6, 0]">
                <a-button
                  type="link"
                  size="small"
                  @click="addNotes(record, true)"
                >
                  <i class="ti ti-notes ti-lg"></i>
                </a-button>
              </a-badge>
            </a-tooltip>
            <a-dropdown
              v-if="record.status != 1 || showLiveChartingButton(record)"
              placement="bottomLeft"
            >
              <a-button size="small" type="link">
                <i class="ti ti-dots-vertical ti-lg"></i>
              </a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a-tooltip
                      v-if="record.is_flagged == 0"
                      title="Mark as Flagged"
                      placement="left"
                    >
                      <a-button
                        type="link"
                        size="small"
                        @click="addNotes(record, false)"
                      >
                        <i class="ti ti-flag ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                    <a-tooltip
                      v-else
                      title="Mark as Unflagged"
                      placement="left"
                    >
                      <a-button
                        type="link"
                        size="small"
                        @click="addNotes(record, false)"
                      >
                        <i class="ti ti-flag-off ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-menu-item>

                  <a-menu-item v-if="showLiveChartingButton(record)">
                    <router-link
                      :to="{
                        name: 'manager.case-report-live-charting',
                        params: { uuid: record.uuid },
                      }"
                    >
                      <a-tooltip title="Live Charting" placement="left">
                        <a-button type="link" size="small">
                          <i class="ti ti-device-heart-monitor ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </router-link>
                  </a-menu-item>
                  <a-menu-item v-if="record.status == 2">
                    <a-tooltip placement="left" title="Revert Back To Pending">
                      <a-button
                        size="small"
                        type="link"
                        @click="handleRevertbacktoPending(record)"
                      >
                        <i class="ti ti-arrow-back ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-menu-item>

                  <a-menu-item v-if="record.status == 2">
                    <a-tooltip
                      v-if="record.hospital?.is_cycle_count_on"
                      title="You can not approve case report while cycle count is ON."
                      placement="left"
                    >
                      <a-button size="small" type="link" disabled>
                        <i class="ti ti-status-change ti-lg"></i>
                      </a-button>
                    </a-tooltip>

                    <a-popconfirm
                      v-else
                      title="Are you sure you want to approve this case report?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="
                        () => {
                          $comman.caseReportLogStore({
                            id: record.id,
                            action: 'Approve Record',
                          });
                          handleStatusChangeEvent({
                            id: record.id,
                            status: '3',
                          });
                        }
                      "
                    >
                      <a-tooltip title="Approve Case Report" placement="left">
                        <a-button size="small" type="link">
                          <i class="ti ti-status-change ti-lg"></i>
                        </a-button>
                      </a-tooltip>
                    </a-popconfirm>
                  </a-menu-item>

                  <a-menu-item v-if="record.status != 1">
                    <a-tooltip title="Download" placement="left">
                      <a-button
                        type="link"
                        size="small"
                        @click="downloadPdf(record)"
                      >
                        <i class="ti ti-download ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-menu-item>

                  <a-menu-item v-if="record.status != 1">
                    <a-tooltip title="Print" placement="left">
                      <a-button
                        type="link"
                        size="small"
                        @click="renderPdf(record)"
                      >
                        <i class="ti ti-printer ti-lg"></i>
                      </a-button>
                    </a-tooltip>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-space>
        </template>
      </a-table>
    </a-card>
  </a-layout-content>
</template>

<script>
import { commonService } from "@/services";
import { PlusOutlined } from "@ant-design/icons-vue";
import { mapActions } from "vuex";
import datatableMixins from "../../../mixins/datatableMixins";

export default {
  components: {
    PlusOutlined,
  },

  mixins: [datatableMixins],

  data() {
    return {
      datatableUrl: this.$constants.managerCaseReportDatatableUrl,
      statusChangeUrl: this.$constants.caseReportChangeStatusUrl,
      columns: [
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          sorter: true,
          slots: {
            customRender: "date",
          },
          defaultSortOrder:
            this.$route.query.sortField == "date"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Report No",
          dataIndex: "report_no",
          key: "report_no",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.report_no
            ? [this.$route.query?.report_no]
            : undefined,
          defaultSortOrder:
            this.$route.query.sortField == "report_no"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Facility",
          dataIndex: "hospital_name",
          key: "hospital_name",
          customRender: ({ record }) => record.hospital?.name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.hospital_name
            ? [this.$route.query?.hospital_name]
            : undefined,
        },
        {
          title: "MR No",
          dataIndex: "mr_number",
          key: "mr_number",
          sorter: true,
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.mr_number
            ? [this.$route.query?.mr_number]
            : undefined,
          defaultSortOrder:
            this.$route.query.sortField == "mr_number"
              ? this.$route.query.sortOrder
              : undefined,
        },
        {
          title: "Created By",
          dataIndex: "employee_name",
          key: "employee_name",
          customRender: ({ record }) => record.employee?.full_name ?? "N/A",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          defaultFilteredValue: this.$route.query?.employee_name
            ? [this.$route.query?.employee_name]
            : undefined,
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          customRender: ({ text }) => this.$customDate.ll(text),
          defaultSortOrder: this.$route.query.sortField
            ? this.$route.query.sortField == "created_at"
              ? this.$route.query.sortOrder
              : undefined
            : "descend",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          fixed: "right",
          slots: { customRender: "action" },
        },
      ],
      activeTabKey: this.$route.query?.status ?? "1",
      tabList: this.$constants.caseReportStatuses.map((item) => ({
        key: item.value.toString(),
        counts: 0,
        text: item.text,
        status: item.value,
        slots: { tab: "customRender" },
      })),
      defaultFilterParams: {
        report_no: this.$route.query?.report_no
          ? [this.$route.query?.report_no]
          : undefined,
        mr_number: this.$route.query?.mr_number
          ? [this.$route.query?.mr_number]
          : undefined,
        hospital_name: this.$route.query?.hospital_name
          ? [this.$route.query?.hospital_name]
          : undefined,
        employee_name: this.$route.query?.employee_name
          ? [this.$route.query?.employee_name]
          : undefined,
      },
    };
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    addNotes(record, is_click_note) {
      this.open({
        title: `Case Report Notes | #${record.report_no} `,
        path: "admin.newCaseReport.drawerForm.notes",
        callback: (notes, is_flag) => {
          record.notes = notes;
          record.is_flagged = is_flag;
        },
        record: record,
        extra: { is_click_note: is_click_note },
      });
    },

    handleRevertbacktoPending(record) {
      this.open({
        title: "Revert back to Pending",
        path: "manager.caseReport.drawerForm.revertBackToPending",
        record,
        callback: this.refresh,
      });
    },

    downloadPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "PDF Download",
      });
      this.datatableLoading = true;

      commonService
        .downloadFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          `${record.report_no}_case_report.pdf`
        )
        .then((res) => {
          this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    renderPdf(record) {
      this.$comman.caseReportLogStore({
        id: record.id,
        action: "Render Download",
      });
      this.datatableLoading = true;

      commonService
        .renderFile(
          this.$constants.caseReportExportPdfUrl,
          { id: record.id },
          "application/pdf;base64"
        )
        .then((res) => {
          // this.$message.success(res);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.datatableLoading = false;
        });
    },

    showLiveChartingButton(record) {
      if (record.hospital.is_live_charting_enable == 0) {
        return false;
      }
      return this.$constants.caseServicesIdForCPB
        .concat(this.$constants.caseServicesIdForATS)
        .concat(this.$constants.caseServicesIdForHIPEC)
        .concat(this.$constants.caseServicesIdForECMO)
        .concat(this.$constants.caseServicesIdForNRP)
        .filter((value, index, array) => array.indexOf(value) === index)
        .reduce(
          (bool, curr) => bool || record.data.services.includes(curr),
          false
        );
    },
  },
};
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>
