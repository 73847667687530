<template>
  <a-card
    :loading="loading"
    title="Product Pricing"
    size="small"
    class="product-prices"
  >
    <template #extra>
      <a-space v-if="!loading" size="small">
        <!-- <a-button
          type="primary"
          danger
          :loading="excelExportLoading"
          @click="handleHospitalProductExcelExportEvent"
        >
          <a-space size="small">
            <i class="ti ti-packge-export ti-lg"></i>
            Export Products
          </a-space>
        </a-button>

        <a-button
          ghost
          danger
          :loading="excelImportLoading"
          @click="handleHospitalProductExcelImportEvent"
        >
          <a-space size="small">
            <i class="ti ti-packge-import ti-lg"></i>
            Import Products
          </a-space>
        </a-button> -->
        <span>
          <strong> Cycle Count </strong>
          <a-switch
            :loading="cycleLoading"
            v-model:checked="is_cycle_count_on"
            @change="updateCycleCount"
            checked-children="On"
            un-checked-children="Off"
          />
        </span>

        <show-hide-column v-model="columns" />

        <a-button type="primary" @click="addNewProduct">
          <a-space size="small">
            <i class="ti ti-plus ti-lg"></i>
            Add New
          </a-space>
        </a-button>

        <a-dropdown type="primary">
          <a-button><i class="ti ti-dots-vertical ti-lg"></i></a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item>
                <a-button
                  type="link"
                  :loading="excelExportLoading"
                  @click="handleHospitalProductExcelExportEvent"
                >
                  <a-space>
                    <i class="ti ti-packge-export ti-lg"></i>
                    Export Products
                  </a-space>
                </a-button>
              </a-menu-item>

              <!-- <a-menu-item>
                <a-button
                  type="link"
                  :loading="excelImportLoading"
                  @click="handleHospitalProductExcelImportEvent"
                >
                  <a-space>
                    <i class="ti ti-packge-import ti-lg"></i>
                    Import Products
                  </a-space>
                </a-button>
              </a-menu-item> -->

              <a-menu-item v-if="hospitalProductPrices.length == 0">
                <a-button type="link" @click="handleImportFromHospitalEvent">
                  <a-space>
                    <i class="ti ti-packge-import ti-lg"></i>
                    Import from Hospital
                  </a-space>
                </a-button>
              </a-menu-item>

              <a-menu-item>
                <a-button type="link" @click="handleBinToBinClickEvent">
                  <a-space>
                    <i class="ti ti-arrows-right-left ti-lg"></i>
                    B2B Transfer
                  </a-space>
                </a-button>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </a-space>
    </template>

    <a-table
      size="small"
      :columns="columns.filter((e) => e.show ?? true)"
      :data-source="hospitalProductPrices"
      :rowKey="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChangeEvent"
      :scroll="{ x: true, y: 0 }"
    >
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon><SearchOutlined /></template>
            Search
          </a-button>

          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
      </template>

      <template #filterIcon="filtered">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>

      <template #hospital_price="{ record }">
        {{ $comman.withCurrency(record.price) }}
        <a-tooltip
          v-if="
            hospital.has_allow_product_mergin_less_then_10_per == 0 &&
            $comman.isLessThen10Percent(
              record.product.price / (record.product?.uom?.no_of_items ?? 1),
              record.price
            )
          "
          placement="top"
          title="Margin is less than 10%"
        >
          <a-button size="small" type="link" danger>
            <i class="ti ti-info-circle ti-lg"> </i>
          </a-button>
        </a-tooltip>
      </template>

      <template #qbo_mapping="{ record }">
        <i
          v-if="record?.product?.quickbooks_item_id"
          class="ti ti-check ti-lg"
          style="color: green"
        />
        <i v-else class="ti ti-x ti-lg" style="color: red" />
      </template>

      <template #is_discontinued="{ record }">
        <a-tag v-if="record.product.is_discontinued == 1" color="success">
          Yes
        </a-tag>
        <a-tag v-else color="error">No</a-tag>
      </template>

      <template #product_name="{ record }">
        {{ record?.product?.name }}
        <a-tooltip v-if="record?.notes">
          <template #title>
            {{ record.notes }}
          </template>
          <a-button type="link" size="small">
            <i class="ti ti-info-circle ti-lg"></i>
          </a-button>
        </a-tooltip>
      </template>

      <template #actions="{ record }">
        <a-space :size="1">
          <template v-if="!inlineRecord">
            <a-tooltip title="Edit">
              <a-button
                type="link"
                size="small"
                class="edit-color"
                @click="editProduct(record)"
              >
                <i class="ti ti-pencil ti-lg"></i>
              </a-button>
            </a-tooltip>

            <a-tooltip
              v-if="record.product?.specs_document"
              title="Specs Document"
            >
              <a :href="record.product.specs_document_url" target="_blank">
                <i class="ti ti-file-text ti-lg"></i>
              </a>
            </a-tooltip>

            <template v-if="record.stock_qty == 0">
              <a-popconfirm
                title="Are you sure you want to delete this assigned product?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteHospitalProduct(record.id)"
              >
                <a-tooltip title="Delete">
                  <a-button type="link" size="small" class="danger-color">
                    <i class="ti ti-trash ti-lg"></i>
                  </a-button>
                </a-tooltip>
              </a-popconfirm>
            </template>

            <template v-else>
              <a-popover
                title=""
                trigger="click"
                overlayClassName="overlay-popover-class"
              >
                <template #content>
                  <a-badge status="warning" />
                  You can not remove this product as its In Stock. To remove
                  this, you need to transfer all the items to another hospital.
                </template>
                <a-button type="link" size="small" class="danger-color">
                  <i class="ti ti-trash ti-lg"></i>
                </a-button>
              </a-popover>
            </template>
          </template>
        </a-space>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { mapActions } from "vuex";
import showHideColumn from "../../../components/table/showHideColumn.vue";
import { commonService } from "../../../services";

export default {
  name: "hospital-product-pricing",

  props: {
    hospital: {
      required: true,
    },
  },

  components: { SearchOutlined, showHideColumn },

  data() {
    return {
      loading: true,
      excelExportLoading: false,
      excelImportLoading: false,
      products: [],
      hospitalProductPrices: [],
      inlineRecord: null,
      is_cycle_count_on: false,
      cycleLoading: false,
      columns: [
        {
          title: "Product Name",
          dataIndex: "product_name",
          slots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "product_name",
          },
          onFilter: (value, record) =>
            record.product.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: "QBO Mapping",
          dataIndex: "qbo_mapping",
          slots: {
            customRender: "qbo_mapping",
          },
        },
        {
          title: "Billing Code",
          dataIndex: "billing_code",
          customRender: ({ text }) => text ?? "N/A",
        },
        {
          title: "Original Price Per Unit",
          dataIndex: "original_price",
          customRender: ({ record }) =>
            record?.product?.price
              ? this.$comman.withCurrency(
                  record.product.price / record.product?.uom?.no_of_items
                )
              : "N/A",
        },
        {
          title: "Hospital Price",
          dataIndex: "hospital_price",
          slots: {
            customRender: "hospital_price",
          },
          width: "120px",
        },
        {
          title: "Stock Qty/Box",
          dataIndex: "stock_qty",
          customRender: ({ record }) => {
            let noOfItems = record.product?.uom?.no_of_items ?? 1;
            noOfItems = noOfItems == 0 ? 1 : noOfItems;
            let box = Math.ceil(record.stock_qty / noOfItems);
            return `${record.stock_qty}/${box}`;
          },
        },
        {
          title: "UOM",
          dataIndex: "uom",
          customRender: ({ record }) => record.product?.uom?.name ?? "N/A",
        },
        {
          title: "Min. Par Level",
          dataIndex: "minimum_par_level_qty",
        },
        {
          title: "Replenish Qty (Per UOM)",
          dataIndex: "replenish_uom",
        },
        {
          title: "Discontinued?",
          dataIndex: "is_discontinued",
          key: "is_discontinued",
          slots: { customRender: "is_discontinued" },
          show: false,
        },
        {
          title: "Actions",
          dataIndex: "actions",
          slots: {
            customRender: "actions",
          },
        },
      ],
      pagination: {
        current: 1,
        size: "normal",
        pageSize: 0,
        total: 0,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
        showSizeChanger: true,
      },
    };
  },

  computed: {
    isCarolinaCompanyHospital() {
      return this.hospital.companies.filter((e) => e.id == 2).length != 1;
    },
  },

  async mounted() {
    const is_cycle_count = this.hospital.is_cycle_count_on ? true : false;
    this.is_cycle_count_on = is_cycle_count;
    await this.getProducts();
    await this.getHospitalProductPrices();
  },

  methods: {
    ...mapActions("drawer", ["open"]),

    updateCycleCount() {
      this.cycleLoading = true;
      commonService
        .store(this.$constants.updateCycleCount, {
          hospital_id: this.hospital.id,
          is_cycle_count_on: this.is_cycle_count_on,
        })
        .then((res) => {
          this.$message.success(res.message);
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.cycleLoading = false;
        });
    },

    getHospitalProductPrices() {
      this.loading = true;
      return commonService
        .get(this.$constants.hospitalProductPricesUrl, {
          hospital_id: this.hospital.id,
        })
        .then((res) => {
          this.hospitalProductPrices = res.data;
          this.loading = false;
        });
    },

    getProducts() {
      return commonService.get(this.$constants.getProductsUrl).then((res) => {
        this.products = res.data.filter((item) => item.status == 1);
      });
    },

    addNewProduct() {
      this.open({
        title: "Add new hospital product price",
        path: "hospital.drawerForm.storeProduct",
        callback: this.getHospitalProductPrices,
        record: { hospital_id: this.hospital.id },
        extra: {
          products: this.remainingProducts(),
          isCarolinaCompanyHospital: this.isCarolinaCompanyHospital,
          hospital: this.hospital,
        },
      });
    },

    editProduct(record) {
      this.open({
        title: "Edit hospital product price",
        path: "hospital.drawerForm.storeProduct",
        callback: this.getHospitalProductPrices,
        record,
        extra: {
          products: this.remainingProducts(record.product_id),
          isCarolinaCompanyHospital: this.isCarolinaCompanyHospital,
          hospital: this.hospital,
        },
      });
    },

    deleteHospitalProduct(id) {
      this.loading = true;
      commonService
        .get(this.$constants.hospitalProductPriceDeleteUrl, { id })
        .then((res) => {
          this.$message.success(res.message);
          this.getHospitalProductPrices();
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },

    remainingProducts(selectedId = null) {
      let hospitalProductIds = this.hospitalProductPrices
        .map((item) => item.product_id)
        .filter((item) => item != selectedId);

      return this.products.filter(
        (item) => !hospitalProductIds.includes(item.id)
      );
    },

    handleHospitalProductExcelExportEvent() {
      this.excelExportLoading = true;
      commonService
        .downloadFile(
          this.$constants.hospitalProductExcelExportUrl,
          {
            hospital_id: this.hospital.id,
          },
          "Hospital products.xlsx"
        )
        .then((res) => {
          this.$message.success(res);
          this.excelExportLoading = false;
        })
        .catch((err) => {
          this.$message.error(err);
          this.excelExportLoading = false;
        });
    },

    handleHospitalProductExcelImportEvent() {
      this.open({
        title: "Import Hospital Products",
        path: "hospital.drawerForm.importProductPrice",
        callback: this.getHospitalProductPrices,
        record: { hospital_id: this.hospital.id },
      });
    },

    handleImportFromHospitalEvent() {
      this.open({
        title: "Import from Hospital",
        path: "hospital.drawerForm.importFromHospital",
        callback: this.getHospitalProductPrices,
        record: { hospital_id: this.hospital.id },
      });
    },

    handleBinToBinClickEvent() {
      this.open({
        title: "Bin to Bin Stock",
        path: "hospital.drawerForm.binToBinStore",
        callback: this.getHospitalProductPrices,
        width: "1000px",
        record: {
          hospital_id: this.hospital.id,
          hospital_products: this.hospitalProductPrices,
        },
      });
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
    },

    handleReset(clearFilters) {
      clearFilters();
    },

    handleTableChangeEvent(pagination) {
      this.pagination = pagination;
    },
  },
};
</script>

<style scoped>
.product-prices div.ant-typography,
.product-prices .ant-typography p {
  margin-bottom: 0;
}
</style>
