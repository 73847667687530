<template>
  <a-layout-content>
    <page-header>
      <template #title> Case Report </template>

      <template #buttons>
        <!--  -->
      </template>
    </page-header>
    <a-row :gutter="[10, 10]">
      <a-col :span="$comman.hasRole([1, 5]) ? 12 : 24">
        <a-card size="small" hoverable :loading="cardLoading">
          <a-form
            ref="caseReportForm"
            :model="model"
            layout="vertical"
            :rules="rules"
          >
            <a-row :gutter="[16, 0]">
              <a-col :span="$comman.hasRole([1, 5]) ? 24 : 12">
                <a-form-item
                  ref="date_range"
                  label="Date Range"
                  name="date_range"
                >
                  <a-range-picker
                    :allowClear="false"
                    v-model:value="model.date_range"
                    :disabled-date="$comman.disabledFutureDate"
                    :format="$constants.datepickerFormat"
                    inputReadOnly
                    :ranges="ranges"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="$comman.hasRole([1, 5]) ? 24 : 12">
                <a-form-item ref="hospital_ids" name="hospital_ids">
                  <template #label>
                    <a-row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      style="width: 100%"
                    >
                      Hospitals
                      <a-button
                        type="link"
                        @click="selectAllNrpEnableHospitals"
                      >
                      Auto Select Organ Recovery Facilities
                      </a-button>
                    </a-row>
                  </template>

                  <a-select
                    v-model:value="model.hospital_ids"
                    show-search
                    optionFilterProp="label"
                    allowClear
                    placeholder="Select any..."
                    mode="multiple"
                  >
                    <a-select-option
                      v-for="option in hospitals"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="$comman.hasRole([1, 5]) ? 24 : 12">
                <a-form-item
                  label="Does this facility have NRP enabled?"
                  name="only_nrp"
                >
                  <a-radio-group v-model:value="model.only_nrp">
                    <a-radio :value="true">Yes</a-radio>
                    <a-radio :value="false">No</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>

              <a-col :span="$comman.hasRole([1, 5]) ? 24 : 12">
                <a-form-item
                  ref="date_time_format"
                  label="Date & Time Format"
                  name="date_time_format"
                >
                  <a-select
                    v-model:value="model.date_time_format"
                    show-search
                    optionFilterProp="label"
                    allowClear
                    placeholder="Select any..."
                  >
                    <a-select-option value="MM-DD-YYYY HH:MM:SS">
                      MM-DD-YYYY HH:MM:SS
                    </a-select-option>
                    <a-select-option value="MM-DD-YYYY HH:MM">
                      MM-DD-YYYY HH:MM
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-space>
              <a-button
                type="primary"
                html-type="button"
                :loading="submitBtnLoader"
                @click="exportExcel"
              >
                Export Excel
              </a-button>

              <a-button
                type="primary"
                html-type="button"
                :loading="submitBtnLoader"
                @click="exportCsv"
              >
                Export CSV
              </a-button>
            </a-space>
          </a-form>
        </a-card>
      </a-col>

      <template v-if="$comman.hasRole([1, 5])">
        <a-col :span="12">
          <a-card
            title="NRP Statistics Report"
            size="small"
            hoverable
            :loading="cardLoading"
          >
            <a-form ref="NrpCaseReportForm" :model="nrpmodel" layout="vertical">
              <a-form-item
                ref="selectedYear"
                label="Year"
                name="selectedYear"
                :rules="{ required: true, message: 'This field is required' }"
              >
                <a-select
                  v-model:value="nrpmodel.selectedYear"
                  :options="yearOptions"
                  style="width: 100%"
                  placeholder="Select Year"
                />
              </a-form-item>
              <a-form-item
                ref="selectedMonth"
                label="Month"
                name="selectedMonth"
              >
                <a-select
                  v-model:value="nrpmodel.selectedMonth"
                  :options="monthOptions"
                  style="width: 100%"
                  placeholder="Select Month"
                  allow-clear
                  showSearch
                  optionFilterProp="label"
                />
              </a-form-item>
              <a-button
                type="primary"
                html-type="button"
                :loading="downloadExcelBtnLoading"
                @click="downloadExcel"
              >
                Export Excel
              </a-button>
            </a-form>
          </a-card>
        </a-col>
      </template>
    </a-row>
  </a-layout-content>
</template>

<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { commonService } from "../../../services";

export default {
  components: { ExclamationCircleOutlined },
  data() {
    return {
      cardLoading: true,
      submitBtnLoader: false,
      currentYear: moment().year(),
      downloadExcelBtnLoading: false,
      nrpmodel: {
        selectedYear: undefined,
        selectedMonth: undefined,
      },
      model: {
        date_range: [null, null],
        hospital_ids: [],
        only_nrp: null,
        date_time_format: undefined,
      },
      ranges: {
        Today: [moment(), moment()],
        "Last 15 Days": [moment().subtract(14, "days"), moment()],
      },
      rules: {
        date_range: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "array",
            len: 2,
            fields: {
              0: {
                type: "date",
                required: true,
                message: "This field is required!",
              },
              1: { type: "date", required: true, message: "" },
            },
          },
        ],
        only_nrp: [
          {
            required: true,
            message: "This field is required!",
            trigger: "change",
            type: "boolean",
          },
        ],
        hospital_ids: {
          required: true,
          message: "This field is required!",
          trigger: "change",
          type: "array",
        },
        date_time_format: {
          required: true,
          message: "This field is required!",
          trigger: "change",
        },
      },
      hospitals: [],
    };
  },

  computed: {
    yearOptions() {
      return Array.from({ length: this.currentYear - 2021 }, (_, i) => ({
        label: (2022 + i).toString(),
        value: 2022 + i,
      }));
    },

    monthOptions() {
      return moment.months().map((month, index) => ({
        label: month,
        value: index + 1,
      }));
    },
  },

  async mounted() {
    await this.getHospitals();
    this.cardLoading = false;
  },

  methods: {
    getHospitals() {
      return commonService
        .get(this.$constants.getHospitalsUrl)
        .then((res) => {
          this.hospitals = res.data;
        })
        .catch((error) => this.$message.error(error));
    },

    formData() {
      return {
        dates: [
          this.$customDate.ymd(this.model.date_range[0]),
          this.$customDate.ymd(this.model.date_range[1]),
        ],
        date_time_format: this.model.date_time_format,
        hospital_ids: this.model.hospital_ids,
        only_nrp: this.model.only_nrp,
      };
    },

    dateString() {
      return (
        this.model.date_range[0].clone().format("MMDDYY") +
        "-" +
        this.model.date_range[1].clone().format("MMDDYY")
      );
    },

    exportExcel() {
      this.$refs.caseReportForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.reportCaseReport,
            { ...this.formData(), excel_export: true },
            "case-report-" + this.dateString() + ".xlsx"
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },
    downloadExcel() {
      this.$refs.NrpCaseReportForm.validate().then(() => {
        this.downloadExcelBtnLoading = true;
        commonService
          .downloadFile(
            this.$constants.reportNRPStatictic,
            {
              year: this.nrpmodel.selectedYear,
              month: this.nrpmodel.selectedMonth,
              export_excel: true,
            },
            `nrp_statistics_report_${
              this.nrpmodel.selectedMonth
                ? this.nrpmodel.selectedMonth + "_"
                : ""
            }${this.nrpmodel.selectedYear}.xlsx`
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.downloadExcelBtnLoading = false;
          });
      });
    },
    exportCsv() {
      this.$refs.caseReportForm.validate().then(() => {
        this.submitBtnLoader = true;
        commonService
          .downloadFile(
            this.$constants.reportCaseReport,
            { ...this.formData(), csv_export: true },
            "case-report-" + this.dateString() + ".csv"
          )
          .then((res) => {
            this.$message.success(res);
          })
          .catch((err) => {
            this.$message.error(err);
          })
          .finally(() => {
            this.submitBtnLoader = false;
          });
      });
    },

    selectAllNrpEnableHospitals() {
      this.model.hospital_ids = this.hospitals
        .filter((e) => e.is_can_receive_organs)
        .map((e) => e.id);
    },
  },
};
</script>
