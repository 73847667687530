<template>
  <a-form
    name="nrp_pre_procedure_checklist_form"
    ref="nrp_pre_procedure_checklist_form"
    :model="model"
    layout="vertical"
    @finish="onSubmit"
  >
    <a-row v-if="$comman.hasRole(1)" :gutter="[16, 0]">
      <a-col :span="12">
        <a-form-item
          ref="date"
          label="Date"
          name="date"
          :rules="{
            required: true,
            message: 'Invalid date.',
            type: 'date',
          }"
        >
          <a-date-picker
            v-model:value="model.date"
            :disabled-date="$comman.disabledFutureDate"
            :format="$constants.datepickerFormat"
            :placeholder="$constants.datePickerPlaceholder"
            :allowClear="false"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item
          ref="time"
          label="Time"
          name="time"
          :rules="{
            required: true,
            message: 'Invalid time.',
            validator: $validation.timeWithSecFormat,
          }"
        >
          <a-input
            v-model:value="model.time"
            v-maska="$constants.timeWithSecMaska"
            placeholder="HH:mm:ss"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row type="flex">
      <a-col flex="35px">
        <a-tooltip title="Select all N/A option">
          <a-button
            type="link"
            size="small"
            class="p-sm-0"
            @click="selectAllOption('not_applicable')"
          >
            N/A
          </a-button>
        </a-tooltip>
      </a-col>
      <a-col flex="35px">
        <a-tooltip title="Select all option">
          <a-button
            type="link"
            size="small"
            class="p-sm-0"
            @click="selectAllOption('checked')"
          >
            <CheckOutlined />
          </a-button>
        </a-tooltip>
      </a-col>
      <a-col flex="auto">
        <strong>CANNULATION CHECKLIST</strong>
      </a-col>
    </a-row>

    <template v-for="option in preProcedureChecklist" :key="option">
      <a-row type="flex">
        <a-col flex="35px">
          <a-checkbox
            v-model:checked="model.not_applicable[option]"
            @click="(e) => optionChecked(e, option, 'not_applicable')"
          />
        </a-col>
        <a-col flex="35px">
          <a-checkbox
            v-model:checked="model.checked[option]"
            @click="(e) => optionChecked(e, option, 'checked')"
          />
        </a-col>
        <a-col flex="calc(100% - 70px)">
          <span v-html="option" />

          <template v-if="option == 'Units PRBCs Available'">
            <a-input
              v-model:value="model.prbc_units"
              size="small"
              style="width: 100px; margin-left: 10px"
              placeholder="units"
            />
          </template>
        </a-col>
      </a-row>
    </template>

    <a-row type="flex" justify="center" class="mt-sm-2">
      <a-space direction="vertical">
        <vue-signature-pad
          :options="{ onEnd: saveSign }"
          ref="signPad"
          :customStyle="$constants.signPadCustomCss"
        />

        <a-row type="flex" justify="center">
          <a-col>
            <a-typography-paragraph>
              Signature indicates that the information reported is complete and
              accurate.
            </a-typography-paragraph>
          </a-col>
        </a-row>
        <a-row v-if="model.sign" justify="center">
          <a-space>
            <a-button
              htmlType="button"
              type="primary"
              danger
              ghost
              @click="clearSign"
            >
              Clear
            </a-button>
          </a-space>
        </a-row>
      </a-space>
    </a-row>

    <modal-buttons name="Save" />
  </a-form>
</template>

<script>
import { CheckOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  emits: ["callback"],

  components: { CheckOutlined },

  data() {
    return {
      model: {
        uuid: null,
        checked: [],
        not_applicable: [],
        prbc_units: null,
        sign: null,
        data: null,
        time: null,
        submitted_at: null,
      },
    };
  },

  computed: {
    ...mapGetters("modal", ["record"]),
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.record?.hospital?.timezone ?? this.$constants.timezone;
    },

    checklist() {
      return this.record?.live_chart?.nrp?.pre_procedure_checklist ?? null;
    },

    preProcedureChecklist() {
      return this.$constants.nrpPreProcedureChecklist;
    },
  },

  mounted() {
    this.getFormState();
  },

  methods: {
    ...mapActions("modal", ["loadingStart", "loadingStop", "close"]),

    getFormState() {
      if (this.checklist) {
        let oldChecked = this.checklist?.checked ?? [];
        let oldNotApplicable = this.checklist?.not_applicable ?? [];
        let checked = {};
        let notApplicable = {};
        this.preProcedureChecklist.forEach((e) => {
          checked[e] = oldChecked.includes(e) ? true : false;
          notApplicable[e] = oldNotApplicable.includes(e) ? true : false;
        });

        this.model.prbc_units = this.checklist?.prbc_units ?? null;
        this.model.checked = checked ?? [];
        this.model.not_applicable = notApplicable ?? [];
        this.model.sign = this.checklist.sign ?? null;
        this.model.date = this.checklist.date
          ? moment(this.checklist.date, "MM-DD-YYYY")
          : moment(this.checklist.submitted_at, "MM-DD-YYYY HH:mm:ss");
        this.model.time = this.checklist.time
          ? this.checklist.time
          : moment(this.checklist.submitted_at, "MM-DD-YYYY HH:mm:ss").format(
              "HH:mm:ss"
            );
        this.model.submitted_at = this.checklist.submitted_at ?? null;
        this.$nextTick(() => {
          this.$refs.signPad.fromDataURL(this.model.sign);
        });
      } else {
        this.model.date = moment().tz(this.timezone);
        this.model.time = moment().tz(this.timezone).format("HH:mm:ss");
      }
    },

    onSubmit() {
      if (this.model.sign == null) {
        this.$message.error("Signature must be required.");
        return;
      }

      let newChecked = [];
      for (const [key, value] of Object.entries(this.model.checked)) {
        if (value) {
          newChecked.push(key);
        }
      }
      let newNotApplicable = [];
      for (const [key, value] of Object.entries(this.model.not_applicable)) {
        if (value) {
          newNotApplicable.push(key);
        }
      }

      let data = {
        uuid: this.record.uuid,
        checked: newChecked,
        not_applicable: newNotApplicable,
        prbc_units: this.model.prbc_units,
        employee_id: this.checklist?.employee_id ?? this.employee.id,
        employee_name: this.checklist?.employee_name ?? this.employee.full_name,
        sign: this.model.sign,
        date: this.model.date.format("MM-DD-YYYY"),
        time: this.model.time,
      };
      data.submitted_at = `${data.date} ${data.time}`;

      this.loadingStart();
      commonService
        .store(this.$constants.caseReportStoreNRPPreProcedureChecklistUrl, data)
        .then((res) => {
          this.$message.success(res.message);
          this.close();
          this.$emit("callback", res.data);
        })
        .catch((err) => this.$message.error(err))
        .finally(() => this.loadingStop());
    },

    clearSign() {
      this.model.sign = null;
      this.$refs.signPad.clearSignature();
    },

    saveSign() {
      const { isEmpty, data } = this.$refs.signPad.saveSignature();
      if (isEmpty) {
        this.$message.error("Signature can not be empty.");
        return Promise.reject();
      }
      this.model.sign = data;
      return Promise.resolve();
    },

    optionChecked(e, option, key) {
      if (e.target.checked) {
        if (key == "checked") {
          this.model["not_applicable"][option] = false;
        } else {
          this.model["checked"][option] = false;
        }
      }
    },

    selectAllOption(key) {
      let allChecked = this.preProcedureChecklist.reduce(
        (bool, option) => bool && this.model[key][option],
        true
      );
      this.preProcedureChecklist.forEach(
        (option) => (this.model[key][option] = allChecked ? false : true)
      );
      key = key == "checked" ? "not_applicable" : "checked";
      this.preProcedureChecklist.forEach(
        (option) => (this.model[key][option] = false)
      );
    },
  },
};
</script>
