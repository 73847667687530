<template>
  <a-form
    name="time_tracking_update_status_form"
    ref="time_tracking_update_status_form"
    :model="model"
    :rules="rules"
    layout="vertical"
    @finish="handleFinish"
  >
    <div v-for="(r, index) in model" :key="index">
      <a-descriptions :column="2" size="small" bordered>
        <a-descriptions-item :span="2" label="Employee Name">
          <a-space>
            {{ r.record?.employee?.full_name ?? "N/A" }}
            <a-tag
              :color="
                $comman.getValueFromObject(
                  r.record.employee,
                  'employment_type',
                  $constants.employment_types,
                  'color'
                )
              "
            >
              {{
                $comman.getValueFromObject(
                  r.record.employee,
                  "employment_type",
                  $constants.employment_types
                )
              }}
            </a-tag>
          </a-space>
        </a-descriptions-item>

        <a-descriptions-item :span="2" label="Hospital Name">
          {{ r.record.hospital?.name ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item :span="2" label="Shift Name">
          {{ r.record.shift_type?.name ?? "N/A" }}
        </a-descriptions-item>

        <a-descriptions-item label="Start Date & Time"
          >{{ $customDate.mdy(r.record.start_date) }}
          {{ $customDate.hi(r.record.start_time) }}
        </a-descriptions-item>

        <a-descriptions-item label="End Date & Time">
          {{ $customDate.mdy(r.record.end_date) }}
          {{ $customDate.hi(r.record.end_time) }}
        </a-descriptions-item>

        <a-descriptions-item :span="2" label="Total Time (In Hours)">
          {{ parseFloat(r.record.total_time).toFixed(2) }}
        </a-descriptions-item>

        <template
          v-if="$constants.timeTrackingOnCall.includes(r.record.shift_type_id)"
        >
          <a-descriptions-item
            :span="2"
            label="Was on-call coverage also provided on the same day?"
          >
            {{ r.record.was_on_call == 1 ? "Yes" : "No" }}
          </a-descriptions-item>

          <template v-if="r.record.was_on_call">
            <a-descriptions-item label="Total On-Call Time (In Hours)">
              {{ parseFloat(r.record.total_on_call_time).toFixed(2) }}
            </a-descriptions-item>

            <a-descriptions-item label="On-Call End Date & Time">
              {{ $customDate.mdy(r.record.on_call_end_date) }}
              {{ $customDate.hi(r.record.on_call_end_time) }}
            </a-descriptions-item>
          </template>
        </template>

        <template v-if="r.record.was_travel">
          <a-descriptions-item
            :span="2"
            label="Is travel compensation available for hospital?"
          >
            {{ r.record.was_travel == 1 ? "Yes" : "No" }}
          </a-descriptions-item>

          <a-descriptions-item label="Home to Hospital">
            {{ $customDate.hi(r.record.home_departure) }} -
            {{ $customDate.hi(r.record.hospital_arrival) }}
          </a-descriptions-item>

          <a-descriptions-item label="Hospital to Home">
            {{ $customDate.hi(r.record.hospital_departure) }} -
            {{ $customDate.hi(r.record.home_arrival) }}
          </a-descriptions-item>

          <a-descriptions-item :span="2" label="Total Travel Time (In Hours)">
            {{ parseFloat(r.record.total_travel_time).toFixed(2) }}
          </a-descriptions-item>
        </template>

        <template v-if="r.record.shift_type_id == 4">
          <a-descriptions-item :span="2" label="Number of patients on ECMO">
            {{ r.record.no_of_ecmo ?? "N/A" }}
          </a-descriptions-item>

          <a-descriptions-item :span="2" label="MR no(s) of patients on ECMO">
            {{ r.record.mr_ecmo ?? "N/A" }}
          </a-descriptions-item>
        </template>

        <a-descriptions-item :span="2" label="Notes">
          {{ r.record.notes ?? "N/A" }}
        </a-descriptions-item>
      </a-descriptions>

      <a-divider orientation="left">Update Status</a-divider>

      <a-row :gutter="[20, 0]">
        <a-col :span="r.status == 2 ? 12 : 24">
          <a-form-item label="Status" :name="[index, 'status']">
            <a-select
              v-model:value="r.status"
              placeholder="Select status"
              show-search
              optionFilterProp="label"
              disabled
            >
              <a-select-option
                v-for="status in statuses"
                :key="status.value"
                :label="status.text"
                :value="status.value"
              >
                {{ status.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col v-if="r.status == 2" :span="12">
          <a-form-item
            label="QuickBooks Class"
            :name="[index, 'quickbooks_class_id']"
          >
            <a-select
              v-model:value="r.quickbooks_class_id"
              placeholder="Select quickbooks class"
              optionFilterProp="label"
              allowClear
              showSearch
            >
              <a-select-option
                v-for="option in computedClasses(r)"
                :key="option.Id"
                :label="option.Name"
                :value="option.Id"
              >
                {{ option.Name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <template v-if="r.status == 2">
          <template v-for="(payment, idx) in r.payments" :key="idx">
            <a-col :span="r.record.employee.employment_type == 1 ? 12 : 8">
              <a-form-item
                :label="payment.label"
                :name="[index, 'payments', idx, 'job_id']"
                :rules="{
                  required: true,
                  message: 'field is required!',
                  trigger: 'change',
                  type: 'number',
                }"
              >
                <a-select
                  v-model:value="payment.job_id"
                  placeholder="Select job"
                  show-search
                  optionFilterProp="label"
                  @change="paymentCalculate('job_id', index)"
                >
                  <a-select-option
                    v-for="option in employeeJobs.filter(
                      (e) => e.employee_id == r.record.employee_id
                    )"
                    :key="option.id"
                    :label="option.title"
                    :value="option.id"
                  >
                    {{ option.title }} ({{ $comman.withCurrency(option.rate) }}
                    -
                    {{
                      $comman.getValueFromObject(
                        option,
                        "payment_unit",
                        $constants.employeeJobPaymentUnit
                      )
                    }})
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-form-item
                label="Hours"
                :name="[index, 'payments', idx, 'hours']"
              >
                <a-input
                  v-model:value="payment.hours"
                  placeholder="Enter remarks here..."
                  disabled
                />
              </a-form-item>
            </a-col>

            <a-col :span="4" v-if="r.record.employee.employment_type == 2">
              <a-form-item
                label="Rate"
                :name="[index, 'payments', idx, 'rate']"
                @change="paymentCalculateUsingRate(index)"
              >
                <a-input-number
                  v-model:value="payment.rate"
                  placeholder="Enter Rate"
                  :min="0"
                  :step="0.01"
                  :formatter="$comman.currencyformatter"
                  :parser="$comman.currencyParser"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-form-item
                label="Additional Pay"
                :name="[index, 'payments', idx, 'additional_amount']"
                @change="paymentCalculate('additional_amount', index)"
              >
                <a-input-number
                  v-model:value="payment.additional_amount"
                  placeholder="Enter Amount"
                  :min="0"
                  :step="0.01"
                  :formatter="$comman.currencyformatter"
                  :parser="$comman.currencyParser"
                />
              </a-form-item>
            </a-col>

            <a-col :span="4">
              <a-form-item
                label="Amount"
                :name="[index, 'payments', idx, 'total']"
              >
                <a-input-number
                  v-model:value="payment.total"
                  placeholder="Enter Amount"
                  :min="0"
                  :step="0.01"
                  :formatter="$comman.currencyformatter"
                  :parser="$comman.currencyParser"
                  disabled
                />
              </a-form-item>
            </a-col>
          </template>
        </template>

        <a-col :span="24">
          <a-form-item label="Remarks" :name="[index, 'remarks']">
            <a-textarea
              v-model:value="r.remarks"
              placeholder="Enter remarks here..."
              :auto-size="{ minRows: 3, maxRows: 6 }"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </div>
    <side-drawer-buttons name="Update Status" />
  </a-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import formMixins from "../../../mixins/formMixins";
import { commonService } from "../../../services";

export default {
  mixins: [formMixins],

  emits: ["callback"],

  data() {
    return {
      model: [],
      statuses: [
        {
          text: "Approve",
          value: 2,
        },
        {
          text: "Reject",
          value: 3,
        },
      ],
      rules: {
        status: [
          {
            required: true,
            message: "Status is required!",
            type: "number",
          },
        ],
        quickbooks_class_id: {
          required: true,
          message: "This is required!",
        },
      },
      employeeJobs: [],
      columns: [
        {
          title: "Job Name",
          dataIndex: "job_name",
          key: "job_name",
        },
        {
          title: "Rate",
          dataIndex: "rate",
          key: "rate",
        },
        {
          title: "Hours",
          dataIndex: "hours",
          key: "hours",
        },
        {
          title: "Subtotal",
          dataIndex: "total",
          key: "total",
        },
        {
          title: "Additional Amount",
          dataIndex: "additional_amount",
          key: "additional_amount",
        },
        {
          title: "Total Amount",
          dataIndex: "total_amount",
          key: "total_amount",
          width: 125,
        },
      ],
      scroll: { x: true, y: 0 },
      classes: [],
    };
  },

  computed: {
    ...mapGetters("drawer", ["record", "extra"]),

    dataSource() {
      if (this.record.payments) {
        return this.record.payments.map((item) => {
          return {
            id: item.id,
            job_name: `${item.json?.employee_job?.title ?? ""} - (${
              item.json?.employee_job?.payment_unit ?? ""
            })`,
            rate: this.$comman.withCurrency(item.rate),
            hours: item.hour,
            total: this.$comman.withCurrency(item.amount),
            additional_amount: this.$comman.withCurrency(
              item.additional_amount
            ),
            total_amount: this.$comman.withCurrency(item.total_amount),
          };
        });
      }
      return [];
    },
  },

  async mounted() {
    await this.getQuickbooksData();
    commonService
      .get(this.$constants.getEmployeeJobs, {
        employee_ids: this.record.map((e) => e.employee_id),
      })
      .then((res) => {
        this.employeeJobs = res.data;
      });
    this.getmodel();
  },

  methods: {
    ...mapActions("drawer", ["loadingStart", "loadingStop", "close"]),

    getmodel() {
      let primaryShift = {
        label: "Primary Shift",
        job_id: undefined,
        hours: 0,
        rate: null,
        amount: 0,
        additional_amount: 0,
        total: 0,
        payment_unit: undefined,
      };

      let onCallShift = {
        label: "On Call Shift",
        job_id: undefined,
        hours: 0,
        rate: null,
        amount: 0,
        additional_amount: 0,
        total: 0,
        payment_unit: undefined,
      };

      let travelShift = {
        label: "Travel",
        job_id: undefined,
        hours: 0,
        rate: null,
        amount: 0,
        additional_amount: 0,
        total: 0,
        payment_unit: undefined,
      };

      let temp, payments;
      this.record.forEach((element) => {
        payments = [{ ...primaryShift, hours: element.total_time }];
        if (element.was_on_call) {
          payments.push({ ...onCallShift, hours: element.total_on_call_time });
        }
        if (element.was_travel) {
          payments.push({ ...travelShift, hours: element.total_travel_time });
        }

        temp = {
          id: element.id,
          status: this.extra.status,
          remarks: null,
          payments: payments,
          quickbooks_class_id: this.quickbooksClassId(element),
          quickbooks_class_info: this.quickbooksClassInfo(element),
          record: element,
        };

        this.model.push(temp);
      });
    },

    handleFinish() {
      const data = this.model.map((r) => ({
        id: r.id,
        status: r.status,
        remarks: r.remarks,
        payments: r.status == 2 ? r.payments : [],
        quickbooks_class_id: r.quickbooks_class_id,
        quickbooks_class_info: r.quickbooks_class_info,
      }));
      this.loadingStart();
      commonService
        .store(
          this.$constants.admiEmployeeTimeTrackBulkactionForApproveReject,
          {
            ...data,
          }
        )
        .then((res) => {
          this.loadingStop();
          if (res.success) {
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
          this.close();
          this.$emit("callback");
        })
        .catch((err) => {
          this.loadingStop();
          if ("errors" in err) {
            this.errors = err.errors;
          } else {
            this.$message.error(err);
          }
        });
    },

    paymentCalculate(type, index) {
      this.model[index].payments = this.model[index].payments.map((item) => {
        if (item.job_id) {
          let job = this.employeeJobs.find((e) => item.job_id == e.id);
          if (job) {
            if (type == "additional_amount") {
              job.rate = item.rate;
            }
            item.payment_unit = job.payment_unit;
            item.rate = ["Year", "Year+Overtime"].includes(item.payment_unit)
              ? 0
              : job.rate;
            item.amount = item.rate * item.hours;
            item.total = (item.amount + item.additional_amount).toFixed(2);
          }
        }
        return item;
      });
    },

    paymentCalculateUsingRate(index) {
      this.model[index].payments = this.model[index].payments.map((item) => {
        if (item.job_id) {
          item.amount = ["Year", "Year+Overtime"].includes(item.payment_unit)
            ? 0
            : item.rate * item.hours;
          item.total = (item.amount + item.additional_amount).toFixed(2);
        }
        return item;
      });
    },

    getQuickbooksData() {
      commonService
        .store(this.$constants.getQuickbooksData, {
          names: ["classes"],
        })
        .then((res) => {
          if (res.success) {
            res.data.forEach((v) => {
              if (v.name == "classes") {
                this.classes = JSON.parse(v.data);
              }
            });
          }
        });
    },

    quickbooksClassId(rec) {
      return rec?.hospital?.quickbooks_class_id ?? null;
    },

    quickbooksClassInfo(rec) {
      return rec?.hospital?.quickbooks_class_info
        ? JSON.parse(rec.hospital.quickbooks_class_info)
        : null;
    },
    computedClasses(record) {
      if (record.quickbooks_class_id) {
        return this.classes.filter(
          (e) =>
            e.Id == record.quickbooks_class_id ||
            (e.ParentRef?.value &&
              e.ParentRef.value == record.quickbooks_class_id)
        );
      }
      return [];
    },
  },
};
</script>
