<template>
  <template v-if="!hipec">
    <a-card size="small">
      <a-empty />
    </a-card>
  </template>

  <template v-else>
    <div class="monitor">
      <div class="monitor-top">
        <div class="monitor-message">
          <h3>
            {{ caseReport.patient_first_name }}
            {{ caseReport.patient_last_name }} | MR:
            {{ caseReport.mr_number }} | Acct:
            {{ caseReport.data.account_number ?? "N/A" }} | DOB:
            {{ $customDate.mdy(caseReport.data.dob) }} | Gender:
            {{ caseReport.data.gender }} | Allergies:
            {{ caseReport.data.allergies ?? "N/A" }} | BSA :
            {{ caseReport.data.body_surface_area ?? "N/A" }}
          </h3>
        </div>
      </div>
      <div class="monitor-middle">
        <div class="monitor-left">
          <div class="timer-actions">
            <ul class="timer-action-list">
              <li class="action-item">
                <a-spin :spinning="hipecLoading">
                  <button
                    class="timer-action"
                    :class="
                      checkTimer('hipec') && !checkTimer('hipec')['stop_time']
                        ? 'timer-action-active'
                        : ''
                    "
                    @click="
                      hasNotCompletedHIPEC
                        ? handleTimerEvent('hipec', 'HIPEC')
                        : ''
                    "
                  >
                    <span class="title">
                      {{ hipecText }}
                      <span style="font-weight: normal !important">
                        {{ timer("hipec") }}
                      </span>
                    </span>
                    <span class="start">
                      {{
                        checkTimer("hipec") && checkTimer("hipec")["start_time"]
                          ? checkTimer("hipec")["start_time"].slice(0, 5)
                          : "-"
                      }}
                    </span>
                    <span class="stop">
                      {{
                        checkTimer("hipec") && checkTimer("hipec")["stop_time"]
                          ? checkTimer("hipec")["stop_time"].slice(0, 5)
                          : "-"
                      }}
                    </span>
                  </button>
                </a-spin>
              </li>
            </ul>
          </div>
          <div class="event-actions">
            <ul class="event-action-list">
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    hasNotCompletedHIPEC
                      ? open({
                          title: 'Pre-Procedure Checklist',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.hipecPreProcedureChecklist',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                        })
                      : ''
                  "
                >
                  <span class="title">Pre-Procedure Checklist</span>
                  <span class="description">
                    {{ hipec.pre_checklist?.submitted_at ?? null }}
                  </span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    (!isCompleted &&
                      checkTimer('hipec') &&
                      checkTimer('hipec')['start_time'] &&
                      checkTimer('hipec')['stop_time']) ||
                    isAdminLiveChartingPage
                      ? openPostProcedureHalfDrawer()
                      : ''
                  "
                >
                  <span class="title">Post-Procedure Checklist</span>
                  <span class="description">
                    {{ hipec.post_checklist?.submitted_at ?? null }}
                  </span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    canAddEvents || isAdminLiveChartingPage
                      ? open({
                          title: 'Status',
                          width: '90%',
                          path: 'newCaseReport.drawerForm.hipecStatus',
                          callback: (e) => $emit('updateCaseReport', e),
                          record: caseReport,
                          extra: {
                            index: events?.length != 1 ? undefined : 0,
                          },
                        })
                      : ''
                  "
                >
                  <span class="title">Status</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action"
                  @click="
                    open({
                      title: 'Case Data',
                      path: 'newCaseReport.drawerForm.caseData',
                      width: '90%',
                      callback: callback,
                      record: caseReport,
                      extra: { dontShowCI: true },
                    })
                  "
                >
                  <span class="title">Case Data</span>
                </button>
              </li>
              <li class="action-item">
                <button
                  class="event-action event-action-close"
                  @click="$emit('closeCaseClickEvent')"
                >
                  <span class="title">Close Case</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="monitor-right">
          <div
            class="monitor-right-middle"
            ref="hipecDatatableRef"
            style="padding: 1em 0 0 0"
          >
            <div class="event-table scroller">
              <table class="event-table-compact">
                <thead>
                  <tr>
                    <th rowspan="2">Time</th>
                    <th colspan="3">Vitals</th>
                    <th colspan="6">Pump</th>
                    <th colspan="3">Temperature (°C)</th>
                    <th colspan="3">Volume</th>
                  </tr>
                  <tr>
                    <th>Arterial BP (mmHg)</th>
                    <th>HR</th>
                    <th>SpO<sub>2</sub></th>
                    <th>Flow Rate (ml/min)</th>
                    <th>Circuit Pressure (mmHg)</th>
                    <th>Negative Pressure (mmHg)</th>
                    <th>Target Temp (°C)</th>
                    <th>Temp Pump (°C)</th>
                    <th>Reservoir Volume (ml)</th>
                    <th>Inflow (°C)</th>
                    <th>Outflow (°C)</th>
                    <th>Other (°C)</th>
                    <th class="highlight">Prime (ml)</th>
                    <th class="highlight">Plasmalyte (ml)</th>
                    <th class="highlight">NaCl 0.9% (ml)</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="hasRecords">
                    <template
                      v-for="(item, index) in events ?? []"
                      :key="index"
                    >
                      <tr v-if="item.event == 'Date change'">
                        <td
                          colspan="16"
                          align="left"
                          style="
                            font-weight: 500;
                            background-color: #eee;
                            padding-left: 10px;
                          "
                        >
                          {{ item.comment }}
                        </td>
                      </tr>
                      <tr v-else>
                        <td
                          v-if="
                            (canAddEvents &&
                              item.employee_id == employee.id &&
                              !['Shift Change'].includes(item.event)) ||
                            isAdminLiveChartingPage
                          "
                        >
                          <a-popover placement="left">
                            <template #content>
                              <a-space>
                                <a-button
                                  type="primary"
                                  @click="handleEditEvent(item, index - 1)"
                                >
                                  Edit
                                </a-button>
                                <a-popconfirm
                                  v-if="index != 1 && item.event != 'HIPEC'"
                                  title="Confirm deletion?"
                                  ok-text="Yes"
                                  cancel-text="No"
                                  @confirm="deleteRecord(item.unique_code)"
                                >
                                  <a-button danger>Delete</a-button>
                                </a-popconfirm>
                              </a-space>
                            </template>
                            {{ item.time.slice(0, 5) ?? "-" }}
                          </a-popover>
                        </td>
                        <td v-else>
                          {{ item.time.slice(0, 5) ?? "-" }}
                        </td>
                        <template
                          v-if="['HIPEC', 'Shift Change'].includes(item.event)"
                        >
                          <td align="left" colspan="15">
                            <span class="text-medium">
                              {{ item.note }}
                              {{
                                item.note.includes("Off")
                                  ? `(${hipecTime})`
                                  : ""
                              }}
                              [Date: {{ item.date }}]
                            </span>
                          </td>
                        </template>
                        <template v-else-if="['Comment'].includes(item.event)">
                          <td align="left" colspan="15">
                            {{ item.comment }} | {{ item.time }}
                          </td>
                        </template>
                        <template v-else>
                          <td>{{ item.arterial_bp ?? "-" }}</td>
                          <td>{{ item.hr ?? "-" }}</td>
                          <td>{{ item.spo2 ?? "-" }}</td>
                          <td>{{ item.flow_rate ?? "-" }}</td>
                          <td>{{ item.circuit_pressure ?? "-" }}</td>
                          <td>
                            {{
                              item.negative_pressure != 0
                                ? item.negative_pressure
                                : "-"
                            }}
                          </td>
                          <td>{{ item.target_temp ?? "-" }}</td>
                          <td>{{ item.temp_pump ?? "-" }}</td>
                          <td>{{ item.reservoir_volume ?? "-" }}</td>
                          <td>{{ item.inflow ?? "-" }}</td>
                          <td>{{ item.outflow ?? "-" }}</td>
                          <td>{{ item.other ?? "-" }}</td>
                          <td>{{ item.prime ?? "-" }}</td>
                          <td>{{ item.plasmalyte ?? "-" }}</td>
                          <td>{{ item.nacl ?? "-" }}</td>
                        </template>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="16" align="left">
                        <strong>No Data Found.</strong>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="monitor-right-bottom">
            <div class="quick-actions">
              <ul class="quick-actions-list">
                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      canAddEvents || isAdminLiveChartingPage
                        ? open({
                            title: `Add ${hipecText} Event`,
                            width: '90%',
                            path: 'newCaseReport.drawerForm.hipecEvent',
                            callback: (e) => $emit('updateCaseReport', e),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Events
                  </button>
                </li>

                <li class="quick-item">
                  <button
                    class="action"
                    @click="
                      canAddEvents || isAdminLiveChartingPage
                        ? open({
                            title: 'Shift Change',
                            width: '90%',
                            path: 'newCaseReport.drawerForm.shiftChange',
                            callback: (e) => redirectToIndexPage(),
                            record: caseReport,
                          })
                        : ''
                    "
                  >
                    Shift Change
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import moment from "moment";
import { createVNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import { commonService } from "../../../services";

export default {
  props: [
    "caseReport",
    "uuid",
    "hipec",
    "hipecText",
    "isCompleted",
    "isAdminLiveChartingPage",
  ],

  emits: ["updateCaseReport", "closeCaseClickEvent"],

  data() {
    return {
      hipecLoading: false,

      currentDateTime: "",
      currentDateTimeInverval: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["employee"]),

    timezone() {
      return this.caseReport?.hospital?.timezone ?? this.$constants.timezone;
    },

    hasNotCompletedHIPEC() {
      return !this.isCompleted || this.isAdminLiveChartingPage;
    },

    onlyActiveRecords() {
      let data = this.hipec?.data
        ? this.hipec?.data
            .filter((item) => !item.deleted_at)
            .map((item) => {
              if (!item.employee_id) {
                item.employee_id = this.caseReport.employee_id;
              }
              if (!item.date) {
                item.date = this.$customDate.mdy(this.caseReport.date);
                item.datetime = `${item.date} ${item.time}`;
              }
              return item;
            })
        : [];

      if (data.length != 0) {
        let firstDate = data[0].date;
        let lastDate = data[data.length - 1].date;
        let range = this.$comman.getDateRange(firstDate, lastDate);
        range.forEach((date, idx) => {
          if (idx != 0) {
            data.push({
              event: "Date change",
              date: date,
              time: "00:00:00",
              datetime: moment(`${date} 00:00:00`, "MM-DD-YYYY HH:mm:ss"),
              comment: `Date: ${date}`,
            });
          }
        });
      }

      return data.sort((a, b) =>
        moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
          moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
        )
          ? 1
          : -1
      );
    },

    hasRecords() {
      return this.onlyActiveRecords.length > 0 ? true : false;
    },

    events() {
      if (!this.hasRecords) {
        return [];
      }
      return this.onlyActiveRecords
        .filter((e) => !e.deleted_at)
        .sort((a, b) =>
          moment(a.datetime, "MM-DD-YYYY HH:mm:ss").isAfter(
            moment(b.datetime, "MM-DD-YYYY HH:mm:ss")
          )
            ? 1
            : -1
        );
    },

    hasTimers() {
      return this.hipec?.timers ? true : false;
    },

    canAddEvents() {
      console.log(this.checkTimer("hipec"));
      return (
        !this.isCompleted &&
        (this.checkTimer("hipec") == null ||
          (this.checkTimer("hipec")["start_time"] &&
            !this.checkTimer("hipec")["stop_time"]))
      );
    },

    hipecTime() {
      if (this.hipec?.timers?.hipec[0]) {
        let timers = this.hipec.timers.hipec[0];
        let startTime = moment(timers.start_time, "HH:mm:ss");
        let stopTime = moment(timers.stop_time, "HH:mm:ss");
        let duration = stopTime.diff(startTime, "seconds");
        return this.$comman.secondsToHms1(duration);
      }
      return "";
    },
  },

  mounted() {
    this.currentDateTime = moment().tz(this.timezone).format("HH:mm:ss");
    var self = this;
    this.currentDateTimeInverval = setInterval(function () {
      self.currentDateTime = moment().tz(self.timezone).format("HH:mm:ss");
    }, 1000);
  },

  unmounted() {
    clearInterval(this.currentDateTimeInverval);
  },

  methods: {
    ...mapActions("modal", ["open"]),

    callback() {
      // console.log("callback::");
    },

    handleEditEvent(item, index) {
      let path = "newCaseReport.drawerForm.";
      let title = `Edit `;
      if (item.event == "Comment") {
        path += "hipecEvent";
        title += `${this.hipecText} Event`;
      } else if (item.event == "Status") {
        path += "hipecStatus";
        title += `Status`;
      } else if (item.event == "HIPEC") {
        path += "hipecTimer";
        title += `Timer`;
      }
      this.open({
        title,
        path,
        callback: (e) => this.$emit("updateCaseReport", e),
        record: this.caseReport,
        extra: { item, index },
      });
    },

    deleteRecord(unique_code) {
      commonService
        .store(this.$constants.caseReportStoreHIPECRecordUrl, {
          unique_code,
          uuid: this.uuid,
          delete: true,
        })
        .then((res) => {
          this.$message.success(res.message);
          this.$emit("updateCaseReport", res.data);
        })
        .catch((err) => this.$message.error(err));
    },

    checkTimer(type) {
      return this.hasTimers && this.hipec.timers[type]
        ? this.hipec.timers[type][0]
        : null;
    },

    timer(type) {
      let checkTimer = this.checkTimer(type);
      if (checkTimer) {
        let startTime = checkTimer.start_time;
        let stopTime = checkTimer.stop_time;
        if (this.currentDateTime && startTime) {
          let seconds = moment(
            stopTime ?? this.currentDateTime,
            "HH:mm:ss"
          ).diff(moment(startTime, "HH:mm:ss"), "seconds");
          return this.$comman.secondsToHms1(seconds);
        }
      }
      return "";
    },

    openPostProcedureHalfDrawer() {
      this.open({
        title: "Post-Procedure Checklist",
        path: "newCaseReport.drawerForm.hipecPostProcedureChecklist",
        callback: (e) => this.$emit("updateCaseReport", e),
        record: this.caseReport,
      });
    },

    async handleTimerEvent(type, event) {
      if (this.checkTimer(type) && this.checkTimer(type)["stop_time"]) {
        return;
      }

      this.hipecLoading = true;
      let data = { uuid: this.uuid, type, event };
      if (this.checkTimer(type) && !this.checkTimer(type)["stop_time"]) {
        data.stop_time = moment().tz(this.timezone).format("HH:mm:ss");
        data.note = `${this.hipecText} Off`;
        let that = this;

        Modal.confirm({
          title: () => `Are you sure you want to stop ${this.hipecText}?`,
          icon: () => createVNode(ExclamationCircleOutlined),
          onOk() {
            that.storeTimer(data);
          },
          okText: "Confirm",
          okType: "danger",
          keyboard: false,
          onCancel() {
            that.hipecLoading = false;
            return;
          },
        });
        return;
      } else {
        data.start_time = moment().tz(this.timezone).format("HH:mm:ss");
        data.note = `${this.hipecText} On`;
      }

      this.storeTimer(data);
    },

    storeTimer(data) {
      commonService
        .store(this.$constants.caseReportStoreHIPECTimerRecordUrl, data)
        .then((res) => {
          this.$emit("updateCaseReport", res.data);
          if ("stop_time" in data) {
            this.openPostProcedureHalfDrawer();
          }
        })
        .catch((err) => this.$message.error(err))
        .finally(() => (this.hipecLoading = false));
    },

    redirectToIndexPage() {
      this.$router.push({
        name:
          this.$route.name == "admin.case-report-live-charting"
            ? "admin.case-report"
            : "case-report",
      });
    },
  },
};
</script>
